import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Modal,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Button,
  Tooltip,
  LinearProgress,
  Alert,
  Checkbox,
  FormControlLabel,
  Switch,
  Paper,
  Popper,
  Snackbar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import MinimizeIcon from '@mui/icons-material/Minimize';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getToken } from '../../../utils/auth';
import { AIRLINE_LOGOS } from '../utils';
import Draggable from 'react-draggable';
import DiscrepancyAlert from './DiscrepancyAlert';
import UnableToProcessModal from './UnableToProcessModal';

// Initialize dayjs plugins
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

function RecordRepricing({ reservationData, open, onClose, onSuccess }) {
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const [voucherNumbers, setVoucherNumbers] = useState({});
  const [amountSaved, setAmountSaved] = useState({});
  const [expirationDates, setExpirationDates] = useState({});
  const [issueDates, setIssueDates] = useState({});
  const [refundTypes, setRefundTypes] = useState({});
  const [processedPassengers, setProcessedPassengers] = useState([]);
  const [removedPassengers, setRemovedPassengers] = useState([]);
  const [confirmedCredits, setConfirmedCredits] = useState({});
  const [totalAmount, setTotalAmount] = useState('0.00');
  const [lfgRate, setLfgRate] = useState(reservationData?.admin_data?.lfg_percentage || '0.15');
  const [billingAmount, setBillingAmount] = useState('0.00');
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(true);
  const [doNotBill, setDoNotBill] = useState(false);
  const [doNotBillReason, setDoNotBillReason] = useState('');
  const [showCollapsed, setShowCollapsed] = useState({});
  const [applyReferralCredits, setApplyReferralCredits] = useState(false);
  const [pulsingInputId, setPulsingInputId] = useState(null);
  const [loadingAAEmail, setLoadingAAEmail] = useState(false);
  const [aaTripCredits, setAATripCredits] = useState([]);
  const [aaCreditPopupOpen, setAACreditPopupOpen] = useState(false);
  const [aaCreditPopupAnchor, setAACreditPopupAnchor] = useState(null);
  const [aaCreditError, setAACreditError] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [copiedCredit, setCopiedCredit] = useState(null);
  const [snapshotLoading, setSnapshotLoading] = useState(false);
  const [snapshotError, setSnapshotError] = useState(null);
  const [comparisonInfo, setComparisonInfo] = useState(reservationData?.repricing_comparison_info || {});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [creditsBeingUsed, setCreditsBeingUsed] = useState('0.00');
  const [submitting, setSubmitting] = useState(false);
  const [discrepancyNotes, setDiscrepancyNotes] = useState([]);
  const [unableToProcessOpen, setUnableToProcessOpen] = useState(false);

  const billingInfo = reservationData?.admin_data?.billing_info?.payment_method;
  const userInfo = reservationData?.user_info;
  const outstandingReferralCredits = userInfo?.outstanding_referral_credits || 0;

  const airline = reservationData?.reservation_details?.airline;
  const isRefundable = reservationData?.reservation_details?.flags?.is_refundable;
  const pnr = reservationData?.pnr;

  const pulseAnimation = {
    '@keyframes pulseError': {
      '0%': {
        borderColor: 'rgba(255, 68, 68, 0.5)',
        boxShadow: '0 0 0 0 rgba(255, 68, 68, 0.5)',
      },
      '70%': {
        borderColor: 'rgba(255, 68, 68, 0)',
        boxShadow: '0 0 0 6px rgba(255, 68, 68, 0)',
      },
      '100%': {
        borderColor: 'rgba(255, 68, 68, 0)',
        boxShadow: '0 0 0 0 rgba(255, 68, 68, 0)',
      },
    },
  };

  // Initialize passenger data when component mounts or reservationData changes
  useEffect(() => {
    if (reservationData?.passengers) {
      // Reset removed passengers
      setRemovedPassengers([]);
      
      // Initialize default values based on refundable status and airline
      const defaultTypes = {};
      const defaultVouchers = {};
      const defaultDates = {};
      
      reservationData.passengers.forEach(p => {
        // Set default credit type based on is_refundable flag
        defaultTypes[p.passenger_id] = isRefundable ? 'Cash' : 'Voucher';

        if (isRefundable) {
          defaultVouchers[p.passenger_id] = 'Refund to Original Form of Payment';
        } else if (airline === 'united') {
          defaultVouchers[p.passenger_id] = pnr;
        }

        // Set default expiration dates based on airline
        if (airline === 'american') {
          defaultDates[p.passenger_id] = dayjs().add(1, 'year');
        } else if (airline === 'delta' || airline === 'united') {
          // Use ticket issue date if available, otherwise fallback to today
          const ticketIssueDate = dayjs(reservationData?.reservation_details?.ticket_issue_date);
          defaultDates[p.passenger_id] = ticketIssueDate.isValid() 
            ? ticketIssueDate.add(1, 'year')
            : dayjs().add(1, 'year');
        }
      });
      
      setRefundTypes(defaultTypes);
      setVoucherNumbers(defaultVouchers);
      setExpirationDates(prev => ({...prev, ...defaultDates}));
    }
  }, [reservationData, isRefundable, airline, pnr]);

  // Initialize comparison info state from reservation data
  useEffect(() => {
    if (reservationData?.repricing_comparison_info) {
      setComparisonInfo(reservationData.repricing_comparison_info);
    }
  }, [reservationData]);

  // Update billing amount when amount saved changes
  useEffect(() => {
    const total = Object.values(amountSaved).reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
    setTotalAmount(total.toFixed(2));
    const billing = total * parseFloat(lfgRate);
    
    if (applyReferralCredits && outstandingReferralCredits > 0) {
      // Calculate how much of the billing amount can be covered by credits
      const creditsToUse = Math.min(billing, outstandingReferralCredits);
      // Store the amount of credits being used
      setCreditsBeingUsed(creditsToUse.toFixed(2));
      // Subtract the credits from the billing amount
      setBillingAmount((billing - creditsToUse).toFixed(2));
    } else {
      // If not applying credits, show full billing amount and clear credits being used
      setBillingAmount(billing.toFixed(2));
      setCreditsBeingUsed('0.00');
    }
  }, [amountSaved, lfgRate, applyReferralCredits, outstandingReferralCredits]);

  // Calculate progress percentage
  const progressPercentage = reservationData?.passengers?.length > 0 
    ? (processedPassengers.length / reservationData.passengers.length) * 100 
    : 0;

  const commonInputStyles = {
    '& .MuiOutlinedInput-root': {
      height: 36,
      bgcolor: 'white',
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.15)',
      },
    }
  };

  const handleCreditLookup = async (passengerId) => {
    if (!voucherNumbers[passengerId]) {
      setError({ ...error, [passengerId]: 'Please enter a voucher number' });
      return;
    }

    setLoading({ ...loading, [passengerId]: true });
    setError({ ...error, [passengerId]: null });

    const passenger = reservationData?.passengers?.find(p => p.passenger_id === passengerId);
    const airlineCode = reservationData?.reservation_details?.airline?.toLowerCase();
    
    // Map airline codes
    const airlineMapping = {
      'american': 'aa',
      'delta': 'dl',
      'united': 'ua'
    };

    const mappedAirline = airlineMapping[airlineCode] || airlineCode;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", getToken());
    myHeaders.append("Key", "ZSGSPH");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(
        `https://admin-api.withautopilot.com/dev/find_credit_info?airline=${mappedAirline}&credit_number=${voucherNumbers[passengerId]}&first_name=${passenger.first_name}&last_name=${passenger.last_name}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Failed to fetch credit information');
      }

      const result = await response.json();
      
      // Handle both single object and array responses
      const creditInfo = Array.isArray(result) ? selectBestCredit(result, passenger) : result;
      
      if (!creditInfo) {
        throw new Error('No valid credit information found');
      }

      // Ensure total_amount is treated as a number
      const amount = typeof creditInfo.total_amount === 'string' 
        ? parseFloat(creditInfo.total_amount) 
        : creditInfo.total_amount;

      if (isNaN(amount)) {
        throw new Error('Invalid amount received from credit lookup');
      }
      
      // Update the amount and expiration date with the returned values
      setAmountSaved({
        ...amountSaved,
        [passengerId]: amount.toFixed(2)
      });
      
      // Convert the expiration date string to a dayjs object for the DatePicker
      setExpirationDates({
        ...expirationDates,
        [passengerId]: dayjs(creditInfo.expiration_date)
      });
      
      // Store the issue date if available
      if (creditInfo.issue_date) {
        setIssueDates({
          ...issueDates,
          [passengerId]: creditInfo.issue_date
        });
      }
      
      // Set the credit as confirmed when we successfully get the data
      setConfirmedCredits({ ...confirmedCredits, [passengerId]: true });
      setProcessedPassengers([...processedPassengers, passengerId]);
      setShowCollapsed({ ...showCollapsed, [passengerId]: true });
    } catch (error) {
      console.error('Error looking up credit:', error);
      setError({ 
        ...error, 
        [passengerId]: 'Failed to lookup credit information. Please verify the voucher number and try again.' 
      });
      // Remove confirmed status if there was an error
      setConfirmedCredits({ 
        ...confirmedCredits, 
        [passengerId]: false 
      });
      setShowCollapsed({
        ...showCollapsed,
        [passengerId]: false
      });
    } finally {
      setLoading({ ...loading, [passengerId]: false });
    }
  };

  // Helper function to select the best credit from an array of credits
  const selectBestCredit = (credits, passenger) => {
    if (!credits || credits.length === 0) {
      return null;
    }

    if (credits.length === 1) {
      return credits[0];
    }

    // Sort credits by issue date (most recent first)
    const sortedCredits = [...credits].sort((a, b) => {
      return new Date(b.issue_date) - new Date(a.issue_date);
    });

    // Get all credits with the most recent issue date
    const mostRecentDate = sortedCredits[0].issue_date;
    const mostRecentCredits = sortedCredits.filter(credit => 
      credit.issue_date === mostRecentDate
    );

    if (mostRecentCredits.length === 1) {
      return mostRecentCredits[0];
    }

    // If multiple credits with same issue date, try to match traveler name
    const passengerFullName = `${passenger.first_name} ${passenger.last_name}`.toLowerCase();
    
    // Try to find a credit with matching traveler name (loose match)
    const matchingCredit = mostRecentCredits.find(credit => {
      const creditName = credit.traveler_name.toLowerCase();
      // Check if either name contains the other or if they're similar
      return creditName.includes(passengerFullName) || 
             passengerFullName.includes(creditName) ||
             levenshteinDistance(creditName, passengerFullName) <= 3;
    });

    // Return matching credit if found, otherwise return the first one
    return matchingCredit || mostRecentCredits[0];
  };

  // Helper function to calculate Levenshtein distance for fuzzy name matching
  const levenshteinDistance = (str1, str2) => {
    const track = Array(str2.length + 1).fill(null).map(() =>
      Array(str1.length + 1).fill(null)
    );

    for (let i = 0; i <= str1.length; i++) {
      track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j++) {
      track[j][0] = j;
    }

    for (let j = 1; j <= str2.length; j++) {
      for (let i = 1; i <= str1.length; i++) {
        const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
          track[j][i - 1] + 1,
          track[j - 1][i] + 1,
          track[j - 1][i - 1] + indicator
        );
      }
    }

    return track[str2.length][str1.length];
  };

  const handleRemovePassenger = (passengerId) => {
    const passenger = reservationData?.passengers?.find(p => p.passenger_id === passengerId);
    
    // Clear all data associated with this passenger
    setVoucherNumbers(prev => {
      const newVouchers = { ...prev };
      delete newVouchers[passengerId];
      return newVouchers;
    });
    
    setAmountSaved(prev => {
      const newAmounts = { ...prev };
      delete newAmounts[passengerId];
      return newAmounts;
    });
    
    setExpirationDates(prev => {
      const newDates = { ...prev };
      delete newDates[passengerId];
      return newDates;
    });
    
    setRefundTypes(prev => {
      const newTypes = { ...prev };
      delete newTypes[passengerId];
      return newTypes;
    });
    
    setConfirmedCredits(prev => {
      const newConfirmed = { ...prev };
      delete newConfirmed[passengerId];
      return newConfirmed;
    });
    
    setProcessedPassengers(prev => prev.filter(id => id !== passengerId));
    setShowCollapsed(prev => {
      const newCollapsed = { ...prev };
      delete newCollapsed[passengerId];
      return newCollapsed;
    });
    
    setRemovedPassengers([...removedPassengers, passenger]);
  };

  const handleAddPassenger = (passenger) => {
    setRemovedPassengers(removedPassengers.filter(p => p.passenger_id !== passenger.passenger_id));
  };

  const activePassengers = reservationData?.passengers?.filter(
    p => !removedPassengers.find(rp => rp.passenger_id === p.passenger_id)
  ) || [];

  const handleToggleCollapse = (passengerId) => {
    setShowCollapsed(prev => ({
      ...prev,
      [passengerId]: !prev[passengerId]
    }));
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleDataChange = (passengerId) => {
    setConfirmedCredits(prev => ({
      ...prev,
      [passengerId]: false
    }));
    setShowCollapsed(prev => ({
      ...prev,
      [passengerId]: false
    }));
  };

  const constructRequestData = () => {
    // Return null if we don't have the necessary data
    if (!activePassengers || !reservationData || !billingInfo) {
      return null;
    }

    const passenger_refaring = activePassengers
      .filter(p => 
        amountSaved[p.passenger_id] || voucherNumbers[p.passenger_id]
      )
      .map(passenger => ({
        passenger_id: passenger.passenger_id,
        amount_saved: amountSaved[passenger.passenger_id],
        voucher_number: voucherNumbers[passenger.passenger_id],
        voucher_expiration: expirationDates[passenger.passenger_id] ? expirationDates[passenger.passenger_id].toISOString() : null,
        refund_type: refundTypes[passenger.passenger_id] || 'Voucher'
      }));

    return {
      reservation_id: reservationData.reservation_id,
      passenger_refaring,
      charge_amount: billingAmount,
      payment_method: `${billingInfo?.brand || ''} **** ${billingInfo?.last4 || ''}`,
      do_not_bill_this_event: doNotBill || billingAmount === '0.00',
      do_not_bill_reason: (doNotBill || billingAmount === '0.00') && !doNotBill ? 'Charge amount is $0.00' : doNotBillReason,
      do_not_send_customer_email: !sendConfirmationEmail,
      stripe_customer_id: billingInfo?.customer_id,
      billing_amount: billingAmount,
      setup_intent_id: billingInfo?.id,
      autopilot_credits_redeemed: creditsBeingUsed,
      card_info: {
        brand: billingInfo?.brand,
        last4: billingInfo?.last4,
        exp_month: billingInfo?.exp_month,
        exp_year: billingInfo?.exp_year,
        postal_code: billingInfo?.address?.postal_code
      }
    };
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setDiscrepancyNotes([]); // Clear any previous discrepancy notes
    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("content-type", "application/json");
    myHeaders.append("token", getToken());

    console.log('Active Passengers:', activePassengers);
    console.log('Confirmed Credits:', confirmedCredits);
    console.log('Amount Saved:', amountSaved);
    console.log('Voucher Numbers:', voucherNumbers);
    console.log('Expiration Dates:', expirationDates);
    console.log('Refund Types:', refundTypes);

    const requestData = constructRequestData();
    console.log('Final API request payload:', requestData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestData),
      redirect: "follow"
    };

    try {
      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/record_refare", requestOptions);
      const result = await response.json();
      console.log('API Response:', result);
      
      // Set submitting to false immediately after getting response
      setSubmitting(false);
      
      if (result.discrepancy_notes) {
        // Handle discrepancy notes
        const notes = Array.isArray(result.discrepancy_notes) ? result.discrepancy_notes : [result.discrepancy_notes];
        setDiscrepancyNotes(notes);
      } else if (result.error) {
        // Handle error response
        setError({
          ...error,
          submit: typeof result.error === 'string' ? result.error : 'An error occurred while recording the savings'
        });
      } else if (response.ok) {
        onSuccess(result.message || 'Refare event recorded successfully');
        // Close the modal immediately instead of using a timeout
        onClose();
      } else {
        console.error('API Error:', result);
        setError({
          ...error,
          submit: 'An unexpected error occurred while recording the savings'
        });
      }
    } catch (error) {
      console.error('Error submitting repricing:', error);
      setError({
        ...error,
        submit: 'Failed to connect to the server. Please try again.'
      });
      setSubmitting(false);
    }
  };

  const handleLookupClick = (e, passengerId) => {
    e.stopPropagation();
    handleCreditLookup(passengerId);
  };

  const handleAAEmailLookup = async (event) => {
    setLoadingAAEmail(true);
    setAACreditError(null);
    setAACreditPopupAnchor(event.currentTarget);

    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("accept-language", "en-US,en;q=0.9,es;q=0.8");
    myHeaders.append("content-type", "application/json");
    myHeaders.append("origin", "https://beluga.withautopilot.com");
    myHeaders.append("priority", "u=1, i");
    myHeaders.append("referer", "https://beluga.withautopilot.com/");
    myHeaders.append("sec-ch-ua", "\"Not A(Brand\";v=\"8\", \"Chromium\";v=\"132\", \"Google Chrome\";v=\"132\"");
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
    myHeaders.append("sec-fetch-dest", "empty");
    myHeaders.append("sec-fetch-mode", "cors");
    myHeaders.append("sec-fetch-site", "same-site");
    myHeaders.append("token", getToken());
    myHeaders.append("user-agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/132.0.0.0 Safari/537.36");

    const raw = JSON.stringify({
      "user_id": userInfo?.user_id || ""
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/get_aa_trip_credit_info_from_email", requestOptions);
      const result = await response.json();
      
      if (result.trip_credit_info) {
        setAATripCredits(result.trip_credit_info);
        setAACreditPopupOpen(true);
      } else {
        setAACreditError(response.text || "No trip credits found");
      }
    } catch (error) {
      setAACreditError(error.message);
    } finally {
      setLoadingAAEmail(false);
    }
  };

  const handleCopyCredit = (credit, e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(credit);
    setCopiedCredit(credit);
    setTimeout(() => setCopiedCredit(null), 2000);
  };

  const getSortedComparisons = (comparisons) => {
    if (!comparisons?.length) return [];
    return [...comparisons].sort((a, b) => dayjs(b).valueOf() - dayjs(a).valueOf());
  };

  const hasRecentComparison = () => {
    if (!comparisonInfo?.pending_comparisons?.length) {
      console.log('No pending comparisons found');
      return false;
    }

    const sortedComparisons = getSortedComparisons(comparisonInfo.pending_comparisons);
    const latestComparison = dayjs(sortedComparisons[0]);
    const currentTime = dayjs();
    
    console.log('Date Comparison Debug:');
    console.log('All Comparisons:', comparisonInfo.pending_comparisons);
    console.log('Sorted Comparisons:', sortedComparisons);
    console.log('Latest Comparison:', latestComparison.format());
    console.log('Current Time:', currentTime.format());
    console.log('24 Hours Ago:', currentTime.subtract(24, 'hours').format());
    console.log('Is Latest Comparison After 24 Hours Ago?', latestComparison.isAfter(currentTime.subtract(24, 'hours')));
    
    return latestComparison.isAfter(currentTime.subtract(24, 'hours'));
  };

  const getComparisonTimeInfo = () => {
    if (!comparisonInfo?.pending_comparisons?.length) {
      return null;
    }

    const sortedComparisons = getSortedComparisons(comparisonInfo.pending_comparisons);
    const latestComparison = dayjs(sortedComparisons[0]);
    const timeAgo = latestComparison.fromNow();
    const estTime = latestComparison.tz('America/New_York').format('MM/DD/YYYY hh:mm:ss A z');
    
    return `${timeAgo}\n${estTime}`;
  };

  const handleSnapshotClick = async () => {
    setSnapshotLoading(true);
    setSnapshotError(null);

    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("content-type", "application/json");
    myHeaders.append("token", getToken());

    const raw = JSON.stringify({
      "reservation_id": reservationData.reservation_id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/add_repricing_comparison", requestOptions);
      const result = await response.json();
      
      if (result.error) {
        setSnapshotError(result.error);
      } else if (result.success) {
        // Update the comparison info state with the new timestamp at the beginning
        const newComparisonInfo = {
          ...comparisonInfo,
          pending_comparisons: [
            new Date().toISOString(),
            ...(comparisonInfo?.pending_comparisons || [])
          ]
        };
        setComparisonInfo(newComparisonInfo);
      }
    } catch (error) {
      setSnapshotError(error.message);
    } finally {
      setSnapshotLoading(false);
    }
  };

  // Check if the credit was issued today (East Coast time)
  const isIssuedToday = (issueDate) => {
    if (!issueDate) return true; // If no issue date, don't show warning
    
    const eastCoastDate = dayjs().tz('America/New_York').format('YYYY-MM-DD');
    return dayjs(issueDate).format('YYYY-MM-DD') === eastCoastDate;
  };

  // Format date for display (MM/DD/YYYY)
  const formatDateForDisplay = (dateString) => {
    return dayjs(dateString).format('MM/DD/YYYY');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="record-savings-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxWidth: 1000,
        maxHeight: '90vh',
        bgcolor: 'white',
        borderRadius: 2,
        overflow: 'auto',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      }}>
        {discrepancyNotes.length > 0 && (
          <DiscrepancyAlert 
            open={discrepancyNotes.length > 0}
            onClose={(success, message) => {
              if (success) {
                // Call onSuccess with the message from the API
                onSuccess(message || 'Refare event recorded successfully');
                // Close the RecordRepricing modal
                onClose();
              } else {
                setDiscrepancyNotes([]);
              }
            }}
            discrepancyNotes={discrepancyNotes}
            reservationData={constructRequestData()}
          />
        )}

        {/* Header */}
        <Box sx={{ 
          p: 3, 
          borderBottom: '1px solid', 
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          bgcolor: 'white',
          zIndex: 1,
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 600, color: '#1a1a1a' }}>Record Savings</Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
              {hasRecentComparison() ? (
                <Tooltip 
                  title={getComparisonTimeInfo()}
                  placement="bottom"
                  arrow
                  open={tooltipOpen}
                  onOpen={() => setTooltipOpen(true)}
                  onClose={() => setTooltipOpen(false)}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'white',
                        color: '#1a1a1a',
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                        p: 1.5,
                        '& .MuiTooltip-arrow': {
                          color: 'white',
                        },
                        whiteSpace: 'pre-line',
                        fontSize: '0.75rem',
                      }
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    bgcolor: 'rgba(76, 175, 80, 0.1)',
                    color: '#4caf50',
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 1,
                    cursor: 'help',
                  }}>
                    <CheckCircleIcon sx={{ fontSize: 16 }} />
                    <Typography sx={{ fontSize: '0.75rem', fontWeight: 500 }}>
                      Recent Price Comparison Available
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={snapshotError || ''}
                  open={!!snapshotError}
                  placement="bottom"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#ff4444',
                        color: 'white',
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                        p: 1.5,
                        '& .MuiTooltip-arrow': {
                          color: '#ff4444',
                        },
                        fontSize: '0.75rem',
                      }
                    }
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSnapshotClick}
                    disabled={snapshotLoading}
                    sx={{
                      bgcolor: '#ff4444',
                      color: 'white',
                      animation: 'pulse 2s infinite',
                      '@keyframes pulse': {
                        '0%': {
                          boxShadow: '0 0 0 0 rgba(255, 68, 68, 0.4)',
                        },
                        '70%': {
                          boxShadow: '0 0 0 10px rgba(255, 68, 68, 0)',
                        },
                        '100%': {
                          boxShadow: '0 0 0 0 rgba(255, 68, 68, 0)',
                        },
                      },
                      '&:hover': {
                        bgcolor: '#ff4444',
                      },
                      minWidth: snapshotLoading ? 142 : 'auto', // Prevent button width change during loading
                    }}
                  >
                    {snapshotLoading ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size={16} sx={{ color: 'white' }} />
                        <Typography sx={{ fontSize: '0.875rem' }}>
                          Snapshotting...
                        </Typography>
                      </Box>
                    ) : (
                      'Snapshot Reservation Details'
                    )}
                  </Button>
                </Tooltip>
              )}
              <IconButton onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* User Info Card */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            color: '#666'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <PersonIcon sx={{ color: '#535aff' }} />
              <Typography sx={{ fontWeight: 500, color: '#1a1a1a' }}>
                {userInfo?.first_name} {userInfo?.last_name}
              </Typography>
              <Box sx={{ 
                bgcolor: userInfo?.plan === 'pro' ? '#4caf50' : 
                        userInfo?.plan === 'card_linked' ? '#535aff' : 
                        '#535aff', 
                color: 'white', 
                px: 1.5, 
                py: 0.5, 
                borderRadius: 1,
                fontSize: '0.75rem',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}>
                {userInfo?.plan === 'card_linked' ? 'CARD LINKED' : userInfo?.plan?.toUpperCase() || 'FREE'}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CreditCardIcon sx={{ fontSize: 18 }} />
              {billingInfo?.brand ? (
                <Typography variant="body2">
                  {billingInfo?.brand?.toUpperCase() || 'CARD'} *{billingInfo?.last4} (Expires {String(billingInfo?.exp_month).padStart(2, '0')}/{String(billingInfo?.exp_year).slice(-2)})
                </Typography>
              ) : (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                  bgcolor: 'rgba(255, 68, 68, 0.1)',
                  color: '#ff4444',
                  px: 1.5,
                  py: 0.5,
                  borderRadius: 1,
                  fontSize: '0.75rem',
                  fontWeight: 500,
                }}>
                  <Typography variant="body2">
                    No payment method linked
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocationOnIcon sx={{ fontSize: 18 }} />
              <Typography variant="body2" sx={{ maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {billingInfo?.address?.line1}
                {billingInfo?.address?.line2 ? `, ${billingInfo.address.line2}` : ''}, {billingInfo?.address?.city}, {billingInfo?.address?.state} {billingInfo?.address?.postal_code}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Add Passengers Section */}
        <Box sx={{ 
          px: 3, 
          py: 1,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: removedPassengers.length > 0 ? 2 : 0 }}>
            {removedPassengers.length > 0 && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {removedPassengers.map((passenger) => (
                  <Button
                    key={passenger.passenger_id}
                    variant="outlined"
                    onClick={() => handleAddPassenger(passenger)}
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{ 
                      borderColor: 'rgba(83, 90, 255, 0.3)',
                      color: '#535aff',
                      bgcolor: 'white',
                      '&:hover': {
                        borderColor: '#535aff',
                        bgcolor: 'rgba(83, 90, 255, 0.05)',
                      }
                    }}
                  >
                    {passenger.first_name} {passenger.last_name}
                  </Button>
                ))}
              </Box>
            )}
            {airline === 'american' && userInfo?.email_refresh_tokens?.length > 0 && (
              <Tooltip 
                title={
                  <Box sx={{ p: 0.5 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
                      Linked emails:
                    </Typography>
                    {userInfo.email_refresh_tokens.map((email, index) => (
                      <Typography key={index} variant="body2">{email}</Typography>
                    ))}
                  </Box>
                }
                arrow
              >
                <Button
                  variant="outlined"
                  onClick={handleAAEmailLookup}
                  disabled={loadingAAEmail}
                  startIcon={
                    <Box 
                      component="img" 
                      src={AIRLINE_LOGOS.american}
                      sx={{ 
                        width: 20,
                        height: 20,
                        objectFit: 'contain',
                        borderRadius: '50%',
                      }}
                    />
                  }
                  sx={{
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                    color: '#1a1a1a',
                    ml: 'auto',
                    '&:hover': {
                      borderColor: 'rgba(0, 0, 0, 0.24)',
                      bgcolor: 'rgba(0, 0, 0, 0.02)',
                    }
                  }}
                >
                  {loadingAAEmail ? (
                    <CircularProgress size={16} sx={{ color: '#1a1a1a' }} />
                  ) : (
                    'Get AA Trip Credit from Email'
                  )}
                </Button>
              </Tooltip>
            )}
          </Box>
        </Box>

        {/* Main Content */}
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          flex: 1,
        }}>
          {/* Passenger Cards - Scrollable */}
          <Box sx={{ 
            flex: 1,
            overflowY: 'auto',
            px: 3,
            pt: 3,
            pb: activePassengers.length === 0 ? 0 : 3,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'rgba(0, 0, 0, 0.2)',
            },
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {activePassengers.length === 0 ? (
                <Box sx={{ 
                  height: 160,
                  border: '2px dashed rgba(0, 0, 0, 0.12)',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#666',
                  gap: 1.5,
                  bgcolor: 'rgba(0, 0, 0, 0.02)',
                  transition: 'all 0.2s',
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: '#535aff',
                    bgcolor: 'rgba(83, 90, 255, 0.02)',
                    color: '#535aff',
                  }
                }}>
                  <AddIcon sx={{ fontSize: 28 }} />
                  <Typography sx={{ fontSize: '0.875rem' }}>
                    Start by selecting passengers above to record their savings
                  </Typography>
                </Box>
              ) : (
                activePassengers.map((passenger) => {
                  const isProcessed = processedPassengers.includes(passenger.passenger_id);
                  const isConfirmed = confirmedCredits[passenger.passenger_id];
                  const isCollapsed = showCollapsed[passenger.passenger_id];
                  
                  return (
                    <Box
                      key={passenger.passenger_id}
                      onClick={() => handleToggleCollapse(passenger.passenger_id)}
                      sx={{
                        p: 2.5,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: isConfirmed ? '#4caf50' : 'rgba(0, 0, 0, 0.12)',
                        bgcolor: isConfirmed ? 'rgba(76, 175, 80, 0.04)' : 'white',
                        transition: 'all 0.3s ease',
                        position: 'relative',
                        cursor: 'pointer',
                        '&:hover': isConfirmed ? {
                          bgcolor: 'rgba(76, 175, 80, 0.08)',
                        } : {
                          bgcolor: 'rgba(0, 0, 0, 0.02)',
                        },
                      }}
                    >
                      {/* Passenger Header */}
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: isCollapsed ? 0 : 2
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <KeyboardArrowDownIcon 
                              sx={{ 
                                transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease',
                                color: '#666',
                                fontSize: 20,
                              }}
                            />
                            <Typography sx={{ fontWeight: 600, color: '#1a1a1a' }}>
                              {passenger.first_name} {passenger.last_name}
                            </Typography>
                          </Box>
                          {!isCollapsed && !isConfirmed && (
                            <Tooltip 
                              title={!voucherNumbers[passenger.passenger_id] ? "Please enter a voucher number first" : ""}
                              arrow
                              placement="top"
                            >
                              <span>
                                <Button
                                  onClick={(e) => handleLookupClick(e, passenger.passenger_id)}
                                  disabled={loading[passenger.passenger_id] || !voucherNumbers[passenger.passenger_id]}
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    bgcolor: '#535aff',
                                    color: 'white',
                                    height: 32,
                                    textTransform: 'none',
                                    '&:hover': {
                                      bgcolor: '#4045cc',
                                    },
                                    '&.Mui-disabled': {
                                      bgcolor: 'rgba(83, 90, 255, 0.12)',
                                      color: 'rgba(83, 90, 255, 0.5)',
                                    },
                                    pointerEvents: !voucherNumbers[passenger.passenger_id] ? 'auto' : 'auto',
                                  }}
                                >
                                  {loading[passenger.passenger_id] ? (
                                    <CircularProgress size={16} sx={{ color: 'white' }} />
                                  ) : (
                                    `Lookup Credit With ${airline.charAt(0).toUpperCase() + airline.slice(1)}`
                                  )}
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                          {isCollapsed && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, ml: 2 }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography sx={{ fontSize: '0.875rem', color: '#666' }}>
                                  Voucher:
                                </Typography>
                                <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                                  {voucherNumbers[passenger.passenger_id]}
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography sx={{ fontSize: '0.875rem', color: '#666' }}>
                                  Amount:
                                </Typography>
                                <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                                  ${amountSaved[passenger.passenger_id]}
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography sx={{ fontSize: '0.875rem', color: '#666' }}>
                                  Expires:
                                </Typography>
                                <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                                  {expirationDates[passenger.passenger_id]?.format('MM/DD/YYYY')}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {isConfirmed && (
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              gap: 1,
                              color: '#4caf50',
                              bgcolor: 'rgba(76, 175, 80, 0.1)',
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 1,
                            }}>
                              <CheckCircleIcon sx={{ fontSize: 16 }} />
                              <Typography sx={{ fontSize: '0.75rem', fontWeight: 500 }}>
                                Credit Confirmed
                              </Typography>
                            </Box>
                          )}
                          <IconButton 
                            size="small" 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemovePassenger(passenger.passenger_id);
                            }}
                            sx={{ 
                              color: '#666',
                              '&:hover': {
                                color: '#ff4444',
                                bgcolor: 'rgba(255, 68, 68, 0.05)',
                              }
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>

                      {/* Warning Alert in collapsed view */}
                      {isCollapsed && 
                       confirmedCredits[passenger.passenger_id] && 
                       issueDates[passenger.passenger_id] && 
                       !isIssuedToday(issueDates[passenger.passenger_id]) && (
                        <Box sx={{ mt: 1, width: '100%' }}>
                          <Alert severity="warning" sx={{ fontSize: '0.875rem' }}>
                            Credit was issued on {formatDateForDisplay(issueDates[passenger.passenger_id])}, are you sure that a credit was processed as a part of this transaction?
                          </Alert>
                        </Box>
                      )}

                      {/* Credit Info Fields */}
                      <Collapse in={!isCollapsed} timeout={300}>
                        {/* Warning Alert in expanded view */}
                        {confirmedCredits[passenger.passenger_id] && 
                         issueDates[passenger.passenger_id] && 
                         !isIssuedToday(issueDates[passenger.passenger_id]) && (
                          <Box sx={{ mt: 1, mb: 1, px: 2 }}>
                            <Alert severity="warning" sx={{ fontSize: '0.875rem' }}>
                              Credit was issued on {formatDateForDisplay(issueDates[passenger.passenger_id])}, are you sure that a credit was processed as a part of this transaction?
                            </Alert>
                          </Box>
                        )}
                        
                        <Box sx={{ 
                          display: 'grid', 
                          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                          gap: 2,
                          alignItems: 'start',
                          p: 2,
                          pt: 0,
                        }}>
                          <Box>
                            <Typography sx={{ mb: 1, color: '#666', fontSize: '0.875rem' }}>
                              Voucher Number
                            </Typography>
                            <TextField
                              size="small"
                              value={voucherNumbers[passenger.passenger_id] || ''}
                              onClick={handleInputClick}
                              onChange={(e) => {
                                handleDataChange(passenger.passenger_id);
                                setVoucherNumbers({
                                  ...voucherNumbers,
                                  [passenger.passenger_id]: e.target.value
                                });
                              }}
                              placeholder="Enter voucher number"
                              error={!!error[passenger.passenger_id]}
                              helperText={error[passenger.passenger_id]}
                              sx={{ 
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                  height: 56,
                                  bgcolor: 'white',
                                  animation: pulsingInputId === passenger.passenger_id ? 'pulseError 1s cubic-bezier(0.4, 0, 0.6, 1)' : 'none',
                                },
                                '@keyframes pulseError': pulseAnimation['@keyframes pulseError'],
                              }}
                            />
                          </Box>

                          <Box>
                            <Typography sx={{ mb: 1, color: '#666', fontSize: '0.875rem' }}>
                              Amount
                            </Typography>
                            <TextField
                              size="small"
                              value={amountSaved[passenger.passenger_id] || ''}
                              onClick={handleInputClick}
                              onChange={(e) => {
                                handleDataChange(passenger.passenger_id);
                                setAmountSaved({
                                  ...amountSaved,
                                  [passenger.passenger_id]: e.target.value
                                });
                              }}
                              placeholder="0.00"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              sx={{ 
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                  height: 56,
                                  bgcolor: 'white',
                                }
                              }}
                            />
                          </Box>

                          <Box>
                            <Typography sx={{ mb: 1, color: '#666', fontSize: '0.875rem' }}>
                              Expiration Date
                            </Typography>
                            <DatePicker
                              value={expirationDates[passenger.passenger_id] || null}
                              disabled={refundTypes[passenger.passenger_id] === 'Cash'}
                              onChange={(newValue) => {
                                handleDataChange(passenger.passenger_id);
                                setExpirationDates({
                                  ...expirationDates,
                                  [passenger.passenger_id]: newValue
                                });
                              }}
                              sx={{ width: '100%' }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select date",
                                  onClick: (e) => {
                                    e.stopPropagation();
                                  },
                                  sx: { 
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': {
                                      height: 56,
                                      bgcolor: 'white',
                                      '&.Mui-disabled': {
                                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor: 'rgba(0, 0, 0, 0.12)'
                                        }
                                      }
                                    }
                                  }
                                },
                                popper: {
                                  sx: {
                                    zIndex: 1400
                                  },
                                  onClick: (e) => e.stopPropagation()
                                },
                                desktopPaper: {
                                  onClick: (e) => e.stopPropagation()
                                }
                              }}
                            />
                          </Box>

                          <Box>
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              gap: 1,
                              mb: 1,
                              height: 21 // Match the height of other labels
                            }}>
                              <Typography sx={{ color: '#666', fontSize: '0.875rem' }}>
                                Credit Type
                              </Typography>
                              <Button
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDataChange(passenger.passenger_id);
                                  setRefundTypes({
                                    ...refundTypes,
                                    [passenger.passenger_id]: 'Cash'
                                  });
                                  setVoucherNumbers({
                                    ...voucherNumbers,
                                    [passenger.passenger_id]: 'Refund to Original Form of Payment'
                                  });
                                  setExpirationDates({
                                    ...expirationDates,
                                    [passenger.passenger_id]: null
                                  });
                                }}
                                sx={{
                                  minWidth: 'auto',
                                  px: 1,
                                  py: 0.25,
                                  fontSize: '0.75rem',
                                  color: '#535aff',
                                  bgcolor: 'rgba(83, 90, 255, 0.08)',
                                  '&:hover': {
                                    bgcolor: 'rgba(83, 90, 255, 0.12)',
                                  }
                                }}
                              >
                                Refund
                              </Button>
                              {airline === 'american' && (
                                <Button
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDataChange(passenger.passenger_id);
                                    setVoucherNumbers({
                                      ...voucherNumbers,
                                      [passenger.passenger_id]: 'Trip Credit in AA Account'
                                    });
                                  }}
                                  sx={{
                                    minWidth: 'auto',
                                    px: 1,
                                    py: 0.25,
                                    fontSize: '0.75rem',
                                    color: '#535aff',
                                    bgcolor: 'rgba(83, 90, 255, 0.08)',
                                    '&:hover': {
                                      bgcolor: 'rgba(83, 90, 255, 0.12)',
                                    }
                                  }}
                                >
                                  No #
                                </Button>
                              )}
                            </Box>
                            <FormControl 
                              size="small" 
                              onClick={handleInputClick}
                              sx={{ 
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                  height: 56,
                                  bgcolor: 'white',
                                }
                              }}
                            >
                              <Select
                                value={refundTypes[passenger.passenger_id] || 'Voucher'}
                                onChange={(e) => {
                                  handleDataChange(passenger.passenger_id);
                                  const newType = e.target.value;
                                  setRefundTypes({
                                    ...refundTypes,
                                    [passenger.passenger_id]: newType
                                  });

                                  // Set default voucher number for Cash type
                                  if (newType === 'Cash') {
                                    setVoucherNumbers({
                                      ...voucherNumbers,
                                      [passenger.passenger_id]: 'Refund to Original Form of Payment'
                                    });
                                    // Clear expiration date for Cash type
                                    setExpirationDates({
                                      ...expirationDates,
                                      [passenger.passenger_id]: null
                                    });
                                  } else if (airline === 'united') {
                                    // Reset to PNR for United if switching back to Voucher
                                    setVoucherNumbers({
                                      ...voucherNumbers,
                                      [passenger.passenger_id]: pnr
                                    });
                                  }
                                }}
                              >
                                <MenuItem value="Voucher">Voucher</MenuItem>
                                <MenuItem value="Cash">Cash</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  );
                })
              )}
            </Box>
          </Box>

          {/* Summary Section - Always visible, more compact */}
          <Box sx={{ 
            px: 3,
            pb: 3,
            pt: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
            bgcolor: 'white',
          }}>
            <Box sx={{ 
              p: 2.5, 
              bgcolor: '#f8f9fa', 
              borderRadius: 2,
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 2,
              }}>
                <Typography sx={{ fontWeight: 600, color: '#1a1a1a' }}>
                  Summary
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={sendConfirmationEmail}
                      onChange={(e) => setSendConfirmationEmail(e.target.checked)}
                      size="small"
                      sx={{ 
                        color: '#535aff',
                        '&.Mui-checked': {
                          color: '#535aff',
                        }
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: '0.875rem', color: '#666' }}>
                      Send customer confirmation email
                    </Typography>
                  }
                />
              </Box>

              <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                gap: 2,
                mb: 2,
              }}>
                <Box>
                  <Typography sx={{ mb: 0.5, color: '#666', fontSize: '0.875rem' }}>
                    Total Amount
                  </Typography>
                  <TextField
                    size="small"
                    value={totalAmount}
                    onChange={(e) => setTotalAmount(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    sx={{ 
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        height: 36,
                        bgcolor: 'white',
                      }
                    }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ mb: 0.5, color: '#666', fontSize: '0.875rem' }}>
                    LFG Rate
                  </Typography>
                  <TextField
                    size="small"
                    value={(parseFloat(lfgRate) * 100).toFixed(1)}
                    onChange={(e) => setLfgRate((parseFloat(e.target.value) / 100).toString())}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    sx={{ 
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        height: 36,
                        bgcolor: 'white',
                      }
                    }}
                  />
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 0.5 }}>
                    <Typography sx={{ color: '#666', fontSize: '0.875rem' }}>
                      Billing Amount
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={doNotBill}
                          onChange={(e) => setDoNotBill(e.target.checked)}
                          size="small"
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#535aff',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#535aff',
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.75rem', color: '#666' }}>
                          Do not bill this event
                        </Typography>
                      }
                    />
                  </Box>
                  <TextField
                    size="small"
                    value={billingAmount}
                    onChange={(e) => setBillingAmount(e.target.value)}
                    disabled={doNotBill}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    sx={{ 
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        height: 36,
                        bgcolor: 'white',
                        '&.Mui-disabled': {
                          bgcolor: 'rgba(0, 0, 0, 0.04)',
                        }
                      }
                    }}
                  />
                  {outstandingReferralCredits > 0 && (
                    <Box sx={{ 
                      mt: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={applyReferralCredits}
                            onChange={(e) => setApplyReferralCredits(e.target.checked)}
                            size="small"
                            sx={{
                              color: '#535aff',
                              '&.Mui-checked': {
                                color: '#535aff',
                              },
                            }}
                          />
                        }
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Typography sx={{ fontSize: '0.75rem', color: '#666' }}>
                              Apply referral credits
                            </Typography>
                            <Typography sx={{ 
                              fontSize: '0.75rem', 
                              color: '#535aff',
                              fontWeight: 600,
                              bgcolor: 'rgba(83, 90, 255, 0.08)',
                              px: 1,
                              py: 0.25,
                              borderRadius: 1,
                            }}>
                              ${outstandingReferralCredits.toFixed(2)}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              {doNotBill && (
                <TextField
                  fullWidth
                  size="small"
                  value={doNotBillReason}
                  onChange={(e) => setDoNotBillReason(e.target.value)}
                  placeholder="Enter reason for not billing"
                  sx={{ 
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                      height: 36,
                      bgcolor: 'white',
                    }
                  }}
                />
              )}

              {error.submit && (
                <Alert 
                  severity="error" 
                  sx={{ mb: 2 }}
                  onClose={() => setError({ ...error, submit: null })}
                >
                  {error.submit}
                </Alert>
              )}

              <Button
                fullWidth
                variant="contained"
                size="large"
                disabled={submitting}
                onClick={handleSubmit}
                sx={{
                  bgcolor: '#535aff',
                  color: 'white',
                  py: 1.25,
                  fontSize: '1rem',
                  fontWeight: 600,
                  position: 'relative',
                  '&:hover': {
                    bgcolor: '#4045cc',
                  },
                  '&.Mui-disabled': {
                    bgcolor: submitting ? '#535aff' : 'rgba(83, 90, 255, 0.12)',
                    color: submitting ? 'white' : 'rgba(83, 90, 255, 0.5)',
                    opacity: submitting ? 0.8 : 1,
                  }
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                  opacity: submitting ? 0.8 : 1,
                  transition: 'opacity 0.2s ease'
                }}>
                  {submitting ? (
                    <>
                      <CircularProgress 
                        size={24} 
                        sx={{ 
                          color: 'white',
                          animation: 'spin 1s linear infinite',
                          '@keyframes spin': {
                            '0%': {
                              transform: 'rotate(0deg)',
                            },
                            '100%': {
                              transform: 'rotate(360deg)',
                            },
                          },
                        }} 
                      />
                      <span>Recording Savings...</span>
                    </>
                  ) : (
                    'Complete Repricing Transaction'
                  )}
                </Box>
                {submitting && (
                  <LinearProgress 
                    sx={{ 
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: 3,
                      bgcolor: 'rgba(255, 255, 255, 0.2)',
                      '& .MuiLinearProgress-bar': {
                        bgcolor: 'white',
                      }
                    }} 
                  />
                )}
              </Button>
            </Box>
          </Box>
        </Box>

        <Popper
          open={aaCreditPopupOpen || false}
          anchorEl={aaCreditPopupAnchor}
          placement="bottom-end"
          sx={{ zIndex: 1300 }}
        >
          <Draggable handle=".drag-handle">
            <Paper
              elevation={3}
              sx={{
                p: 2,
                width: 300,
                maxHeight: minimized ? 40 : 400,
                overflow: 'hidden',
                transition: 'max-height 0.3s ease',
              }}
            >
              <Box 
                className="drag-handle"
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  mb: minimized ? 0 : 2,
                  cursor: 'move',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DragIndicatorIcon sx={{ color: '#666' }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    AA Trip Credits
                  </Typography>
                </Box>
                <Box>
                  {minimized ? (
                    <IconButton size="small" onClick={() => setMinimized(false)}>
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton size="small" onClick={() => setMinimized(true)}>
                        <MinimizeIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => setAACreditPopupOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
              
              {!minimized && (
                aaCreditError ? (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    {aaCreditError}
                  </Alert>
                ) : (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: 1,
                    maxHeight: 300,
                    overflowY: 'auto'
                  }}>
                    {aaTripCredits.map((credit, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        onClick={(e) => handleCopyCredit(credit, e)}
                        sx={{
                          justifyContent: 'space-between',
                          borderColor: copiedCredit === credit ? '#4caf50' : 'rgba(0, 0, 0, 0.12)',
                          color: copiedCredit === credit ? '#4caf50' : '#1a1a1a',
                          bgcolor: copiedCredit === credit ? 'rgba(76, 175, 80, 0.08)' : 'transparent',
                          '&:hover': {
                            borderColor: copiedCredit === credit ? '#4caf50' : '#535aff',
                            bgcolor: copiedCredit === credit ? 'rgba(76, 175, 80, 0.12)' : 'rgba(83, 90, 255, 0.05)',
                          },
                          transition: 'all 0.2s ease',
                          px: 2,
                          py: 1,
                        }}
                      >
                        <Typography sx={{ fontSize: '0.875rem', mr: 1 }}>
                          {credit}
                        </Typography>
                        {copiedCredit === credit ? (
                          <CheckCircleIcon sx={{ 
                            fontSize: 18,
                            color: '#4caf50',
                          }} />
                        ) : (
                          <ContentCopyIcon sx={{ 
                            fontSize: 18,
                            color: '#666',
                          }} />
                        )}
                      </Button>
                    ))}
                  </Box>
                )
              )}
            </Paper>
          </Draggable>
        </Popper>

        <UnableToProcessModal
          open={unableToProcessOpen}
          onClose={() => setUnableToProcessOpen(false)}
        />
      </Box>
    </Modal>
  );
}

export default RecordRepricing; 