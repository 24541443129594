import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  InputAdornment,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getToken } from '../utils/auth';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import ReservationDisplayComponent from './ReservationDisplayComponent';
import { alpha } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../styles/fonts.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const theme = createTheme({
  palette: {
    primary: {
      main: '#535aff',
    },
  },
  typography: {
    fontFamily: '"Haffer Trial", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    body1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    caption: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    overline: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Haffer Trial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Regular.woff') format('woff');
        }
      `,
    },
  },
});

function RecordRefaring() {
  const [reservationId, setReservationId] = useState('');
  const [pnr, setPnr] = useState('');
  const [reservationData, setReservationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPassenger, setSelectedPassenger] = useState('');
  const [voucherNumber, setVoucherNumber] = useState('');
  const [savingsBlocks, setSavingsBlocks] = useState([]);
  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [chargeAmount, setChargeAmount] = useState('');
  const [creditAmount, setCreditAmount] = useState('');
  const [chargePercentage, setChargePercentage] = useState('0');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [doNotBill, setDoNotBill] = useState(false);
  const [doNotBillReason, setDoNotBillReason] = useState('');
  const [doNotSendEmail, setDoNotSendEmail] = useState(false);
  const [billingInfo, setBillingInfo] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loadingCredit, setLoadingCredit] = useState(false);
  const [activeBlockIndex, setActiveBlockIndex] = useState(null);
  const [useReferralCredits, setUseReferralCredits] = useState(false);
  const [referralCredits, setReferralCredits] = useState(0);
  const [subtotal, setSubtotal] = useState('0.00');
  const [unableToProcessModal, setUnableToProcessModal] = useState(false);
  const [unableToProcessReason, setUnableToProcessReason] = useState('');
  const [sendCustomerEmail, setSendCustomerEmail] = useState(true);
  const [keepTracking, setKeepTracking] = useState(true);
  const [errorType, setErrorType] = useState('');
  const [tripCreditModalOpen, setTripCreditModalOpen] = useState(false);
  const [tripCreditInfo, setTripCreditInfo] = useState(null);
  const [tripCreditError, setTripCreditError] = useState(null);
  const [tripCreditLoading, setTripCreditLoading] = useState(false);
  const [discrepancyModalOpen, setDiscrepancyModalOpen] = useState(false);
  const [discrepancyNotes, setDiscrepancyNotes] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleLookup = useCallback(async (id = reservationId, lookupPnr = pnr) => {
    setLoading(true);
    setError(null);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());

    let raw;
    let url;

    if (id) {
      raw = JSON.stringify({ "reservation_id": parseInt(id) });
      url = "https://admin-api.withautopilot.com/admin_portal/find_reservation_for_refare";
    } else if (lookupPnr) {
      raw = JSON.stringify({ "pnr": lookupPnr });
      url = "https://admin-api.withautopilot.com/admin_portal/find_reservation_for_refare";
    } else {
      setError('Please enter either a Reservation ID or PNR');
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      setReservationData(result);
      // if (result.reservation_id) {
      //   setReservationId(result.reservation_id.toString());
      // }
      // if (result.pnr) {
      //   setPnr(result.pnr);
      // }
      if (result.lfg_percentage) {
        setChargePercentage((result.lfg_percentage * 100).toFixed(2));
      }
    } catch (error) {
      console.error('Error fetching reservation data:', error);
      setError('Failed to fetch reservation data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [reservationId, pnr]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reservationIdFromQuery = queryParams.get('reservation_id');
    if (reservationIdFromQuery) {
      setReservationId(reservationIdFromQuery);
      handleLookup(reservationIdFromQuery);
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate, handleLookup]);

  useEffect(() => {
    if (reservationData) {
      if (reservationData.billing_info && reservationData.billing_info !== "No billing info available") {
        setBillingInfo(reservationData.billing_info);
        setPaymentMethod(`${reservationData.billing_info.brand} **** ${reservationData.billing_info.last4}`);
      }
      if (reservationData.referral_code && reservationData.referral_code.toLowerCase() === 'zsgsph') {
        setChargeAmount('0.00');
        setDoNotBill(true);
        setDoNotBillReason("Friend's and Family");
      }
      setSavingsBlocks([]);
      setReferralCredits(reservationData.user_referral_credits || 0);
    }
  }, [reservationData]);

  useEffect(() => {
    const totalSavings = savingsBlocks.reduce((sum, block) => sum + parseFloat(block.amountSaved || 0), 0);
    setCreditAmount(totalSavings.toFixed(2));
    const calculatedCharge = (totalSavings * (parseFloat(chargePercentage) / 100)).toFixed(2);
    setChargeAmount(calculatedCharge);
  }, [savingsBlocks, chargePercentage]);

  useEffect(() => {
    if (selectedPassenger && reservationData?.airline?.toLowerCase() === 'united') {
      setVoucherNumber(reservationData.pnr || '');
    }
  }, [selectedPassenger, reservationData]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLookup();
    }
  };

  const getAirlineCode = (airlineName) => {
    const airlineMap = {
      'american': 'aa',
      'delta': 'dl',
      'united': 'ua'
    };
    return airlineMap[airlineName.toLowerCase()] || '';
  };

  const handleCreditLookup = useCallback(async () => {
    if (!selectedPassenger || !voucherNumber) {
      setError('Please select a passenger and enter a voucher number.');
      return;
    }
  
    const passenger = reservationData.passengers.find(p => p.passenger_id === selectedPassenger);
    if (!passenger) {
      setError('Selected passenger not found.');
      return;
    }
  
    setLoadingCredit(true);
    setError(null);
  
    const airlineCode = getAirlineCode(reservationData.airline);
    if (!airlineCode) {
      setError('Invalid or unsupported airline.');
      setLoadingCredit(false);
      return;
    }
  
    const url = `https://admin-api.withautopilot.com/dev/find_credit_info?airline=${airlineCode}&credit_number=${voucherNumber}&first_name=${passenger.first_name}&last_name=${passenger.last_name}`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Key': 'ZSGSPH',
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      let creditInfo;
  
      if (Array.isArray(result)) {
        // Sort by issue_date in descending order and take the most recent one
        creditInfo = result.sort((a, b) => {
          if (!a.issue_date) return 1;  // Push items without issue_date to the end
          if (!b.issue_date) return -1;
          return new Date(b.issue_date) - new Date(a.issue_date);
        })[0];
      } else {
        creditInfo = result;
      }
      
      // Ensure total_amount has exactly 2 decimal places
      const formattedAmount = parseFloat(creditInfo.total_amount).toFixed(2);
      
      const expirationDate = creditInfo.expiration_date 
        ? dayjs(creditInfo.expiration_date)
        : dayjs().add(366, 'day');
  
      setSavingsBlocks(prev => [...prev, {
        passengerId: selectedPassenger,
        passengerName: `${passenger.first_name} ${passenger.last_name}`,
        voucherNumber,
        amountSaved: formattedAmount,
        expirationDate: expirationDate,
        refundType: 'Voucher'
      }]);
      setActiveBlockIndex(savingsBlocks.length);
      setSelectedPassenger('');
      setVoucherNumber('');
  
    } catch (error) {
      console.error('Error fetching credit info:', error);
      setError('Failed to fetch credit info. Please try again.');
    } finally {
      setLoadingCredit(false);
    }
  }, [selectedPassenger, voucherNumber, reservationData, savingsBlocks.length]);

  const handleManualEntry = () => {
    if (!selectedPassenger) {
      setError('Please select a passenger.');
      return;
    }

    const passenger = reservationData.passengers.find(p => p.passenger_id === selectedPassenger);
    if (!passenger) {
      setError('Selected passenger not found.');
      return;
    }

    const defaultVoucherNumber = 
      reservationData?.airline?.toLowerCase() === 'united' 
        ? (voucherNumber || reservationData.pnr)
        : voucherNumber;

    setSavingsBlocks(prev => [...prev, {
      passengerId: selectedPassenger,
      passengerName: `${passenger.first_name} ${passenger.last_name}`,
      voucherNumber: defaultVoucherNumber,
      amountSaved: '',
      expirationDate: dayjs().add(366, 'day'),
      refundType: 'Voucher'
    }]);
    setActiveBlockIndex(savingsBlocks.length);
    setSelectedPassenger('');
    setVoucherNumber('');
  };

  const handleRemoveSavingsBlock = (index) => {
    setSavingsBlocks(prev => prev.filter((_, i) => i !== index));
    setActiveBlockIndex(null);
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setLoading(true);
    const transactionData = {
      reservation_id: reservationData.reservation_id,
      passenger_refaring: savingsBlocks.map(block => ({
        passenger_id: block.passengerId,
        amount_saved: block.amountSaved,
        voucher_number: block.voucherNumber,
        voucher_expiration: block.expirationDate ? block.expirationDate.toISOString() : null,
        refund_type: block.refundType,
      })),
      charge_amount: chargeAmount,
      payment_method: paymentMethod,
      do_not_bill_this_event: doNotBill,
      do_not_bill_reason: doNotBillReason,
      do_not_send_customer_email: doNotSendEmail,
      stripe_customer_id: reservationData?.stripe_customer_id,
      billing_amount: chargeAmount,
      setup_intent_id: billingInfo?.setup_intent_id,
      card_info: billingInfo ? {
        brand: billingInfo.brand,
        last4: billingInfo.last4,
        exp_month: billingInfo.exp_month,
        exp_year: billingInfo.exp_year,
        postal_code: billingInfo.postal_code,
      } : null,
    };

    if (useReferralCredits && referralCredits > 0) {
      const creditsApplied = Math.min(referralCredits, parseFloat(subtotal));
      transactionData.autopilot_credits_redeemed = creditsApplied.toFixed(2);
    }

    console.log(JSON.stringify(transactionData));

    try {
      const response = await fetch('https://admin-api.withautopilot.com/admin_portal/record_refare', {
        method: 'POST',
        headers: {
          'token': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('API Response:', result);
      
      if (result.discrepancy_notes) {
        setDiscrepancyNotes(result);
        setDiscrepancyModalOpen(true);
      } else {
        setSnackbarMessage(`Successfully recorded refaring for reservation ${reservationData.reservation_id}`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        
        // Only reset form state if there are no discrepancy notes
        setReservationData(null);
        setReservationId('');
        setSavingsBlocks([]);
        setChargeAmount('');
        setPaymentMethod('');
        setDoNotBill(false);
        setDoNotBillReason('');
        setDoNotSendEmail(false);
        setShowBillingInfo(false);
      }
    } catch (error) {
      console.error('Error recording refare:', error);
      setSnackbarMessage('Failed to record refare. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  }, [reservationData, savingsBlocks, chargeAmount, paymentMethod, doNotBill, doNotBillReason, doNotSendEmail, reservationData, billingInfo, useReferralCredits, referralCredits, subtotal]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleChargeAmountChange = (e) => {
    setChargeAmount(e.target.value);
  };

  const handleChargeAmountBlur = () => {
    if (chargeAmount === '') {
      setChargeAmount('0.00');
    } else {
      setChargeAmount(parseFloat(chargeAmount).toFixed(2));
    }
  };

  const handleCreditAmountChange = (e) => {
    setCreditAmount(e.target.value);
    const newChargeAmount = (parseFloat(e.target.value) * (parseFloat(chargePercentage) / 100)).toFixed(2);
    setChargeAmount(newChargeAmount);
  };

  const handleChargePercentageChange = (e) => {
    setChargePercentage(e.target.value);
    const newChargeAmount = (parseFloat(creditAmount) * (parseFloat(e.target.value) / 100)).toFixed(2);
    setChargeAmount(newChargeAmount);
  };

  const canAddMorePassengers = reservationData && reservationData.passengers && savingsBlocks.length < reservationData.passengers.length;

  // Add this function to calculate the final charge amount
  const calculateFinalChargeAmount = (amount) => {
    let charge = parseFloat(amount) || 0;
    if (useReferralCredits) {
      charge = Math.max(0, charge - referralCredits);
    }
    return charge.toFixed(2);
  };

  const handleFinalBillingAmountChange = (e) => {
    const newAmount = e.target.value;
    setChargeAmount(newAmount);
    // Recalculate credit amount and percentage based on the new final billing amount
    if (newAmount && creditAmount) {
      const newPercentage = ((parseFloat(newAmount) / parseFloat(creditAmount)) * 100).toFixed(2);
      setChargePercentage(newPercentage);
    }
  };

  const handleUseReferralCreditsChange = (e) => {
    setUseReferralCredits(e.target.checked);
    calculateCharges();
  };

  // Update this function to calculate subtotal and final amount
  const calculateCharges = useCallback(() => {
    const totalCredit = parseFloat(creditAmount) || 0;
    const percentage = parseFloat(chargePercentage) || 0;
    const calculatedSubtotal = (totalCredit * (percentage / 100)).toFixed(2);
    setSubtotal(calculatedSubtotal);

    let finalAmount = parseFloat(calculatedSubtotal);
    if (useReferralCredits) {
      finalAmount = Math.max(0, finalAmount - referralCredits);
    }
    setChargeAmount(finalAmount.toFixed(2));
  }, [creditAmount, chargePercentage, useReferralCredits, referralCredits]);

  useEffect(() => {
    calculateCharges();
  }, [calculateCharges]);

  const handleUnableToProcess = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://admin-api.withautopilot.com/admin_portal/send_couldnt_refare_email', {
        method: 'POST',
        headers: {
          'token': getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reservation_id: reservationId,
          error: errorType,
          is_tracking_turned_off: !keepTracking,
          send_email: sendCustomerEmail
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        setSnackbarMessage('Successfully recorded unable to process status');
        setSnackbarSeverity('success');
      } else {
        throw new Error('Failed to record unable to process status');
      }
    } catch (error) {
      console.error('Error recording unable to process:', error);
      setSnackbarMessage('Failed to record unable to process status');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setUnableToProcessModal(false);
      setLoading(false);
      // Reset form
      setErrorType('');
      setSendCustomerEmail(true);
      setKeepTracking(true);
    }
  };

  const handleGetTripCreditInfo = async () => {
    if (!reservationData?.user_id) {
      setTripCreditError("No user ID found in reservation data");
      setTripCreditModalOpen(true);
      return;
    }

    setTripCreditLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    myHeaders.append("token", getToken());

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ "user_id": reservationData.user_id.toString() }),
      redirect: "follow"
    };

    try {
      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/get_aa_trip_credit_info_from_email", requestOptions);
      const result = await response.json();
      
      if (result.error) {
        setTripCreditError(result.error);
        setTripCreditModalOpen(true);
      } else if (result.trip_credit_info && Array.isArray(result.trip_credit_info) && result.trip_credit_info.length > 0) {
        if (selectedPassenger && result.trip_credit_info[0]) {
          setVoucherNumber(result.trip_credit_info[0]);
        } else {
          setTripCreditInfo(result.trip_credit_info);
          setTripCreditModalOpen(true);
        }
      } else {
        setTripCreditError("No trip credits found");
        setTripCreditModalOpen(true);
      }
    } catch (error) {
      console.error(error);
      setTripCreditError("Failed to fetch trip credit information");
      setTripCreditModalOpen(true);
    } finally {
      setTripCreditLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom>Record Refaring</Typography>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Reservation ID"
                value={reservationId}
                onChange={(e) => setReservationId(e.target.value)}
                onKeyPress={handleKeyPress}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#535aff',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#535aff',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="PNR"
                value={pnr}
                onChange={(e) => setPnr(e.target.value)}
                onKeyPress={handleKeyPress}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#535aff',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#535aff',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                onClick={() => handleLookup()}
                disabled={loading || (!reservationId && !pnr)}
                fullWidth
                sx={{
                  bgcolor: '#535aff',
                  '&:hover': {
                    bgcolor: alpha('#535aff', 0.8),
                  },
                }}
              >
                {loading ? <CircularProgress size={24} /> : 'Lookup'}
              </Button>
            </Grid>
          </Grid>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Paper>

        {reservationData && (
          <ReservationDisplayComponent reservationData={reservationData} />
        )}

        {canAddMorePassengers && (
          <Paper elevation={3} sx={{ p: 2, mb: 2, bgcolor: '#e8f5e9' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                Record Savings ({savingsBlocks.length} of {
                  (reservationData?.passengers || []).length
                } {(reservationData?.passengers || []).length === 1 ? 'Passenger' : 'Passengers'})
              </Typography>
              {reservationData?.airline?.toLowerCase() === 'american' && (
                <Button
                  variant="outlined"
                  sx={{ 
                    ml: 2,
                    borderColor: '#535aff',
                    color: '#535aff',
                    '&:hover': {
                      borderColor: '#535aff',
                      bgcolor: alpha('#535aff', 0.1),
                    },
                  }}
                  size="small"
                  onClick={handleGetTripCreditInfo}
                  disabled={tripCreditLoading}
                  startIcon={tripCreditLoading ? <CircularProgress size={20} /> : null}
                >
                  {tripCreditLoading ? 'Loading Credits...' : 'Get Trip Credit Info From Linked Email'}
                </Button>
              )}
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel sx={{ '&.Mui-focused': { color: '#535aff' } }}>Passenger</InputLabel>
                  <Select
                    value={selectedPassenger}
                    onChange={(e) => setSelectedPassenger(e.target.value)}
                    label="Passenger"
                    sx={{
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#535aff',
                      },
                    }}
                  >
                    {reservationData.passengers &&
                      reservationData.passengers
                        .filter(passenger => !savingsBlocks.some(block => block.passengerId === passenger.passenger_id))
                        .map((passenger) => (
                          <MenuItem key={passenger.passenger_id} value={passenger.passenger_id}>
                            {`${passenger.first_name} ${passenger.last_name}`}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Voucher Number"
                  value={voucherNumber}
                  onChange={(e) => setVoucherNumber(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#535aff',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#535aff',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  onClick={handleCreditLookup}
                  disabled={loadingCredit || !selectedPassenger || !voucherNumber}
                  fullWidth
                  sx={{
                    bgcolor: '#535aff',
                    '&:hover': {
                      bgcolor: alpha('#535aff', 0.8),
                    },
                  }}
                >
                  {loadingCredit ? <CircularProgress size={24} /> : 'Lookup Credit Info'}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="outlined"
                  onClick={handleManualEntry}
                  disabled={!selectedPassenger}
                  fullWidth
                  sx={{
                    borderColor: '#535aff',
                    color: '#535aff',
                    '&:hover': {
                      borderColor: '#535aff',
                      bgcolor: alpha('#535aff', 0.1),
                    },
                  }}
                >
                  Enter Credit Info Manually
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {savingsBlocks.map((block, index) => (
          <Paper 
            key={index} 
            elevation={3} 
            sx={{ 
              p: 2, 
              mb: 2, 
              position: 'relative', 
              bgcolor: alpha('#535aff', 0.05),
              boxShadow: activeBlockIndex === index ? `0 0 0 2px #535aff` : 'none',
            }}
          >
            <IconButton
              onClick={() => handleRemoveSavingsBlock(index)}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom>Savings for {block.passengerName}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Voucher Number"
                  value={block.voucherNumber}
                  onChange={(e) => {
                    const newBlocks = [...savingsBlocks];
                    newBlocks[index].voucherNumber = e.target.value;
                    setSavingsBlocks(newBlocks);
                  }}
                  required={block.refundType === 'Voucher'}
                  error={block.refundType === 'Voucher' && !block.voucherNumber}
                  helperText={block.refundType === 'Voucher' && !block.voucherNumber ? 'Voucher number is required' : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Amount Saved"
                  value={block.amountSaved}
                  onChange={(e) => {
                    const newBlocks = [...savingsBlocks];
                    newBlocks[index].amountSaved = e.target.value;
                    setSavingsBlocks(newBlocks);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  required
                  error={!block.amountSaved}
                  helperText={!block.amountSaved ? 'Amount saved is required' : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Voucher Expiration"
                  value={block.expirationDate}
                  onChange={(newValue) => {
                    const newBlocks = [...savingsBlocks];
                    newBlocks[index].expirationDate = newValue;
                    setSavingsBlocks(newBlocks);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Refund Type</InputLabel>
                  <Select
                    value={block.refundType}
                    onChange={(e) => {
                      const newBlocks = [...savingsBlocks];
                      newBlocks[index].refundType = e.target.value;
                      setSavingsBlocks(newBlocks);
                    }}
                    label="Refund Type"
                  >
                    <MenuItem value="Voucher">Voucher</MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        ))}

        {reservationData && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: '#535aff',
                  '&:hover': {
                    bgcolor: alpha('#535aff', 0.8),
                  },
                }}
                onClick={() => setShowBillingInfo(true)}
              >
                Review Billing Info
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setUnableToProcessModal(true)}
              >
                Unable to Process
              </Button>
            </Box>
          </Box>
        )}

        <Modal
          open={showBillingInfo}
          onClose={() => setShowBillingInfo(false)}
          aria-labelledby="billing-modal-title"
          aria-describedby="billing-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #535aff',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}>
            <Typography id="billing-modal-title" variant="h6" component="h2" gutterBottom>
              Billing Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Final Billing Amount"
                  value={chargeAmount}
                  onChange={handleFinalBillingAmountChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  disabled={doNotBill}
                />
              </Grid>
              {referralCredits > 0 && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useReferralCredits}
                        onChange={handleUseReferralCreditsChange}
                      />
                    }
                    label={`User has $${referralCredits.toFixed(2)} in refaring credits. Apply them to this transaction?`}
                    sx={{ typography: 'caption', alignItems: 'flex-start' }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    label="Payment Method"
                    disabled={doNotBill}
                  >
                    {billingInfo && (
                      <MenuItem value={`${billingInfo.brand} **** ${billingInfo.last4}`}>
                        {billingInfo.brand} **** {billingInfo.last4} (exp. {billingInfo.exp_month}/{billingInfo.exp_year})
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Pricing Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Total Credit Amount"
                          value={creditAmount}
                          onChange={(e) => {
                            setCreditAmount(e.target.value);
                            calculateCharges();
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          disabled={doNotBill}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Charge Percentage"
                          value={chargePercentage}
                          onChange={(e) => {
                            setChargePercentage(e.target.value);
                            calculateCharges();
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          disabled={doNotBill}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Subtotal"
                          value={subtotal}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          disabled
                        />
                      </Grid>
                      {useReferralCredits && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Refaring Credits Applied"
                            value={(-referralCredits).toFixed(2)}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Final Billing Amount"
                          value={chargeAmount}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Savings & Credits Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Total Savings"
                          value={reservationData?.savings_info?.total_savings?.toFixed(2) || '0.00'}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true,
                          }}
                          disabled
                        />
                      </Grid>
                      {reservationData?.savings_info?.price_change_events?.map((event, index) => (
                        <Grid item xs={12} key={event.event_id}>
                          <Paper elevation={1} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Price Change Event {index + 1} - {new Date(event.event_date).toLocaleDateString()}
                            </Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  Original Price: ${event.original_price}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  New Price: ${event.new_price}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  Savings: ${event.price_difference}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  Type: {event.change_type}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                          Available Voucher Credits
                        </Typography>
                        {reservationData?.savings_info?.voucher_credits?.map((credit) => (
                          <Paper 
                            key={credit.credit_id} 
                            elevation={1} 
                            sx={{ p: 2, mb: 1, bgcolor: '#f5f5f5' }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  Amount: ${credit.amount}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  Reference: {credit.reference_number}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  Issue Date: {new Date(credit.issue_date).toLocaleDateString()}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                  Expires: {new Date(credit.expiration_date).toLocaleDateString()}
                                </Typography>
                              </Grid>
                              {credit.notes && (
                                <Grid item xs={12}>
                                  <Typography variant="body2" color="textSecondary">
                                    Notes: {credit.notes}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Paper>
                        ))}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Additional Billing Options</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={doNotBill}
                              onChange={(e) => setDoNotBill(e.target.checked)}
                            />
                          }
                          label="Do not bill this event"
                        />
                      </Grid>
                      {doNotBill && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Reason for not billing"
                            value={doNotBillReason}
                            onChange={(e) => setDoNotBillReason(e.target.value)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={doNotSendEmail}
                              onChange={(e) => setDoNotSendEmail(e.target.checked)}
                            />
                          }
                          label="Do Not Send Customer a Confirmation Email"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Complete Refaring Transaction'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={unableToProcessModal}
          onClose={() => setUnableToProcessModal(false)}
          aria-labelledby="unable-to-process-modal-title"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #535aff',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}>
            <Typography id="unable-to-process-modal-title" variant="h6" component="h2" gutterBottom>
              Unable to Process
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Reason</InputLabel>
                  <Select
                    value={errorType}
                    onChange={(e) => setErrorType(e.target.value)}
                    label="Reason"
                    required
                  >
                    <MenuItem value="elite_upgrade">Upgrade Applied</MenuItem>
                    <MenuItem value="ineligible_fare">Ineligible Fare</MenuItem>
                    <MenuItem value="partially_flown">Partially Flown Ticket</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendCustomerEmail}
                      onChange={(e) => setSendCustomerEmail(e.target.checked)}
                    />
                  }
                  label="Send customer email"
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={keepTracking}
                        onChange={(e) => setKeepTracking(e.target.checked)}
                      />
                    }
                    label="Keep Tracking On"
                  />
                  <Typography variant="caption" color="textSecondary" sx={{ pl: 2 }}>
                    {keepTracking 
                      ? "This reservation will still be tracked"
                      : "Tracking will be turned off once you complete this transaction"}
                  </Typography>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUnableToProcess}
                  fullWidth
                  disabled={loading || !errorType}
                >
                  {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={tripCreditModalOpen}
          onClose={() => setTripCreditModalOpen(false)}
          aria-labelledby="trip-credit-modal-title"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #535aff',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}>
            <Typography id="trip-credit-modal-title" variant="h6" component="h2" gutterBottom>
              Trip Credit Information
            </Typography>
            {tripCreditLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 3 }}>
                <CircularProgress />
                <Typography sx={{ ml: 2 }}>Loading trip credits...</Typography>
              </Box>
            ) : tripCreditError ? (
              <Alert severity="error">{tripCreditError}</Alert>
            ) : tripCreditInfo ? (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Available Trip Credits:
                </Typography>
                {tripCreditInfo.map((credit, index) => (
                  <Paper
                    key={index}
                    elevation={1}
                    sx={{
                      p: 2,
                      mb: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      bgcolor: '#f5f5f5'
                    }}
                  >
                    <Typography variant="h6" sx={{ fontFamily: 'monospace' }}>
                      {credit}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setVoucherNumber(credit);
                          setTripCreditModalOpen(false);
                        }}
                        sx={{ 
                          bgcolor: '#535aff',
                          '&:hover': {
                            bgcolor: alpha('#535aff', 0.8),
                          }
                        }}
                      >
                        Use This Credit
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(credit);
                          setSnackbarMessage('Credit number copied to clipboard');
                          setSnackbarSeverity('success');
                          setSnackbarOpen(true);
                        }}
                        sx={{ 
                          color: '#535aff',
                          borderColor: '#535aff',
                          '&:hover': {
                            borderColor: '#535aff',
                            bgcolor: alpha('#535aff', 0.1),
                          }
                        }}
                      >
                        Copy Number
                      </Button>
                    </Box>
                  </Paper>
                ))}
              </Box>
            ) : (
              <Alert severity="info">No trip credits found</Alert>
            )}
            <Button
              fullWidth
              variant="contained"
              onClick={() => setTripCreditModalOpen(false)}
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </Box>
        </Modal>

        <Modal
          open={discrepancyModalOpen}
          onClose={() => setDiscrepancyModalOpen(false)}
          aria-labelledby="discrepancy-modal-title"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #535aff',
            boxShadow: 24,
            p: 4,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 2,
              backgroundColor: '#ffebee',
              p: 2,
              borderRadius: 1
            }}>
              <Typography variant="h5" component="h2" color="error" sx={{ display: 'flex', alignItems: 'center' }}>
                ⚠️ Important Changes Detected
              </Typography>
            </Box>
            
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              The following changes were detected in the reservation:
            </Typography>

            <Box sx={{ 
              backgroundColor: '#ffebee',
              p: 2,
              borderRadius: 1,
              mb: 3
            }}>
              {discrepancyNotes && discrepancyNotes.discrepancy_notes && (
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  {discrepancyNotes.discrepancy_notes.map((note, index) => (
                    <li key={index}>
                      <Typography variant="body1" color="error" sx={{ py: 0.5 }}>
                        {note}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </Box>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Please review these changes carefully before proceeding.
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleSubmit}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                disabled={loading}
                sx={{ flex: 1 }}
              >
                {loading ? 'Retrying...' : 'Retry Submission'}
              </Button>
              <Button
                variant="outlined"
                onClick={() => setDiscrepancyModalOpen(false)}
                sx={{ flex: 1 }}
              >
                Close Alert
              </Button>
            </Box>
          </Box>
        </Modal>
    
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}

export default RecordRefaring;
