import React from 'react';
import { Grid, Paper, Typography, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PaidIcon from '@mui/icons-material/Paid';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UpdateIcon from '@mui/icons-material/Update';
import ImportExportIcon from '@mui/icons-material/ImportExport';

const InfoRow = ({ icon, label, value }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  }}>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      minWidth: { xs: '100%', sm: 'auto' },
      mb: { xs: 0.5, sm: 0 }
    }}>
      {icon}
      <Typography sx={{ 
        display: 'flex',
        alignItems: 'center',
        ml: 1,
        color: '#666',
        fontSize: { xs: '0.875rem', sm: '1rem' }
      }}>
        {label}
      </Typography>
    </Box>
    <Typography sx={{ 
      ml: { xs: 0, sm: 1 },
      pl: { xs: 4, sm: 0 },
      fontSize: { xs: '0.875rem', sm: '1rem' },
      wordBreak: 'break-word'
    }}>
      {value}
    </Typography>
  </Box>
);

const SpecificInformation = ({ reservationData }) => {
  const theme = useTheme();

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const formatPrice = (price, isAward = false, awardPrice = null) => {
    if (isAward && awardPrice) {
      return `${new Intl.NumberFormat('en-US').format(awardPrice)} Miles + ${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(price)}`;
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  const iconStyle = {
    fontSize: '20px',
    color: theme.palette.primary.main
  };

  const paperStyle = {
    p: { xs: 1.5, sm: 2 },
    border: '1px solid #e0e0e0',
    borderRadius: '12px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  return (
    <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ mt: 0 }}>
      <Grid item xs={12} md={6}>
        <Paper elevation={0} sx={paperStyle}>
          <Stack spacing={{ xs: 1.5, sm: 2 }} sx={{ height: '100%' }}>
            <InfoRow 
              icon={<PaidIcon sx={iconStyle} />}
              label="Total Payment:"
              value={formatPrice(
                reservationData.pricing_details.total_fare,
                reservationData.pricing_details.is_award,
                reservationData.pricing_details.award_price
              )}
            />

            <InfoRow 
              icon={<ConfirmationNumberIcon sx={iconStyle} />}
              label="Ticket Number:"
              value={reservationData.reservation_details.ticket_number}
            />

            <InfoRow 
              icon={<EventIcon sx={iconStyle} />}
              label="Issue Date:"
              value={formatDate(reservationData.reservation_details.ticket_issue_date)}
            />
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={0} sx={paperStyle}>
          <Stack spacing={{ xs: 1.5, sm: 2 }} sx={{ height: '100%' }}>
            <InfoRow 
              icon={<AccessTimeIcon sx={iconStyle} />}
              label="Date Added:"
              value={formatDate(reservationData.reservation_details.date_added)}
            />

            <InfoRow 
              icon={<UpdateIcon sx={iconStyle} />}
              label="Last Refreshed:"
              value={formatDate(reservationData.reservation_details.date_updated)}
            />

            <InfoRow 
              icon={<ImportExportIcon sx={iconStyle} />}
              label="Import Method:"
              value={reservationData.reservation_details.import_method}
            />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SpecificInformation; 