import { alpha } from '@mui/material/styles';

export const MAJOR_AIRLINES = ['aa', 'ua', 'dl'];

export const AIRLINE_LOGOS = {
    delta: 'https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/660ca876659da2dcd1f270e6_unnamed.webp',
    american: 'https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/660d5ea1eff61f9aceefc7bf_unnamed%20(1).webp',
    united: 'https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/660d5ed83484fc0fb471306a_unnamed%20(2).webp'
};

export const AIRLINE_LOGO_STYLES = {
    majorAirline: {
        borderRadius: '12px',
        border: '2px solid #1976d2',
        padding: '8px'
    },
    default: {
        borderRadius: '4px',
        padding: '4px'
    }
};

export const getAirlineLogo = (airline) => {
    if (!airline) return { url: null, style: AIRLINE_LOGO_STYLES.default };
    
    const airlineKey = airline.toLowerCase();
    let logoUrl = null;
    let style = AIRLINE_LOGO_STYLES.default;

    if (airlineKey === 'american' || airlineKey === 'aa') {
        logoUrl = AIRLINE_LOGOS.american;
        style = AIRLINE_LOGO_STYLES.majorAirline;
    } else if (airlineKey === 'united' || airlineKey === 'ua') {
        logoUrl = AIRLINE_LOGOS.united;
        style = AIRLINE_LOGO_STYLES.majorAirline;
    } else if (airlineKey === 'delta' || airlineKey === 'dl') {
        logoUrl = AIRLINE_LOGOS.delta;
        style = AIRLINE_LOGO_STYLES.majorAirline;
    }

    return { url: logoUrl, style };
};

export const LOYALTY_TIER_STYLES = {
  SILVER: {
    bg: alpha('#C0C0C0', 0.15),
    color: '#808080',
    gradient: 'linear-gradient(45deg, #C0C0C0, #E8E8E8, #C0C0C0)'
  },
  GOLD: {
    bg: alpha('#FFD700', 0.15),
    color: '#B8860B',
    gradient: 'linear-gradient(45deg, #FFD700, #FDB931, #FFD700)'
  },
  PLATINUM: {
    bg: alpha('#E5E4E2', 0.15),
    color: '#666666',
    gradient: 'linear-gradient(45deg, #CCD1D9, #E5E4E2, #95A5A6)',
    textColor: '#2C3E50'
  },
  TOP_TIER: {
    bg: alpha('#000000', 0.1),
    color: '#000000',
    gradient: 'linear-gradient(90deg, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)',
    shimmer: true
  },
  INVITE_ONLY: {
    bg: alpha('#000000', 0.1),
    color: '#000000',
    gradient: 'linear-gradient(90deg, #0A0A0A, #1A1A1A, #000000)',
    shimmer: true,
    borderGradient: 'linear-gradient(90deg, #2C3E50, #000000, #2C3E50)'
  },
  DEFAULT: {
    bg: alpha('#808080', 0.15),
    color: '#808080',
    gradient: 'linear-gradient(45deg, #808080, #A9A9A9, #808080)'
  }
};

export const mapLoyaltyStatus = (loyaltyProgram, airline) => {
  if (!loyaltyProgram) return 'None';
  return loyaltyProgram.airline_status || 'Member';
};

export const getLoyaltyStyle = (loyaltyProgram) => {
  if (!loyaltyProgram) return {};
  const status = loyaltyProgram.airline_status;
  if (!status) return LOYALTY_TIER_STYLES.DEFAULT;
  return LOYALTY_TIER_STYLES[status] || LOYALTY_TIER_STYLES.DEFAULT;
};

// Create a map for loyalty status tiers
export const LOYALTY_TIER_MAP = {
  AA: {
    SILVER: 'Gold',
    GOLD: 'Platinum', 
    PLATINUM: 'Platinum Pro',
    TOP_TIER: 'Executive Platinum',
    INVITE_ONLY: 'Concierge Key'
  },
  UA: {
    SILVER: 'Silver',
    GOLD: 'Gold',
    PLATINUM: 'Platinum', 
    TOP_TIER: '1K',
    INVITE_ONLY: 'Global Services',
    DEFAULT: 'Member'
  },
  DL: {
    SILVER: 'Silver',
    GOLD: 'Gold',
    PLATINUM: 'Platinum',
    TOP_TIER: 'Diamond',
    INVITE_ONLY: 'Delta 360'
  }
}