// /Users/sam/Desktop/autopilot-admin-app/src/components/NavMenu.js

import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import FlightIcon from '@mui/icons-material/Flight';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../styles/fonts.css';

const pages = ['Dashboard', 'User Lookup', 'Reservation Lookup', 'Passenger Lookup'];
const lookupOptions = [
  { name: 'Lookup PNR with Airline', path: '/pnr-lookup' },
  { name: 'Lookup Credit Info with Airline', path: '/credit-info-lookup' },
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#535aff',
    },
  },
  typography: {
    fontFamily: '"Haffer Trial", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    body1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    caption: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    overline: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Haffer Trial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Regular.woff') format('woff');
        }
      `,
    },
  },
});

function NavMenu({ onLogout }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElLookup, setAnchorElLookup] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenLookupMenu = (event) => {
    setAnchorElLookup(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseLookupMenu = () => {
    setAnchorElLookup(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    onLogout();
    navigate('/login');
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ backgroundColor: '#535aff' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              component={RouterLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                textDecoration: 'none',
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/67964906eaf7bb15224ee4f2_Frame%20(12).svg"
                alt="Autopilot Logo"
                style={{ height: '30px' }}
              />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, index) => (
                  <React.Fragment key={page}>
                    <MenuItem 
                      onClick={handleCloseNavMenu} 
                      component={RouterLink} 
                      to={page === 'Dashboard' ? '/' : 
                         page === 'User Lookup' ? '/users' :
                         page === 'Reservation Lookup' ? '/reservation' :
                         page === 'Passenger Lookup' ? '/passenger' :
                         `/${page.toLowerCase().replace(' ', '-')}`}
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: '8px',
                        py: 1.5,
                        px: 3
                      }}
                    >
                      {page === 'Dashboard' && <DashboardIcon fontSize="small" />}
                      {page === 'User Lookup' && <PersonIcon fontSize="small" />}
                      {page === 'Reservation Lookup' && <FlightIcon fontSize="small" />}
                      {page === 'Passenger Lookup' && <GroupIcon fontSize="small" />}
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                    {index < pages.length - 1 && (
                      <Box
                        sx={{
                          height: '1px',
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          mx: 2
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
                <Box
                  sx={{
                    height: '1px',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    mx: 2
                  }}
                />
                {lookupOptions.map((option, index) => (
                  <React.Fragment key={option.name}>
                    <MenuItem 
                      onClick={handleCloseNavMenu} 
                      component={RouterLink} 
                      to={option.path}
                      sx={{ 
                        py: 1.5,
                        px: 3
                      }}
                    >
                      <Typography textAlign="center">{option.name}</Typography>
                    </MenuItem>
                    {index < lookupOptions.length - 1 && (
                      <Box
                        sx={{
                          height: '1px',
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          mx: 2
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Menu>
            </Box>
            <Box
              component={RouterLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                textDecoration: 'none',
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/67964906eaf7bb15224ee4f2_Frame%20(12).svg"
                alt="Autopilot Logo"
                style={{ height: '24px' }}
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: '8px' }}>
              {pages.map((page, index) => (
                <React.Fragment key={page}>
                  <Button
                    component={RouterLink}
                    to={page === 'Dashboard' ? '/' : 
                       page === 'User Lookup' ? '/users' :
                       page === 'Reservation Lookup' ? '/reservation' :
                       page === 'Passenger Lookup' ? '/passenger' :
                       `/${page.toLowerCase().replace(' ', '-')}`}
                    onClick={handleCloseNavMenu}
                    sx={{ 
                      my: 2, 
                      color: 'white', 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: '4px',
                      px: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)'
                      }
                    }}
                    startIcon={
                      page === 'Dashboard' ? <DashboardIcon /> :
                      page === 'User Lookup' ? <PersonIcon /> :
                      page === 'Reservation Lookup' ? <FlightIcon /> :
                      page === 'Passenger Lookup' ? <GroupIcon /> :
                      null
                    }
                  >
                    {page}
                  </Button>
                  {index < pages.length - 1 && (
                    <Box
                      sx={{
                        height: '24px',
                        width: '1px',
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        my: 'auto'
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
              <Box
                sx={{
                  height: '24px',
                  width: '1px',
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  my: 'auto'
                }}
              />
              <Button
                onClick={handleOpenLookupMenu}
                sx={{ 
                  my: 2, 
                  color: 'white', 
                  display: 'flex',
                  px: 2,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Lookup
              </Button>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar-lookup"
                anchorEl={anchorElLookup}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElLookup)}
                onClose={handleCloseLookupMenu}
              >
                {lookupOptions.map((option) => (
                  <MenuItem key={option.name} onClick={handleCloseLookupMenu} component={RouterLink} to={option.path}>
                    <Typography textAlign="center">{option.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Settings</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default NavMenu;
