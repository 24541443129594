import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Stack,
  Chip,
  Link,
  Divider,
  Tooltip
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { AIRLINE_LOGOS } from '../utils';

const getVoucherName = (airline) => {
  const airlineName = airline.charAt(0).toUpperCase() + airline.slice(1).toLowerCase();
  switch (airline.toLowerCase()) {
    case 'united':
      return `${airlineName} Future Flight Credit`;
    case 'delta':
      return `${airlineName} eCredit`;
    case 'american':
      return `${airlineName} Trip Credit`;
    default:
      return `${airlineName} Voucher`;
  }
};

const getAirlineLogo = (airline) => {
  const airlineKey = airline.toLowerCase();
  return AIRLINE_LOGOS[airlineKey] || null;
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

const formatDateTime = (dateString) => {
  return new Date(dateString).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

const formatPrice = (amount, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency
  }).format(amount);
};

const groupPriceChangeEvents = (events) => {
  if (!events || events.length === 0) return [];
  
  // Sort events by date
  const sortedEvents = [...events].sort((a, b) => 
    new Date(a.event_date) - new Date(b.event_date)
  );
  
  const groupedEvents = [];
  let currentGroup = {
    event_date: sortedEvents[0].event_date,
    price_difference: sortedEvents[0].price_difference,
    original_price: sortedEvents[0].original_price,
    new_price: sortedEvents[0].new_price,
    receipt_url: sortedEvents[0].receipt_url,
    event_id: sortedEvents[0].event_id,
    voucher_credits: [...(sortedEvents[0].voucher_credits || [])]
  };
  
  for (let i = 1; i < sortedEvents.length; i++) {
    const currentEvent = sortedEvents[i];
    const timeDiff = Math.abs(
      new Date(currentEvent.event_date) - new Date(currentGroup.event_date)
    ) / (1000 * 60); // Convert to minutes
    
    if (timeDiff <= 5) {
      // Combine events
      currentGroup.price_difference += currentEvent.price_difference;
      currentGroup.voucher_credits.push(...(currentEvent.voucher_credits || []));
      // Keep the receipt_url if it exists
      currentGroup.receipt_url = currentGroup.receipt_url || currentEvent.receipt_url;
      // Use the lower of the two new prices
      currentGroup.new_price = Math.min(currentGroup.new_price, currentEvent.new_price);
      // Combine event IDs
      currentGroup.event_id = `${currentGroup.event_id}-${currentEvent.event_id}`;
    } else {
      // Start a new group
      groupedEvents.push(currentGroup);
      currentGroup = {
        event_date: currentEvent.event_date,
        price_difference: currentEvent.price_difference,
        original_price: currentEvent.original_price,
        new_price: currentEvent.new_price,
        receipt_url: currentEvent.receipt_url,
        event_id: currentEvent.event_id,
        voucher_credits: [...(currentEvent.voucher_credits || [])]
      };
    }
  }
  
  // Add the last group
  groupedEvents.push(currentGroup);
  
  return groupedEvents;
};

const VoucherCard = ({ voucher }) => {
  const theme = useTheme();
  const voucherName = getVoucherName(voucher.issuing_airline);
  const airlineLogo = getAirlineLogo(voucher.issuing_airline);
  const passengerName = `${voucher.passenger_first_name} ${voucher.passenger_last_name}`;
  
  return (
    <Box
      sx={{
        position: 'relative',
        p: 2,
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        backgroundColor: '#fff',
        width: '100%',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        },
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          width: '12px',
          height: '24px',
          backgroundColor: '#f8f9ff',
          top: '50%',
          transform: 'translateY(-50%)',
          borderRadius: '24px',
          border: '1px solid #e0e0e0',
        },
        '&::before': {
          left: '-6px',
          borderRight: 'none',
        },
        '&::after': {
          right: '-6px',
          borderLeft: 'none',
        }
      }}
    >
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
              {formatPrice(voucher.amount, voucher.currency)}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {airlineLogo ? (
                <Box
                  sx={{
                    p: 0.5,
                    border: `1px solid ${theme.palette.primary.main}20`,
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img 
                    src={airlineLogo} 
                    alt={`${voucher.issuing_airline} logo`} 
                    style={{ 
                      height: 20,
                      width: 'auto',
                      objectFit: 'contain',
                      borderRadius: '2px'
                    }} 
                  />
                </Box>
              ) : (
                <CardGiftcardIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
              )}
              <Typography variant="body2" color="text.secondary" sx={{ 
                backgroundColor: `${theme.palette.primary.main}08`,
                px: 1,
                py: 0.5,
                borderRadius: '4px',
                fontSize: '0.75rem'
              }}>
                {voucherName}
              </Typography>
            </Box>
          </Box>
          <Chip 
            label={voucher.is_used ? "Used" : "Available"} 
            color={voucher.is_used ? "default" : "success"}
            size="small"
            sx={{ 
              fontWeight: 500,
              backgroundColor: voucher.is_used ? '#e0e0e0' : `${theme.palette.success.main}15`,
              color: voucher.is_used ? 'text.secondary' : theme.palette.success.main,
              border: 'none'
            }}
          />
        </Box>
        
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2,
            p: 1.5,
            backgroundColor: '#f8f9ff',
            borderRadius: '6px',
            position: 'relative',
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              width: '8px',
              height: '16px',
              backgroundColor: '#fff',
              top: '50%',
              transform: 'translateY(-50%)',
              borderRadius: '16px',
            },
            '&::before': {
              left: '-4px',
            },
            '&::after': {
              right: '-4px',
            }
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <Box>
              <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 0.5 }}>
                Passenger
              </Typography>
              <Tooltip title={`Passenger ID: ${voucher.passenger_id}`} arrow placement="top">
                <Typography variant="body2" sx={{ fontWeight: 500, cursor: 'help', '&:hover': { textDecoration: 'underline' } }}>
                  {passengerName}
                </Typography>
              </Tooltip>
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 0.5 }}>
                Reference Number
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {voucher.reference_number}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 0.5 }}>
                Expiration
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <CalendarTodayIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {formatDate(voucher.expiration_date)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

const PriceChangeEvent = ({ event, isLast }) => {
  const theme = useTheme();

  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ flex: 0.2 }}>
        <Typography variant="caption" color="text.secondary">
          {formatDateTime(event.event_date)}
        </Typography>
      </TimelineOppositeContent>
      
      <TimelineSeparator>
        <TimelineDot 
          sx={{ 
            backgroundColor: theme.palette.success.main,
            boxShadow: `0 0 0 3px ${theme.palette.success.main}15`
          }}
        >
          <TrendingDownIcon sx={{ fontSize: 16, color: 'white' }} />
        </TimelineDot>
        {!isLast && <TimelineConnector />}
      </TimelineSeparator>
      
      <TimelineContent>
        <Box 
          sx={{ 
            p: 2.5,
            backgroundColor: '#fff',
            border: '1px solid #e0e0e0',
            borderRadius: '12px',
            width: '100%',
            mb: 2
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5, color: theme.palette.success.main }}>
                Lowest Fare Guarantee Savings
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ArrowDownwardIcon sx={{ fontSize: 16, color: theme.palette.success.main }} />
                <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.success.main }}>
                  {formatPrice(event.price_difference)} Saved
                </Typography>
              </Box>
            </Box>
            {event.receipt_url && (
              <Link 
                href={event.receipt_url} 
                target="_blank" 
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 0.5,
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  padding: '6px 12px',
                  borderRadius: '6px',
                  backgroundColor: `${theme.palette.primary.main}10`,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}20`,
                  }
                }}
              >
                <ReceiptLongIcon sx={{ fontSize: 18 }} />
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  View Receipt
                </Typography>
              </Link>
            )}
          </Box>

          <Box 
            sx={{ 
              display: 'flex', 
              gap: 3, 
              mb: 3,
              p: 2,
              backgroundColor: '#f8f9ff',
              borderRadius: '8px'
            }}
          >
            <Box>
              <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 0.5 }}>
                Original Price
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500, textDecoration: 'line-through', color: 'text.secondary' }}>
                {formatPrice(event.original_price)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ArrowDownwardIcon sx={{ color: theme.palette.success.main, mx: 1 }} />
            </Box>
            <Box>
              <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 0.5 }}>
                New Price
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 600, color: theme.palette.success.main }}>
                {formatPrice(event.new_price)}
              </Typography>
            </Box>
          </Box>

          {event.voucher_credits && event.voucher_credits.length > 0 && (
            <>
              <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600, color: 'text.secondary' }}>
                Vouchers Generated
              </Typography>
              <Stack spacing={2}>
                {event.voucher_credits.map((voucher, index) => (
                  <VoucherCard key={voucher.credit_id} voucher={voucher} />
                ))}
              </Stack>
            </>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

const SavingsAndCredits = ({ priceChangeEvents }) => {
  const theme = useTheme();

  if (!priceChangeEvents || priceChangeEvents.length === 0) {
    return (
      <Paper 
        elevation={0}
        sx={{ 
          p: 2,
          border: '1px solid #e0e0e0',
          borderRadius: '12px',
          mt: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <AttachMoneyIcon color="primary" />
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Savings and Credits
          </Typography>
        </Box>

        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 6,
            px: 4,
            border: '2px dashed #e0e0e0',
            borderRadius: '8px',
            backgroundColor: '#f8f9ff',
            textAlign: 'center'
          }}
        >
          <Box 
            sx={{ 
              width: 48,
              height: 48,
              borderRadius: '50%',
              backgroundColor: `${theme.palette.primary.main}15`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2
            }}
          >
            <AttachMoneyIcon 
              sx={{ 
                color: theme.palette.primary.main,
                fontSize: '28px'
              }} 
            />
          </Box>
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'text.primary',
              mb: 1,
              fontWeight: 500
            }}
          >
            No savings or credits available yet
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ maxWidth: 400 }}
          >
            When the reservation has savings or credits, they will appear here
          </Typography>
        </Box>
      </Paper>
    );
  }

  const groupedEvents = groupPriceChangeEvents(priceChangeEvents);

  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: 2,
        border: '1px solid #e0e0e0',
        borderRadius: '12px',
        mt: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
        <AttachMoneyIcon color="primary" />
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          Savings and Credits
        </Typography>
      </Box>

      <Timeline position="right" sx={{ p: 0, m: 0 }}>
        {groupedEvents.map((event, index) => (
          <PriceChangeEvent 
            key={event.event_id} 
            event={event} 
            isLast={index === groupedEvents.length - 1}
          />
        ))}
      </Timeline>
    </Paper>
  );
};

export default SavingsAndCredits; 