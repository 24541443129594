// /Users/sam/Desktop/autopilot-admin-app/src/components/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#535aff',
    },
  },
  typography: {
    fontFamily: 'Haffer Trial, sans-serif',
  },
});

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const credentials = btoa(`${username}:${password}`);

    try {
      const response = await fetch('https://admin-api.withautopilot.com/admin_portal/auth_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login_data: credentials
        }),
      });

      const data = await response.json();

      if (data.true) {
        // Store the JWT as a cookie
        document.cookie = `token=${data.true}; path=/; max-age=2592000; secure; samesite=strict`;
        onLogin();
        navigate('/');
      } else if (data.false) {
        setError('Invalid username or password');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 4
            }}
          >
            <Box
              component="img"
              src="https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/6710229ee2ff495668a4beb5_Frame.svg"
              alt="Autopilot Logo"
              sx={{
                width: '200px',
              }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 1,
                color: '#535aff',
                fontWeight: 600,
                letterSpacing: '0.02em'
              }}
            >
              Beluga
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email"
              name="username"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#535aff',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#535aff',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#535aff',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#535aff',
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#535aff',
                '&:hover': {
                  backgroundColor: '#4248cc',
                },
                textTransform: 'none',
                fontWeight: 500,
              }}
            >
              Sign In
            </Button>
            {error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}
            <Typography 
              variant="caption" 
              align="center" 
              sx={{ 
                display: 'block',
                mt: 2,
                color: 'text.secondary',
                fontSize: '0.75rem',
                fontWeight: 400
              }}
            >
              Unauthorized use of this website is against Autopilot Terms of Service and will result in a full ban.
            </Typography>
            <Button
              component="a"
              href="https://fly.withautopilot.com"
              target="_self"
              variant="text"
              fullWidth
              sx={{
                mt: 2,
                color: 'text.secondary',
                textTransform: 'none',
                fontSize: '0.875rem',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline'
                }
              }}
            >
              Not an Autopilot Admin? Go to Homepage
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Login;