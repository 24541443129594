// /Users/sam/Desktop/autopilot-admin-app/src/components/HomePage.js

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress, List, ListItem, ListItemIcon, ListItemText, FormGroup, FormControlLabel, Switch, ToggleButton, ToggleButtonGroup, Skeleton, LinearProgress, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CountUp from 'react-countup';
import { getToken } from '../utils/auth';
import { AIRLINE_LOGOS } from './ReservationDisplay/utils';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Chart from 'react-apexcharts';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import StorageIcon from '@mui/icons-material/Storage';
import WebIcon from '@mui/icons-material/Web';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import '../styles/fonts.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#535aff',
    },
  },
  typography: {
    fontFamily: '"Haffer Trial", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    body1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    caption: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    overline: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Haffer Trial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Regular.woff') format('woff');
        }
      `,
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#535aff',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#4248cc',
            },
          },
        },
      },
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 16px rgba(83, 90, 255, 0.1)',
  },
}));

const StatTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#535aff',
  fontFamily: '"Haffer Trial", sans-serif',
  fontWeight: 600,
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.text.primary,
  fontFamily: '"Haffer Trial", sans-serif',
}));

const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

const GreenText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.875rem',
  marginTop: theme.spacing(1),
}));

const StatusPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const ChartControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const StatusList = styled(List)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
});

const AirlineStatusContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const AirlineStatusItem = styled(Paper)(({ theme, isOperational }) => ({
  padding: theme.spacing(1.2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  border: `2px solid ${isOperational ? '#00C853' : '#D50000'}`,
  borderRadius: '12px',
  flex: 1,
  minWidth: 0,
  maxWidth: '180px',
  backgroundColor: 'rgba(83, 90, 255, 0.03)',
  '&:hover': {
    backgroundColor: 'rgba(83, 90, 255, 0.06)',
  },
}));

const AirlineLogo = styled('img')({
  width: '28px',
  height: '28px',
  borderRadius: '6px',
  objectFit: 'contain',
  flexShrink: 0,
});

const SystemIcon = styled(Box)(({ theme }) => ({
  width: '28px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  '& svg': {
    fontSize: '20px',
    color: theme.palette.primary.main,
  }
}));

const StatusItemContent = styled(Box)({
  minWidth: 0,
  flex: 1,
  overflow: 'hidden',
});

const StatusItem = styled(ListItem)({
  padding: '8px 0',
});

const StatusText = styled(ListItemText)({
  margin: 0,
  marginLeft: '8px',
});

const LogoImage = styled('img')({
  height: '32px',
  marginRight: '12px',
  verticalAlign: 'middle',
});

const LoadingSkeleton = () => (
  <Box sx={{ flexGrow: 1, p: 3 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
      <Skeleton variant="rectangular" width={32} height={32} sx={{ mr: 1.5 }} />
      <Skeleton variant="text" width={200} height={40} />
    </Box>
    <Grid container spacing={3}>
      {[1, 2, 3, 4].map((item) => (
        <Grid item xs={12} sm={6} md={3} key={item}>
          <Item elevation={3}>
            <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
            <Skeleton variant="text" width="80%" height={40} />
            <Skeleton variant="text" width="40%" />
          </Item>
        </Grid>
      ))}
      <Grid item xs={12}>
        <StatusPaper elevation={3}>
          <Skeleton variant="text" width="20%" sx={{ mb: 2 }} />
          <Skeleton variant="text" width="30%" sx={{ mb: 3 }} />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <Skeleton
                key={item}
                variant="rectangular"
                width={180}
                height={80}
                sx={{ borderRadius: '12px' }}
              />
            ))}
          </Box>
        </StatusPaper>
      </Grid>
      <Grid item xs={12}>
        <Item elevation={3}>
          <Box sx={{ mb: 2 }}>
            <Skeleton variant="text" width="40%" />
          </Box>
          <Skeleton variant="rectangular" height={350} />
        </Item>
      </Grid>
    </Grid>
  </Box>
);

const SavingsBreakdown = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const ProgressLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
}));

const SavingsAmount = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

const LFGContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(0, 200, 83, 0.08)',
  borderRadius: theme.spacing(1),
}));

const UserTypeProgress = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
}));

const PulsatingDot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  backgroundColor: theme.palette.success.main,
  borderRadius: '50%',
  marginLeft: theme.spacing(1),
  animation: 'pulse 1.5s infinite',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 200, 83, 0.4)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 6px rgba(0, 200, 83, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 200, 83, 0)',
    },
  },
}));

const AwardContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(255, 167, 38, 0.08)',
  borderRadius: theme.spacing(1),
}));

export default function HomePage() {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [growthData, setGrowthData] = useState({
    users: [],
    reservations: [],
    savings: []
  });

  const [showUsers, setShowUsers] = useState(true);
  const [showReservations, setShowReservations] = useState(true);
  const [showSavings, setShowSavings] = useState(true);
  const [dataType, setDataType] = useState('total');

  const fetchDashboardData = useCallback(async () => {
    try {
      const token = getToken();
      const response = await fetch('https://admin-api.withautopilot.com/admin_portal/get_statistics', {
      //const response = await fetch('http://127.0.0.1:8080/admin_portal/get_statistics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStatistics(data);
        processGrowthData(data.growth_data);
        setLoading(false);
      } else {
        console.error('Failed to fetch dashboard data');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const processGrowthData = (growthData) => {
    const users = { new: [], total: [] };
    const reservations = { new: [], total: [] };
    const savings = { new: [], total: [] };
    Object.entries(growthData).forEach(([date, data]) => {
      const timestamp = new Date(date).getTime();
      users.new.push([timestamp, data.users.new]);
      users.total.push([timestamp, data.users.total]);
      reservations.new.push([timestamp, data.reservations.new]);
      reservations.total.push([timestamp, data.reservations.total]);
      savings.new.push([timestamp, data.savings.new]);
      savings.total.push([timestamp, data.savings.total]);
    });
    setGrowthData({ users, reservations, savings });
  };

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350
    },
    title: {
      text: `${dataType === 'total' ? 'Total' : 'New'} Growth over Time`,
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: [
      ...(showUsers ? [{
        title: { text: 'Users' },
        labels: {
          formatter: (value) => value >= 1000 ? Math.round(value).toLocaleString() : Math.round(value)
        }
      }] : []),
      ...(showReservations ? [{
        title: { text: 'Reservations' },
        opposite: showUsers,
        labels: {
          formatter: (value) => value >= 1000 ? Math.round(value).toLocaleString() : Math.round(value)
        }
      }] : []),
      ...(showSavings ? [{
        title: { text: '' },
        opposite: true,
        labels: {
          show: false
        },
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        }
      }] : [])
    ],
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, w }) => {
          const series = w.config.series[seriesIndex];
          if (series.name === 'Savings') {
            return `$${value >= 1000 ? value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : value.toFixed(2)}`;
          }
          return value >= 1000 ? Math.round(value).toLocaleString() : Math.round(value);
        }
      }
    },
    noData: {
      text: 'No data to display',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        fontSize: '20px',
      }
    }
  };

  const chartSeries = [
    ...(showUsers ? [{
      name: 'Users',
      data: growthData.users[dataType] || []
    }] : []),
    ...(showReservations ? [{
      name: 'Reservations',
      data: growthData.reservations[dataType] || []
    }] : []),
    ...(showSavings ? [{
      name: 'Savings',
      data: growthData.savings[dataType] || []
    }] : [])
  ];

  const handleDataTypeChange = (event, newDataType) => {
    if (newDataType !== null) {
      setDataType(newDataType);
    }
  };

  const allSystemsOperational = statistics?.system_status && 
    Object.values(statistics.system_status).every(status => status === true);

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 4,
        }}>
          <LogoImage 
            src="https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/6710229ee2ff495668a4beb5_Frame.svg"
            alt="Beluga Logo"
          />
          <Typography 
            variant="h4" 
            component="div" 
            sx={{ 
              fontWeight: 700,
              color: '#535aff',
              fontFamily: '"Haffer Trial", sans-serif',
            }}
          >
            Beluga Dashboard
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Item elevation={3}>
              <StatTitle variant="h6">Total Users</StatTitle>
              <StatValue variant="h4">
                <CountUp 
                  end={statistics.number_of_users} 
                  duration={3} 
                  separator="," 
                  easingFn={easeInOutCubic}
                />
              </StatValue>
              {statistics.users_added_last_24_hours > 0 && (
                <GreenText>
                  +{statistics.users_added_last_24_hours} Last 24h
                  <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                </GreenText>
              )}
              <UserTypeProgress>
                {Object.entries(statistics.users_by_plan).map(([type, count], index) => {
                  const percentage = (count / statistics.number_of_users) * 100;
                  const color = type === 'pro' ? '#535aff' : type === 'card_linked' ? '#00C853' : '#FFA726';
                  return (
                    <Box key={type} sx={{ mb: 1 }}>
                      <ProgressLabel>
                        <span>{type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
                        <span>{count.toLocaleString()} ({percentage.toFixed(1)}%)</span>
                      </ProgressLabel>
                      <LinearProgress
                        variant="determinate"
                        value={percentage}
                        sx={{
                          height: 8,
                          borderRadius: 4,
                          backgroundColor: `${color}22`,
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: color,
                            borderRadius: 4,
                          },
                        }}
                      />
                    </Box>
                  );
                })}
              </UserTypeProgress>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item elevation={3}>
              <StatTitle variant="h6">Total Amount Saved</StatTitle>
              <StatValue variant="h4">
                $<CountUp 
                  end={statistics.total_amount_saved} 
                  duration={3} 
                  separator="," 
                  decimals={2} 
                  easingFn={easeInOutCubic}
                />
              </StatValue>
              <SavingsBreakdown>
                <Box sx={{ mb: 2 }}>
                  <ProgressLabel>
                    <span>Vouchers</span>
                    <SavingsAmount>
                      ${statistics.total_saved_credits.toLocaleString()} ({((statistics.total_saved_credits / statistics.total_amount_saved) * 100).toFixed(1)}%)
                    </SavingsAmount>
                  </ProgressLabel>
                  <LinearProgress
                    variant="determinate"
                    value={(statistics.total_saved_credits / statistics.total_amount_saved) * 100}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: 'rgba(83, 90, 255, 0.12)',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#535aff',
                        borderRadius: 4,
                      },
                    }}
                  />
                </Box>
                <Box>
                  <ProgressLabel>
                    <span>Cash/Refunds</span>
                    <SavingsAmount>
                      ${(statistics.total_amount_saved - statistics.total_saved_credits).toLocaleString()} ({(((statistics.total_amount_saved - statistics.total_saved_credits) / statistics.total_amount_saved) * 100).toFixed(1)}%)
                    </SavingsAmount>
                  </ProgressLabel>
                  <LinearProgress
                    variant="determinate"
                    value={((statistics.total_amount_saved - statistics.total_saved_credits) / statistics.total_amount_saved) * 100}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: 'rgba(255, 167, 38, 0.12)',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#FFA726',
                        borderRadius: 4,
                      },
                    }}
                  />
                </Box>
              </SavingsBreakdown>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item elevation={3}>
              <StatTitle variant="h6">Total Reservations</StatTitle>
              <StatValue variant="h4">
                <CountUp 
                  end={statistics.total_number_of_reservations} 
                  duration={3} 
                  separator="," 
                  easingFn={easeInOutCubic}
                />
              </StatValue>
              {statistics.reservations_added_last_24_hours > 0 && (
                <GreenText>
                  +{statistics.reservations_added_last_24_hours} Last 24h
                  <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                </GreenText>
              )}
              <LFGContainer>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                  <Typography variant="subtitle2" sx={{ color: 'success.main', display: 'flex', alignItems: 'center' }}>
                    LFG Eligible
                    <PulsatingDot />
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'success.main' }}>
                    {statistics.lfg_eligible_reservations.total >= 1000 ? 
                      statistics.lfg_eligible_reservations.total.toLocaleString() : 
                      statistics.lfg_eligible_reservations.total}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Added in last 24h
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    +{statistics.lfg_eligible_reservations.last_24_hours >= 1000 ? 
                      statistics.lfg_eligible_reservations.last_24_hours.toLocaleString() : 
                      statistics.lfg_eligible_reservations.last_24_hours}
                  </Typography>
                </Box>
              </LFGContainer>
              <AwardContainer>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                  <Typography variant="subtitle2" sx={{ color: '#FFA726', display: 'flex', alignItems: 'center' }}>
                    Award Reservations
                    <EmojiEventsIcon sx={{ ml: 1, fontSize: '1rem' }} />
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: '#FFA726' }}>
                    {statistics.award_reservations.total >= 1000 ? 
                      statistics.award_reservations.total.toLocaleString() : 
                      statistics.award_reservations.total}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Pro User Awards
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {statistics.award_reservations.pro_users >= 1000 ? 
                      statistics.award_reservations.pro_users.toLocaleString() : 
                      statistics.award_reservations.pro_users}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Added in last 24h
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    +{statistics.award_reservations.last_24_hours >= 1000 ? 
                      statistics.award_reservations.last_24_hours.toLocaleString() : 
                      statistics.award_reservations.last_24_hours}
                  </Typography>
                </Box>
              </AwardContainer>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <StatusPaper elevation={3}>
              <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem' }}>System Status</Typography>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 2, 
                  color: allSystemsOperational ? 'success.main' : 'error.main',
                  fontWeight: 'bold',
                  fontSize: '0.8rem'
                }}
              >
                {allSystemsOperational ? 'All Systems Operational' : 'Problem with a System'}
              </Typography>
              
              <AirlineStatusContainer sx={{ mb: 0, flexWrap: 'wrap' }}>
                <AirlineStatusItem elevation={1} isOperational={statistics?.system_status?.aa_api}>
                  <AirlineLogo 
                    src={AIRLINE_LOGOS.american}
                    alt="American Airlines"
                  />
                  <StatusItemContent>
                    <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', lineHeight: 1.2 }}>American Airlines API</Typography>
                    {statistics?.system_status?.aa_api ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#00C853' }}>
                        <CheckCircleIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                        <Typography sx={{ fontSize: '0.7rem' }}>Operational</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#D50000' }}>
                        <CancelIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                        <Typography sx={{ fontSize: '0.7rem' }}>Down</Typography>
                      </Box>
                    )}
                  </StatusItemContent>
                </AirlineStatusItem>

                <AirlineStatusItem elevation={1} isOperational={statistics?.system_status?.united_api}>
                  <AirlineLogo 
                    src={AIRLINE_LOGOS.united}
                    alt="United Airlines"
                  />
                  <StatusItemContent>
                    <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', lineHeight: 1.2 }}>United Airlines API</Typography>
                    {statistics?.system_status?.united_api ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#00C853' }}>
                        <CheckCircleIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                        <Typography sx={{ fontSize: '0.7rem' }}>Operational</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#D50000' }}>
                        <CancelIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                        <Typography sx={{ fontSize: '0.7rem' }}>Down</Typography>
                      </Box>
                    )}
                  </StatusItemContent>
                </AirlineStatusItem>

                <AirlineStatusItem elevation={1} isOperational={statistics?.system_status?.delta_api}>
                  <AirlineLogo 
                    src={AIRLINE_LOGOS.delta}
                    alt="Delta Air Lines"
                  />
                  <StatusItemContent>
                    <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', lineHeight: 1.2 }}>Delta Air Lines API</Typography>
                    {statistics?.system_status?.delta_api ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#00C853' }}>
                        <CheckCircleIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                        <Typography sx={{ fontSize: '0.7rem' }}>Operational</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#D50000' }}>
                        <CancelIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                        <Typography sx={{ fontSize: '0.7rem' }}>Down</Typography>
                      </Box>
                    )}
                  </StatusItemContent>
                </AirlineStatusItem>

                {statistics?.system_status && Object.entries(statistics.system_status)
                  .filter(([key]) => !['aa_api', 'united_api', 'delta_api'].includes(key))
                  .map(([key, value]) => {
                    let icon;
                    switch(key) {
                      case 'autopilot_database':
                        icon = <StorageIcon />;
                        break;
                      case 'autopilot_frontend':
                        icon = <WebIcon />;
                        break;
                      case 'lowest_fare_guarantee':
                        icon = <LocalOfferIcon />;
                        break;
                      default:
                        icon = null;
                    }
                    
                    return (
                      <AirlineStatusItem key={key} elevation={1} isOperational={value}>
                        {icon && <SystemIcon>{icon}</SystemIcon>}
                        <StatusItemContent>
                          <Typography sx={{ fontSize: '0.75rem', lineHeight: 1.2 }}>
                            {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                          </Typography>
                          {value ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', color: '#00C853' }}>
                              <CheckCircleIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                              <Typography sx={{ fontSize: '0.7rem' }}>Operational</Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', color: '#D50000' }}>
                              <CancelIcon sx={{ mr: 0.5, fontSize: '0.9rem' }} />
                              <Typography sx={{ fontSize: '0.7rem' }}>Down</Typography>
                            </Box>
                          )}
                        </StatusItemContent>
                      </AirlineStatusItem>
                    );
                  })}
              </AirlineStatusContainer>
            </StatusPaper>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={3}>
              <ChartControls>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={showUsers} onChange={() => setShowUsers(!showUsers)} />}
                    label="Users"
                  />
                  <FormControlLabel
                    control={<Switch checked={showReservations} onChange={() => setShowReservations(!showReservations)} />}
                    label="Reservations"
                  />
                  <FormControlLabel
                    control={<Switch checked={showSavings} onChange={() => setShowSavings(!showSavings)} />}
                    label="Savings"
                  />
                </FormGroup>
                <ToggleButtonGroup
                  value={dataType}
                  exclusive
                  onChange={handleDataTypeChange}
                >
                  <ToggleButton value="total">Total</ToggleButton>
                  <ToggleButton value="new">New</ToggleButton>
                </ToggleButtonGroup>
              </ChartControls>
              {(showUsers || showReservations || showSavings) ? (
                <Chart
                  options={chartOptions}
                  series={chartSeries}
                  type="line"
                  height={350}
                />
              ) : (
                <Box sx={{ height: 350, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h6">Please select at least one data series to display</Typography>
                </Box>
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
