import React, { useState, useEffect } from 'react';
import { Box, useTheme, Accordion, AccordionSummary, AccordionDetails, Button, Snackbar, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighLevelInfo from './pages/HighLevelInfo';
import StatusIndicators from './pages/StatusIndicators';
import SpecificInformation from './pages/SpecificInformation';
import FlightsInfo from './pages/FlightsInfo';
import PricingInfo from './pages/PricingInfo';
import UpgradeOffers from './pages/UpgradeOffers';
import PassengerInfo from './pages/PassengerInfo';
import RecordRepricing from './pages/RecordRepricing';
import UnableToProcessModal from './pages/UnableToProcessModal';

const ReservationDisplay = ({ reservationData, totalReservations }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(totalReservations === 1);
  const [showRepricingModal, setShowRepricingModal] = useState(false);
  const [showUnableToProcessModal, setShowUnableToProcessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    console.log('ReservationDisplay mounted with data:', reservationData);
    // Reset expanded state when data changes
    setExpanded(totalReservations === 1);
  }, [reservationData, totalReservations]);

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    // Ensure the repricing modal is closed
    setShowRepricingModal(false);
  };

  const handleCloseSuccessMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMessage('');
  };

  if (!reservationData) {
    console.log('No reservation data provided to ReservationDisplay');
    return null;
  }

  return (
    <>
      <Accordion 
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ 
          mb: 2,
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '8px !important',
          backgroundColor: '#f8f9ff',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          overflow: 'hidden',
          '&:hover': {
            boxShadow: `0 0 15px ${theme.palette.primary.main}40`,
            borderColor: theme.palette.primary.main,
            transform: 'translateY(-2px)'
          },
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: 'unset',
            padding: 0,
            backgroundColor: 'white',
            borderBottom: expanded ? `1px solid ${theme.palette.divider}` : 'none'
          },
          '& .MuiAccordionSummary-content': {
            margin: 0
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            right: 16,
            color: theme.palette.primary.main
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <HighLevelInfo reservationData={reservationData} />
        </AccordionSummary>
        <AccordionDetails sx={{ px: 4, pt: 3, pb: 4 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={() => setShowRepricingModal(true)}
              sx={{
                mb: 2.5,
                transition: 'all 0.2s ease',
                textTransform: 'none',
                borderRadius: '6px',
                fontSize: '0.875rem',
              }}
            >
              Record Repricing
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowUnableToProcessModal(true)}
              sx={{
                mb: 2.5,
                transition: 'all 0.2s ease',
                textTransform: 'none',
                borderRadius: '6px',
                fontSize: '0.875rem',
                color: '#d32f2f',
                borderColor: '#d32f2f',
                '&:hover': {
                  borderColor: '#b71c1c',
                  bgcolor: 'rgba(211, 47, 47, 0.04)',
                },
              }}
            >
              Unable to Process
            </Button>
          </Box>
          <SpecificInformation reservationData={reservationData} />
          <StatusIndicators reservationData={reservationData} />
          <PassengerInfo reservationData={reservationData} />
          <FlightsInfo reservationData={reservationData} />
          <PricingInfo reservationData={reservationData} />
          <UpgradeOffers reservationData={reservationData} />
        </AccordionDetails>
      </Accordion>

      <RecordRepricing
        reservationData={reservationData}
        open={showRepricingModal}
        onClose={() => setShowRepricingModal(false)}
        onSuccess={handleSuccessMessage}
      />

      <UnableToProcessModal
        open={showUnableToProcessModal}
        onClose={() => setShowUnableToProcessModal(false)}
        reservationData={reservationData}
      />

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSuccessMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          position: 'fixed',
          bottom: '24px !important',
          left: '50% !important',
          transform: 'translateX(-50%)',
          '& .MuiAlert-root': {
            width: '100%',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
          }
        }}
      >
        <Alert 
          onClose={handleCloseSuccessMessage} 
          severity="success"
          variant="filled"
          sx={{ 
            width: '100%',
            fontSize: '1rem',
            fontWeight: 500,
            alignItems: 'center',
            '& .MuiAlert-icon': {
              fontSize: '1.5rem',
              marginRight: '12px'
            }
          }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ReservationDisplay; 