import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Box, Typography, Card, CardContent, CardHeader, 
  TextField, Button, Tabs, Tab, Avatar, Badge,
  Table, TableBody, TableCell, TableHead, TableRow,
  Tooltip, Select, MenuItem, InputLabel, FormControl,
  List, ListItem, ListItemText, Chip, Divider
} from '@mui/material';
import { 
  Person as UserIcon, 
  ContentCopy as CopyIcon,
  Search as SearchIcon,
  Payment as PaymentIcon,
  Share as ShareIcon,
  Flight as FlightIcon,
  CreditCard as CreditCardIcon,
  People as PeopleIcon
} from '@mui/icons-material';

function UserDetailPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState('info');
  const [userData, setUserData] = useState(null);
  const location = useLocation();

  // Only load from URL params on initial mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('user_id');
    if (userId) {
      setSearchQuery(userId);
      handleSearch(userId);
    }
  // Only run on mount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = async (overrideQuery = null) => {
    const queryToUse = overrideQuery || searchQuery;
    console.log(`Searching for: ${queryToUse}`);
    try {
      const response = await fetch('https://admin-api.withautopilot.com/admin_portal/get_user_detailed', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1")
        },
        body: JSON.stringify({ user_id: queryToUse })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const data = await response.json();
      setUserData(data);
    } catch (err) {
      console.error('Error fetching user data:', err);
      // Handle error state here
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const renderUserInfo = () => (
    <Card>
      <CardHeader title="User Information" />
      <CardContent>
        <Typography><strong>Name:</strong> {userData.first_name} {userData.last_name}</Typography>
        <Typography><strong>Email:</strong> {userData.email}</Typography>
        <Typography><strong>Created At:</strong> {new Date(userData.created_at).toLocaleString()}</Typography>
        <Typography><strong>Plan:</strong> {userData.plan}</Typography>
        <Typography><strong>Permissions:</strong> {userData.permissions}</Typography>
        <Typography><strong>Referral Code:</strong> {userData.referral_code}</Typography>
      </CardContent>
    </Card>
  );

  const renderPassengerInfo = () => (
    <Card>
      <CardHeader title="Passenger Information" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>KTN</TableCell>
              <TableCell>Loyalty Programs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.passenger_info.map((passenger) => (
              <TableRow key={passenger.passenger_id}>
                <TableCell>{`${passenger.first_name} ${passenger.last_name}`}</TableCell>
                <TableCell>{passenger.email}</TableCell>
                <TableCell>{passenger.phone}</TableCell>
                <TableCell>{passenger.date_of_birth || 'N/A'}</TableCell>
                <TableCell>{passenger.ktn || 'N/A'}</TableCell>
                <TableCell>
                  {passenger.loyalty_programs.map((program, index) => (
                    <Chip
                      key={index}
                      label={`${program.program_name}: ${program.loyalty_number}`}
                      size="small"
                      style={{ margin: '2px' }}
                    />
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );

  const renderBillingInfo = () => (
    <Card>
      <CardHeader title="Billing Information" />
      <CardContent>
        <Typography><strong>Stripe Customer ID:</strong> {userData.billing_info.stripe_customer_id}</Typography>
        <Typography variant="h6">Payment Methods:</Typography>
        <List>
          {userData.billing_info.payment_methods.map((method, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`${method.display_brand || 'Card'} ending in ${method.last4 || 'N/A'}`}
                secondary={`Expires: ${method.exp_month_year}`}
              />
            </ListItem>
          ))}
        </List>
        <Typography variant="h6">Recent Transactions:</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.billing_info.transactions.slice(0, 5).map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>${transaction.amount / 100}</TableCell>
                <TableCell>{transaction.description}</TableCell>
                <TableCell>{transaction.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );

  const renderReservations = () => (
    <Card>
      <CardHeader title="Reservations" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Airline</TableCell>
              <TableCell>PNR</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Departure</TableCell>
              <TableCell>Arrival</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Is Award</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.reservation_info.map((reservation) => (
              <TableRow key={reservation.reservation_id}>
                <TableCell>{reservation.airline}</TableCell>
                <TableCell>{reservation.pnr}</TableCell>
                <TableCell>{reservation.reservation_status}</TableCell>
                <TableCell>
                  {reservation.trips[0]?.departure_airport} - {new Date(reservation.trips[0]?.departure_datetime).toLocaleString()}
                </TableCell>
                <TableCell>
                  {reservation.trips[0]?.arrival_airport} - {new Date(reservation.trips[0]?.arrival_datetime).toLocaleString()}
                </TableCell>
                <TableCell>${reservation.payment_amount}</TableCell>
                <TableCell>{reservation.is_award ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );

  const renderVoucherCredits = () => (
    <Card>
      <CardHeader title="Voucher Credits" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Airline</TableCell>
              <TableCell>Expiration Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.voucher_credits.map((credit, index) => (
              <TableRow key={index}>
                <TableCell>${credit.amount}</TableCell>
                <TableCell>{credit.issuing_airline}</TableCell>
                <TableCell>{credit.expiration_date}</TableCell>
                <TableCell>{credit.is_used ? 'Used' : 'Available'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ p: 3, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5">User Management</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ mr: 2 }}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
              }}
            />
            <Button variant="contained" onClick={() => handleSearch()}>Search</Button>
          </Box>
        </CardContent>
      </Card>

      {userData && (
        <Box>
          <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="User Info" value="info" icon={<UserIcon />} />
            <Tab label="Passengers" value="passengers" icon={<PeopleIcon />} />
            <Tab label="Billing" value="billing" icon={<CreditCardIcon />} />
            <Tab label="Reservations" value="reservations" icon={<FlightIcon />} />
            <Tab label="Vouchers" value="vouchers" icon={<PaymentIcon />} />
          </Tabs>
          <Box mt={2}>
            {tabValue === 'info' && renderUserInfo()}
            {tabValue === 'passengers' && renderPassengerInfo()}
            {tabValue === 'billing' && renderBillingInfo()}
            {tabValue === 'reservations' && renderReservations()}
            {tabValue === 'vouchers' && renderVoucherCredits()}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default UserDetailPage;
