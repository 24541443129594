import React, { useState } from 'react';
import { 
  Paper, 
  Typography, 
  Box,
  IconButton,
  Collapse,
  Card,
  CardContent,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightIcon from '@mui/icons-material/Flight';
import EventIcon from '@mui/icons-material/Event';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';

const FlightSegment = ({ segment }) => {
  const theme = useTheme();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Format date and time separately for better readability
    const formattedDate = date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    const formattedTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    return (
      <>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {formattedTime}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {formattedDate}
        </Typography>
      </>
    );
  };

  const handleCopyFlightNumber = () => {
    navigator.clipboard.writeText(segment.flight_number);
  };

  const handleCopyFlightSummary = () => {
    const departureDate = new Date(segment.departure_datetime_local);
    const formattedDate = departureDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    });
    
    const summary = `${segment.flight_number} from ${segment.departure_airport} - ${segment.arrival_airport} on ${formattedDate} in ${segment.cabin_name}`;
    navigator.clipboard.writeText(summary);
  };

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 3,
        backgroundColor: '#f8f9ff',
        borderRadius: '12px',
        transition: 'all 0.3s ease',
        position: 'relative',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: `0 8px 24px ${theme.palette.primary.main}15`,
          '&::before': {
            opacity: 1
          }
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '12px',
          border: `2px solid ${theme.palette.primary.main}`,
          opacity: 0,
          transition: 'opacity 0.3s ease'
        }
      }}
    >
      {/* Flight Number and Segment ID Header */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 1,
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box 
              sx={{ 
                backgroundColor: `${theme.palette.primary.main}15`,
                p: 1,
                borderRadius: '50%',
                mr: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FlightIcon sx={{ color: theme.palette.primary.main }} />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Flight {segment.flight_number}
                </Typography>
                <IconButton 
                  size="small" 
                  onClick={handleCopyFlightNumber} 
                  sx={{ 
                    ml: 1,
                    color: theme.palette.primary.main,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: `${theme.palette.primary.main}15`
                    },
                    '&:active': {
                      transform: 'scale(0.95)',
                      backgroundColor: `${theme.palette.primary.main}25`
                    }
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
              <Typography variant="caption" color="text.secondary">
                Segment ID: {segment.segment_id}
              </Typography>
            </Box>
          </Box>
          <Tooltip title="Copy flight summary" placement="left">
            <IconButton
              size="small"
              onClick={handleCopyFlightSummary}
              sx={{
                color: theme.palette.primary.main,
                padding: '4px',
                transition: 'all 0.2s ease',
                '&:hover': {
                  bgcolor: `${theme.palette.primary.main}15`,
                },
                '&:active': {
                  transform: 'scale(0.95)',
                  bgcolor: `${theme.palette.primary.main}25`,
                }
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider sx={{ mt: 2 }} />
      </Box>

      <Grid container spacing={4}>
        {/* Departure and Arrival Info */}
        <Grid item xs={12} sm={12} md={4}>
          <Stack spacing={3}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ 
                mr: 2, 
                width: '36px',
                height: '36px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: 'white',
                border: `2px solid ${theme.palette.primary.main}20`,
                color: theme.palette.primary.main,
              }}>
                <FlightTakeoffIcon />
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">Departure</Typography>
                <Tooltip title={segment.departure_airport_name} arrow placement="top">
                  <Typography variant="subtitle1" sx={{ 
                    fontWeight: 500, 
                    mb: 0.5,
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}>
                    {segment.departure_airport}
                  </Typography>
                </Tooltip>
                {formatDate(segment.departure_datetime_local)}
              </Box>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Box sx={{ 
                mr: 2, 
                width: '36px',
                height: '36px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: 'white',
                border: `2px solid ${theme.palette.primary.main}20`,
                color: theme.palette.primary.main,
              }}>
                <FlightLandIcon />
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">Arrival</Typography>
                <Tooltip title={segment.arrival_airport_name} arrow placement="top">
                  <Typography variant="subtitle1" sx={{ 
                    fontWeight: 500, 
                    mb: 0.5,
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}>
                    {segment.arrival_airport}
                  </Typography>
                </Tooltip>
                {formatDate(segment.arrival_datetime_local)}
              </Box>
            </Box>
          </Stack>
        </Grid>

        {/* Flight Details Grid */}
        <Grid item xs={12} sm={12} md={8}>
          <Box sx={{ 
            backgroundColor: '#f8f9ff',
            borderRadius: '12px',
            p: { xs: 1, sm: 2 }
          }}>
            <Grid container spacing={2}>
              {/* Aircraft */}
              <Grid item xs={12} sm={4}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: { xs: 1.5, sm: 2.5 },
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0'
                  }}
                >
                  <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                    Aircraft
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {segment.aircraft}
                  </Typography>
                </Paper>
              </Grid>

              {/* Seats */}
              <Grid item xs={12} sm={8}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: { xs: 1.5, sm: 2.5 },
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0'
                  }}
                >
                  <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                    Seat Assignments
                  </Typography>
                  <Box component="ul" sx={{ m: 0, pl: 2, pt: 0.5 }}>
                    {Object.entries(segment.seat_assignments).map(([seat, details]) => {
                      console.log('Seat Assignment Details:', details);
                      return (
                        <Tooltip 
                          key={seat}
                          title={`Passenger: ${details.first_name} ${details.last_name} (ID: ${details.passenger_id})`}
                          arrow 
                          placement="top"
                          PopperProps={{
                            sx: {
                              '& .MuiTooltip-tooltip': {
                                bgcolor: theme.palette.primary.main,
                                color: 'white',
                                '& .MuiTooltip-arrow': {
                                  color: theme.palette.primary.main,
                                },
                              },
                            },
                          }}
                        >
                          <Typography 
                            component="li" 
                            variant="subtitle2" 
                            sx={{ 
                              fontWeight: 500,
                              cursor: 'help',
                              '&:hover': {
                                color: theme.palette.primary.main
                              }
                            }}
                          >
                            {details.first_name} {details.last_name} - {seat}
                          </Typography>
                        </Tooltip>
                      );
                    })}
                  </Box>
                </Paper>
              </Grid>

              {/* Cabin */}
              <Grid item xs={12} sm={4}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: { xs: 1.5, sm: 2.5 },
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0'
                  }}
                >
                  <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                    Cabin
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {segment.cabin_name}
                  </Typography>
                </Paper>
              </Grid>

              {/* Fare Details */}
              <Grid item xs={12} sm={8}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: { xs: 1.5, sm: 2.5 },
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0'
                  }}
                >
                  <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                    Fare Details
                  </Typography>
                  <Box sx={{ mt: 0.5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                      <Box>
                        <Typography variant="caption" color="text.secondary" display="block" sx={{ fontSize: '0.7rem' }}>
                          Fare Class
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          {segment.fare_class}
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box>
                        <Typography variant="caption" color="text.secondary" display="block" sx={{ fontSize: '0.7rem' }}>
                          Fare Code
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          {segment.fare_code}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const LayoverIndicator = ({ prevSegment, nextSegment }) => {
  const theme = useTheme();

  const calculateLayoverTime = (arrival, departure) => {
    const arrivalTime = new Date(arrival);
    const departureTime = new Date(departure);
    const diffInMinutes = Math.floor((departureTime - arrivalTime) / (1000 * 60));
    
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    
    if (hours === 0) {
      return `${minutes}m`;
    } else if (minutes === 0) {
      return `${hours}h`;
    }
    return `${hours}h ${minutes}m`;
  };

  const layoverTime = calculateLayoverTime(
    prevSegment.arrival_datetime_utc,
    nextSegment.departure_datetime_utc
  );

  return (
    <Box 
      sx={{ 
        my: 2,
        mx: 4,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.secondary
      }}
    >
      <Box sx={{ 
        flex: 1, 
        borderBottom: '2px dashed #e0e0e0',
        position: 'relative',
        my: 2
      }}>
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#f8f9ff',
          px: 2,
          py: 1,
          borderRadius: '16px',
          border: '1px solid #e0e0e0',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <AccessTimeIcon fontSize="small" />
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {layoverTime} layover in {prevSegment.arrival_airport}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const TripCard = ({ trip, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Format date and time separately for better readability
    const formattedDate = date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    const formattedTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    return (
      <>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {formattedTime}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {formattedDate}
        </Typography>
      </>
    );
  };

  const tripTitle = `${trip.departure_airport_name} to ${trip.arrival_airport_name}`;

  // Sort segments by departure datetime in ascending order
  const sortedSegments = [...trip.segments].sort((a, b) => {
    const aDate = new Date(a.departure_datetime_utc);
    const bDate = new Date(b.departure_datetime_utc);
    return aDate - bDate;
  });

  const segmentCount = sortedSegments.length;
  const segmentText = segmentCount === 1 ? '1 Segment' : `${segmentCount} Segments`;

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          <Tooltip title={tripTitle} arrow placement="top">
            <span style={{ cursor: 'pointer' }}>
              Trip {index + 1}: {trip.departure_airport} to {trip.arrival_airport}
            </span>
          </Tooltip>
          <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
            (Trip ID: {trip.trip_id})
          </Typography>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}>
              <FlightTakeoffIcon color="primary" sx={{ mr: 1 }} />
              <Tooltip title={trip.departure_airport_name} arrow placement="top">
                <Typography variant="subtitle1" sx={{ 
                  '&:hover': {
                    color: theme.palette.primary.main
                  }
                }}>
                  {trip.departure_airport}
                </Typography>
              </Tooltip>
            </Box>
            <Typography variant="body2" sx={{ 
              display: 'flex', 
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'flex-start' }
            }}>
              <EventIcon fontSize="small" sx={{ mr: 1 }} />
              <Box>{formatDate(trip.departure_datetime_local)}</Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Divider>
              <AirplaneTicketIcon color="action" />
            </Divider>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: { xs: 'center', sm: 'flex-end' }
            }}>
              <Tooltip title={trip.arrival_airport_name} arrow placement="top">
                <Typography variant="subtitle1" sx={{ 
                  '&:hover': {
                    color: theme.palette.primary.main
                  }
                }}>
                  {trip.arrival_airport}
                </Typography>
              </Tooltip>
              <FlightLandIcon color="primary" sx={{ ml: 1 }} />
            </Box>
            <Typography variant="body2" sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: { xs: 'center', sm: 'flex-end' }
            }}>
              <EventIcon fontSize="small" sx={{ mr: 1 }} />
              <Box>{formatDate(trip.arrival_datetime_local)}</Box>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <Accordion 
        expanded={isExpanded} 
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            minHeight: '48px',
            padding: '0 16px',
            backgroundColor: 'transparent',
            borderRadius: '8px',
            borderTop: '1px solid #e0e0e0',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&.Mui-expanded': {
              backgroundColor: 'transparent',
            }
          },
          '& .MuiAccordionSummary-content': {
            margin: '12px 0',
          },
          '& .MuiAccordionDetails-root': {
            padding: '24px 16px',
            backgroundColor: 'transparent',
            '& .MuiPaper-root': {
              backgroundColor: 'white',
              border: `1px solid ${theme.palette.primary.main}15`,
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: `${theme.palette.primary.main}30`,
                boxShadow: `0 4px 12px ${theme.palette.primary.main}10`,
              }
            }
          }
        }}
      >
        <AccordionSummary 
          expandIcon={
            <ExpandMoreIcon 
              sx={{ 
                color: theme.palette.primary.main,
                transition: 'transform 0.3s ease',
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }} 
            />
          }
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography 
              sx={{ 
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              Flight Details
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                borderLeft: '1px solid #e0e0e0',
                paddingLeft: 1,
                marginLeft: 0.5
              }}
            >
              {segmentText}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3}>
            {sortedSegments.map((segment, segmentIndex) => (
              <React.Fragment key={segment.segment_id}>
                <Box sx={{ 
                  '& .MuiGrid-item': {
                    '& .MuiPaper-root': {
                      backgroundColor: 'white',
                      border: `1px solid ${theme.palette.primary.main}15`,
                      borderRadius: '8px',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        borderColor: `${theme.palette.primary.main}30`,
                        boxShadow: `0 4px 12px ${theme.palette.primary.main}10`,
                      }
                    }
                  }
                }}>
                  <FlightSegment segment={segment} />
                </Box>
                {segmentIndex < sortedSegments.length - 1 && (
                  <LayoverIndicator 
                    prevSegment={segment}
                    nextSegment={sortedSegments[segmentIndex + 1]}
                  />
                )}
              </React.Fragment>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

const FlightsInfo = ({ reservationData }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Sort trips by departure datetime
  const trips = (reservationData.trips || []).sort((a, b) => {
    const aDate = new Date(a.departure_datetime_utc);
    const bDate = new Date(b.departure_datetime_utc);
    return aDate - bDate;
  });

  const tripCount = trips.length;
  const tripText = tripCount === 1 ? '1 Trip' : `${tripCount} Trips`;

  const handleCopyAllFlights = () => {
    const allFlightsSummary = trips.map(trip => {
      return trip.segments.map(segment => {
        const departureDate = new Date(segment.departure_datetime_local);
        const formattedDate = departureDate.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
        });
        return `${segment.flight_number} from ${segment.departure_airport} - ${segment.arrival_airport} on ${formattedDate} in ${segment.cabin_name}`;
      }).join('\n');
    }).join('\n');
    
    navigator.clipboard.writeText(allFlightsSummary);
  };

  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: { xs: 1, sm: 2 },
        border: '1px solid #e0e0e0',
        borderRadius: '12px',
        mt: 2
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 1, sm: 0 }
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1, 
            flex: 1,
            width: { xs: '100%', sm: 'auto' },
            justifyContent: { xs: 'center', sm: 'flex-start' }
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ConnectingAirportsIcon color="primary" />
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Flights Information
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              borderLeft: '1px solid #e0e0e0',
              paddingLeft: 1,
              marginLeft: 0.5
            }}
          >
            {tripText}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Tooltip title="Copy all flight summaries" placement="left">
            <IconButton
              size="small"
              onClick={handleCopyAllFlights}
              sx={{
                color: theme.palette.primary.main,
                padding: '4px',
                transition: 'all 0.2s ease',
                '&:hover': {
                  bgcolor: `${theme.palette.primary.main}15`,
                },
                '&:active': {
                  transform: 'scale(0.95)',
                  bgcolor: `${theme.palette.primary.main}25`,
                }
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <IconButton 
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              transition: 'all 0.2s ease',
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              '&:hover': {
                bgcolor: `${theme.palette.primary.main}15`,
              }
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </Box>

      <Collapse in={isExpanded}>
        <Box sx={{ mt: 2 }}>
          {trips.length > 0 ? (
            trips.map((trip, index) => (
              <TripCard key={trip.trip_id} trip={trip} index={index} />
            ))
          ) : (
            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', py: 4 }}>
              No flights found for this reservation
            </Typography>
          )}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default FlightsInfo; 