import React, { useState } from 'react';
import { Paper, Typography, Box, Stack, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio, IconButton, DialogContentText, Collapse, Chip, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import GroupIcon from '@mui/icons-material/Group';
import FlightIcon from '@mui/icons-material/Flight';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';

const statusDescriptions = {
  'Tracked': {
    description: 'Controls whether price data is pulled for this reservation. Note: This only affects price tracking - tickets may still be opened for lower fares based on other system settings.',
    icon: VisibilityIcon,
    category: 'Tracking'
  },
  'Auto Rebook': {
    description: 'System-level toggle that determines the notification type when a lower fare is found on tracked reservations. When enabled, opens a ticket. When disabled, sends a chasing email to the user.',
    icon: AutorenewIcon,
    category: 'Tracking'
  },
  'Active': {
    description: 'Indicates if this is an active reservation in the system.',
    icon: CheckCircleIcon,
    category: 'Status'
  },
  'Upcoming': {
    description: 'Indicates if the departure date is in the future.',
    icon: FlightTakeoffIcon,
    category: 'Status'
  },
  'Archived': {
    description: 'Controls whether the reservation appears in the user\'s upcoming or past reservations view. Affects visibility in the user interface only.',
    icon: ArchiveIcon,
    category: 'Status'
  },
  'Award Ticket': {
    description: 'When enabled, the system will pull and track award pricing data for this reservation.',
    icon: CardMembershipIcon,
    category: 'Booking'
  },
  'Basic Economy': {
    description: 'Indicates if this is a Basic Economy fare class.',
    icon: AirlineSeatReclineNormalIcon,
    category: 'Booking'
  },
  'Elite Upgrade': {
    description: 'Indicates if an elite status upgrade has been applied (confirmed or waitlisted). Check fare basis code for current status.',
    icon: StarIcon,
    category: 'Booking'
  },
  'Refundable': {
    description: 'Indicates if the ticket is refundable based on fare rules.',
    icon: AutorenewIcon,
    category: 'Booking'
  },
  'Direct Booking': {
    description: 'Indicates if the reservation was booked directly with the airline vs. through an OTA.',
    icon: FlightIcon,
    category: 'Booking'
  },
  'Companion Reservation': {
    description: 'Indicates if this is a companion ticket booking (e.g., Southwest Companion Pass, Alaska Airlines companion fare).',
    icon: GroupIcon,
    category: 'Booking'
  }
};

const StatusEditDialog = ({ open, onClose, status, currentValue, onSave }) => {
  const [isNone, setIsNone] = useState(currentValue === null);
  const [isChecked, setIsChecked] = useState(currentValue === true);
  const theme = useTheme();
  const StatusIcon = statusDescriptions[status]?.icon;

  const handleNoneToggle = (event) => {
    setIsNone(event.target.checked);
    if (event.target.checked) {
      setIsChecked(false);
    }
  };

  const handleCheckboxChange = (event) => {
    if (!isNone) {
      setIsChecked(event.target.checked);
    }
  };

  const handleSave = () => {
    onSave(isNone ? null : isChecked);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        borderBottom: '1px solid #e0e0e0',
        pb: 2
      }}>
        <StatusIcon sx={{ color: theme.palette.primary.main }} />
        <Typography variant="h6" component="span" sx={{ flex: 1 }}>
          {status}
        </Typography>
        <IconButton edge="end" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <DialogContentText sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {statusDescriptions[status]?.description}
        </DialogContentText>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 0.5
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            gap: 2
          }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  disabled={isNone}
                />
              }
              label={
                <Typography variant="body2" sx={{ color: isNone ? 'text.disabled' : 'text.primary' }}>
                  Enable {status === 'Tracked' ? 'Tracking' : status}
                </Typography>
              }
              sx={{
                opacity: isNone ? 0.5 : 1,
                transition: 'opacity 0.2s ease',
                m: 0
              }}
            />
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              borderLeft: '1px solid #e0e0e0',
              pl: 2
            }}>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'text.secondary',
                  fontSize: '0.75rem'
                }}
              >
                Set to None
              </Typography>
              <Switch
                size="small"
                checked={isNone}
                onChange={handleNoneToggle}
                color="primary"
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #e0e0e0', p: 2 }}>
        <Button onClick={onClose} sx={{ color: 'text.secondary' }}>
          Cancel
        </Button>
        <Button 
          onClick={handleSave}
          variant="contained"
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StatusChip = ({ label, active, icon: Icon, isNull, onEdit }) => {
  const theme = useTheme();
  
  const getStatusColor = () => {
    if (isNull) return { main: '#999', light: '#f5f5f5' };
    if (!active) return { main: '#666', light: '#f5f5f5' };
    
    // Custom colors for specific statuses
    switch (label) {
      case 'Active':
      case 'Upcoming':
        return { main: '#00a152', light: '#e6f4ea' };
      case 'Archived':
        return { main: '#666', light: '#f5f5f5' };
      case 'Award Ticket':
      case 'Elite Upgrade':
        return { main: '#1a73e8', light: '#e8f0fe' };
      case 'Basic Economy':
        return { main: '#e37400', light: '#fef7e0' };
      case 'Tracked':
      case 'Auto Rebook':
        return { main: theme.palette.primary.main, light: `${theme.palette.primary.main}15` };
      default:
        return { main: theme.palette.primary.main, light: `${theme.palette.primary.main}15` };
    }
  };

  const colors = getStatusColor();
  
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 0.5,
        py: 0.375,
        px: 0.875,
        borderRadius: '6px',
        backgroundColor: active ? colors.light : 'transparent',
        color: active ? colors.main : '#666',
        border: `1px solid ${active ? colors.main + '30' : '#e0e0e0'}`,
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        userSelect: 'none',
        height: 24,
        '&:hover': {
          backgroundColor: active ? colors.light : '#f8f9fa',
          transform: 'translateY(-1px)',
          boxShadow: active ? `0 2px 8px ${colors.main}20` : 'none',
          '& .edit-icon': {
            opacity: 1
          }
        }
      }}
      onClick={onEdit}
    >
      <Icon 
        sx={{ 
          fontSize: '0.875rem',
          opacity: active ? 1 : 0.5,
          transition: 'all 0.2s ease'
        }} 
      />
      <Typography 
        variant="body2" 
        sx={{ 
          fontWeight: active ? 600 : 400,
          opacity: active ? 1 : 0.7,
          letterSpacing: '0.01em',
          fontSize: '0.75rem',
          lineHeight: 1
        }}
      >
        {label}
      </Typography>
      <EditIcon 
        className="edit-icon"
        sx={{ 
          fontSize: '0.75rem', 
          ml: 0.25,
          opacity: 0,
          transition: 'opacity 0.2s ease'
        }} 
      />
    </Box>
  );
};

const StatusGroup = ({ title, items, variant = 'default', onEdit }) => (
  <Box sx={{ 
    flex: variant === 'tracking' ? '0 0 auto' : 1,
    minWidth: variant === 'tracking' ? {
      xs: '100%', // Full width on mobile
      sm: '240px'  // Original width on larger screens
    } : 'auto'
  }}>
    <Typography 
      variant="subtitle2" 
      sx={{ 
        color: '#666',
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.02em',
        mb: 0.75,
        fontFamily: 'Haffer Trial'
      }}
    >
      {title}
    </Typography>
    <Box 
      sx={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: '4px',
        ...(variant === 'tracking' && {
          backgroundColor: '#f8f9ff',
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          p: 0.75
        }),
        '& > *': {
          flex: '0 1 auto'
        }
      }}
    >
      {items.map((item, index) => (
        <StatusChip
          key={index}
          label={item.label}
          active={item.value}
          icon={item.icon}
          isNull={item.value === null}
          onEdit={() => onEdit(item.label, item.value)}
        />
      ))}
    </Box>
  </Box>
);

const AttributeChip = ({ label, disabled }) => {
  const theme = useTheme();
  
  return (
    <Chip
      label={label}
      size="small"
      sx={{
        height: 20,
        fontSize: '0.75rem',
        backgroundColor: disabled ? '#f5f5f5' : `${theme.palette.primary.main}10`,
        color: disabled ? '#666' : theme.palette.primary.main,
        fontWeight: 500,
        borderRadius: '4px',
        border: 'none',
        '& .MuiChip-label': {
          px: 1,
          py: 0
        }
      }}
    />
  );
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const StatusIndicators = ({ reservationData, onRefreshSuccess }) => {
  const theme = useTheme();
  const flags = reservationData.reservation_details.flags;
  const [isExpanded, setIsExpanded] = useState(false);
  const [editingStatus, setEditingStatus] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleStatusEdit = (status, currentValue) => {
    setEditingStatus({ status, currentValue });
  };

  const handleStatusSave = async (newValue) => {
    try {
      const token = getCookie('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      // Map the status label to the corresponding API field name
      const statusToFieldMap = {
        'Upcoming': 'is_upcoming',
        'Active': 'is_active',
        'Visible': 'is_visible',
        'Tracked': 'is_tracked',
        'Auto Rebook': 'is_auto_rebook_enabled',
        'Archived': 'is_archived',
        'Award Ticket': 'is_award',
        'Basic Economy': 'is_basic_economy',
        'Elite Upgrade': 'is_elite_upgrade',
        'Refundable': 'is_refundable',
        'Direct Booking': 'is_direct_booking',
        'Companion Reservation': 'is_companion_reservation'
      };

      const fieldName = statusToFieldMap[editingStatus.status];
      if (!fieldName) {
        throw new Error(`Unknown status: ${editingStatus.status}`);
      }

      // Create request body with exactly two fields: reservation_id and the tag
      const requestBody = {
        reservation_id: reservationData.reservation_id,
        [fieldName]: newValue
      };

      console.log('Sending request with body:', requestBody);

      const response = await fetch('https://admin-api.withautopilot.com/admin_portal/update_reservation_tags', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || data.message || 'Failed to update tag');
      }

      // Show success message
      setSnackbar({
        open: true,
        message: `Successfully updated ${editingStatus.status.toLowerCase()}`,
        severity: 'success'
      });

      // If we have a refresh callback, call it to update the UI
      if (onRefreshSuccess) {
        onRefreshSuccess();
      }
    } catch (error) {
      console.error('Error updating tag:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to update tag. Please try again.',
        severity: 'error'
      });
    } finally {
      setEditingStatus(null);
    }
  };

  const handleRefreshClick = (e) => {
    e.stopPropagation();
    setShowConfirmDialog(true);
  };

  const handleRefreshConfirm = async () => {
    setShowConfirmDialog(false);
    try {
      setIsRefreshing(true);
      const token = getCookie('token');
      
      if (!token) {
        setSnackbar({
          open: true,
          message: 'No authentication token found',
          severity: 'error'
        });
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
      myHeaders.append("Referer", "https://beluga.withautopilot.com/");
      myHeaders.append("User-Agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36");
      myHeaders.append("sec-ch-ua", "\"Google Chrome\";v=\"131\", \"Chromium\";v=\"131\", \"Not_A Brand\";v=\"24\"");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", token);
      myHeaders.append("sec-ch-ua-mobile", "?0");

      const raw = JSON.stringify({
        "user_id": reservationData.user_info.user_id,
        "reservation_id": reservationData.reservation_id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/refresh_pnr", requestOptions);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      console.log('Refresh successful:', result);
      
      if (result.response === "Complete") {
        setSnackbar({
          open: true,
          message: 'Reservation refreshed successfully',
          severity: 'success'
        });
        if (onRefreshSuccess) {
          onRefreshSuccess();
        }
      } else {
        // Show the actual response message if not "Complete"
        setSnackbar({
          open: true,
          message: result.response || 'Unexpected response from server',
          severity: 'warning'
        });
      }
      
    } catch (error) {
      console.error('Error refreshing reservation:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to refresh reservation',
        severity: 'error'
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  // Get active attributes for chips display
  const activeAttributes = [
    { label: 'Tracked', active: flags.is_tracked },
    { label: 'Auto Rebook', active: flags.is_auto_rebook_enabled },
    ...(flags.is_award ? ['Award Ticket'] : []),
    ...(flags.is_basic_economy ? ['Basic Economy'] : []),
    ...(flags.is_elite_upgrade ? ['Elite Upgrade'] : []),
    ...(flags.is_refundable ? ['Refundable'] : []),
    ...(flags.is_direct_booking ? ['Direct Booking'] : []),
    ...(flags.is_companion_reservation ? ['Companion Reservation'] : [])
  ];

  const statusGroups = [
    {
      title: "Tracking Features",
      variant: 'tracking',
      items: [
        {
          label: 'Tracked',
          value: flags.is_tracked,
          icon: VisibilityIcon
        },
        {
          label: 'Auto Rebook',
          value: flags.is_auto_rebook_enabled,
          icon: AutorenewIcon
        }
      ]
    },
    {
      title: "Reservation Status",
      items: [
        {
          label: 'Active',
          value: flags.is_active,
          icon: CheckCircleIcon
        },
        {
          label: 'Upcoming',
          value: flags.is_upcoming,
          icon: FlightTakeoffIcon
        },
        {
          label: 'Archived',
          value: flags.is_archived,
          icon: ArchiveIcon
        }
      ]
    },
    {
      title: "Booking Details",
      items: [
        {
          label: 'Award Ticket',
          value: flags.is_award,
          icon: CardMembershipIcon
        },
        {
          label: 'Basic Economy',
          value: flags.is_basic_economy,
          icon: AirlineSeatReclineNormalIcon
        },
        {
          label: 'Elite Upgrade',
          value: flags.is_elite_upgrade,
          icon: StarIcon
        },
        {
          label: 'Refundable',
          value: flags.is_refundable,
          icon: AutorenewIcon
        },
        {
          label: 'Direct Booking',
          value: flags.is_direct_booking,
          icon: FlightIcon
        },
        {
          label: 'Companion Reservation',
          value: flags.is_companion_reservation,
          icon: GroupIcon
        }
      ]
    }
  ];

  // Add handleSnackbarClose function
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <>
      <Paper 
        elevation={0}
        sx={{ 
          border: '1px solid #e0e0e0',
          borderRadius: '12px',
          mt: 2,
          overflow: 'hidden',
          backgroundColor: 'white'
        }}
      >
        <Box 
          sx={{ 
            p: { xs: 1.5, sm: 2 }, // Reduced padding on mobile
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 1.5, sm: 0 }, // Add gap for stacked layout on mobile
            justifyContent: 'space-between',
            cursor: 'pointer',
            backgroundColor: 'white',
            borderBottom: isExpanded ? '1px solid #e0e0e0' : 'none'
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
            alignItems: { xs: 'flex-start', sm: 'center' }, 
            gap: { xs: 1, sm: 2 },
            width: { xs: '100%', sm: 'auto' } // Full width on mobile
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SettingsIcon 
                sx={{ 
                  color: theme.palette.primary.main,
                  fontSize: '1.25rem'
                }} 
              />
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: 'text.primary',
                  fontSize: '0.875rem'
                }}
              >
                Reservation Attributes
              </Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              gap: 0.5, 
              flexWrap: 'wrap',
              width: { xs: '100%', sm: 'auto' } // Full width on mobile
            }}>
              {activeAttributes.map((attr, index) => {
                if (typeof attr === 'object') {
                  return (
                    <AttributeChip 
                      key={index} 
                      label={attr.label} 
                      disabled={!attr.active}
                    />
                  );
                }
                return <AttributeChip key={index} label={attr} />;
              })}
            </Box>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            width: { xs: '100%', sm: 'auto' }, // Full width on mobile
            justifyContent: { xs: 'space-between', sm: 'flex-end' } // Spread out on mobile
          }}>
            <Tooltip 
              title={!flags.is_upcoming ? "Cannot refresh data for past reservations" : ""}
              arrow
            >
              <span onClick={(e) => e.stopPropagation()}>
                <Button
                  variant="contained"
                  startIcon={
                    isRefreshing ? (
                      <CircularProgress
                        size={14}
                        thickness={4}
                        sx={{ color: 'white' }}
                      />
                    ) : (
                      <RefreshIcon sx={{ fontSize: '0.875rem' }} />
                    )
                  }
                  disabled={!flags.is_upcoming || isRefreshing}
                  onClick={handleRefreshClick}
                  size="small"
                  sx={{
                    backgroundColor: '#535aff',
                    fontFamily: 'Haffer Trial',
                    textTransform: 'none',
                    fontWeight: 500,
                    fontSize: '0.75rem',
                    py: 0.5,
                    px: 1.5,
                    minHeight: 0,
                    height: '24px',
                    lineHeight: 1,
                    boxShadow: 'none',
                    width: { xs: 'auto', sm: 'auto' }, // Auto width
                    '& .MuiButton-startIcon': {
                      mr: 0.5,
                      ml: -0.25,
                      '& .MuiCircularProgress-root': {
                        animation: 'animation-61bdi0 0.7s linear infinite'
                      }
                    },
                    '&:hover': {
                      backgroundColor: '#535aff',
                      boxShadow: 'none'
                    },
                    '&.Mui-disabled': {
                      backgroundColor: theme.palette.grey[200],
                      color: theme.palette.grey[500]
                    }
                  }}
                >
                  {isRefreshing ? 'Refreshing...' : 'Refresh Reservation'}
                </Button>
              </span>
            </Tooltip>
            <IconButton 
              size="small"
              sx={{
                transition: 'transform 0.2s ease',
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                color: theme.palette.text.secondary
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
        </Box>

        <Collapse in={isExpanded}>
          <Box sx={{ p: { xs: 1.5, sm: 2 } }}> {/* Reduced padding on mobile */}
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
              gap: { xs: 2, sm: 3 }, // Adjusted gap for mobile
              mb: 2 
            }}>
              <StatusGroup 
                title={statusGroups[0].title}
                items={statusGroups[0].items}
                variant="tracking"
                onEdit={handleStatusEdit}
              />
              <StatusGroup 
                title={statusGroups[1].title}
                items={statusGroups[1].items}
                onEdit={handleStatusEdit}
              />
            </Box>
            
            <StatusGroup 
              title={statusGroups[2].title}
              items={statusGroups[2].items}
              onEdit={handleStatusEdit}
            />
          </Box>
        </Collapse>

        {editingStatus && (
          <StatusEditDialog
            open={true}
            onClose={() => setEditingStatus(null)}
            status={editingStatus.status}
            currentValue={editingStatus.currentValue}
            onSave={handleStatusSave}
          />
        )}

        {/* Add Confirmation Dialog */}
        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          aria-labelledby="refresh-confirm-dialog"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogTitle id="refresh-confirm-dialog" sx={{ pb: 1 }}>
            Confirm Refresh
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to refresh this reservation? This will pull the latest data from the airline.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: 2, pt: 1 }}>
            <Button 
              onClick={() => setShowConfirmDialog(false)}
              sx={{ color: 'text.secondary' }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleRefreshConfirm}
              variant="contained"
              sx={{
                backgroundColor: '#535aff',
                '&:hover': {
                  backgroundColor: '#535aff',
                }
              }}
            >
              Refresh
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          position: 'fixed',
          bottom: '20px !important',
          left: '50% !important',
          transform: 'translateX(-50%)'
        }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StatusIndicators; 