import React, { useState, useEffect, useCallback } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  CircularProgress, 
  Alert, 
  FormControl,
  InputLabel, 
  Select, 
  OutlinedInput, 
  MenuItem, 
  Checkbox, 
  ListItemText,
  IconButton,
  Popover,
  Menu
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import ReservationDisplay from './ReservationDisplay/index';
import { getToken } from '../utils/auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Haffer Trial',
    allVariants: {
      fontFamily: 'Haffer Trial',
    },
    h4: {
      fontWeight: 600,
      color: '#282c34',
    },
    button: {
      fontFamily: 'Haffer Trial',
      textTransform: 'none',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#535aff',
          '&:hover': {
            backgroundColor: '#4247cc',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            fontFamily: 'Haffer Trial',
          },
          '& input': {
            fontFamily: 'Haffer Trial',
          },
          '& label.Mui-focused': {
            color: '#535aff',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#535aff',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            fontFamily: 'Haffer Trial',
          },
          '& .MuiSelect-select': {
            fontFamily: 'Haffer Trial',
          },
          '& label.Mui-focused': {
            color: '#535aff',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#535aff',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#535aff',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Haffer Trial',
        },
      },
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const filterOptions = [
  { value: 'is_active', label: 'Active' },
  { value: 'is_archived', label: 'Archived' },
  { value: 'is_auto_rebook_enabled', label: 'Auto Rebook Enabled' },
  { value: 'is_award', label: 'Award' },
  { value: 'is_basic_economy', label: 'Basic Economy' },
  { value: 'is_companion_reservation', label: 'Companion Reservation' },
  { value: 'is_direct_booking', label: 'Direct Booking' },
  { value: 'is_elite_upgrade', label: 'Elite Upgrade' },
  { value: 'is_refundable', label: 'Refundable' },
  { value: 'is_tracked', label: 'Tracked' },
  { value: 'is_upcoming', label: 'Upcoming' },
  { value: 'is_visible', label: 'Visible' }
];

const sortOptions = [
  { value: 'default', label: 'Default (Upcoming then Past)' },
  { value: 'upcoming_first', label: 'Upcoming First' },
  { value: 'previous_first', label: 'Previous First' },
  { value: 'import_date_desc', label: 'Import Date (Recent to Old)' },
  { value: 'import_date_asc', label: 'Import Date (Old to Recent)' },
  { value: 'refreshed_date_desc', label: 'Last Refreshed (Recent to Old)' },
  { value: 'refreshed_date_asc', label: 'Last Refreshed (Old to Recent)' },
];

function ReservationLookup() {
  const [searchCriteria, setSearchCriteria] = useState({
    identifier: '',
    user_id: '',
  });
  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState('upcoming_first');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  const [filteredResults, setFilteredResults] = useState(null);
  const [noReservationsFound, setNoReservationsFound] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const initialSearchDone = React.useRef(false);
  const isFirstMount = React.useRef(true);

  const location = useLocation();
  const navigate = useNavigate();

  // Reset initialSearchDone when component unmounts
  useEffect(() => {
    return () => {
      initialSearchDone.current = false;
    };
  }, []);

  // Single effect to handle all search scenarios
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      const queryParams = new URLSearchParams(location.search);
      const userIdFromQuery = queryParams.get('user_id');
      const pnrFromQuery = queryParams.get('pnr');
      const reservationIdFromQuery = queryParams.get('reservation_id');

      // Only proceed if we haven't done the initial search and we have query params
      if (!initialSearchDone.current && (userIdFromQuery || pnrFromQuery || reservationIdFromQuery)) {
        const newSearchCriteria = { ...searchCriteria };
        
        if (userIdFromQuery) {
          newSearchCriteria.user_id = userIdFromQuery;
        }
        if (pnrFromQuery || reservationIdFromQuery) {
          newSearchCriteria.identifier = pnrFromQuery || reservationIdFromQuery;
        }
        
        setSearchCriteria(newSearchCriteria);
        handleSubmit(null, userIdFromQuery);
        initialSearchDone.current = true;
      }
    }
  }, [location.search]);

  const updateUrlWithSearchParams = useCallback(() => {
    const params = new URLSearchParams();
    
    if (searchCriteria.user_id) {
      params.set('user_id', searchCriteria.user_id);
    }
    
    if (searchCriteria.identifier) {
      // Determine if it's a PNR or reservation_id based on format
      if (/^\d+$/.test(searchCriteria.identifier)) {
        params.set('reservation_id', searchCriteria.identifier);
      } else {
        params.set('pnr', searchCriteria.identifier.toUpperCase());
      }
    }
    
    const newUrl = `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
    navigate(newUrl, { replace: true });
  }, [searchCriteria, location.pathname, navigate]);

  const handleSubmit = useCallback(async (e, userIdOverride = null) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResults(null);
    setFilteredResults(null);
    setNoReservationsFound(false);

    const payload = {};
    
    // Smart detection for PNR or Reservation ID
    if (searchCriteria.identifier) {
      // If it contains only numbers, treat as reservation_id
      if (/^\d+$/.test(searchCriteria.identifier)) {
        payload.reservation_id = searchCriteria.identifier;
      } else {
        // If it contains letters and numbers, treat as PNR
        payload.pnr = searchCriteria.identifier.toUpperCase();
      }
    }

    if (searchCriteria.user_id) {
      payload.user_id = searchCriteria.user_id;
    }

    if (userIdOverride) {
      payload.user_id = userIdOverride;
    }

    if (Object.values(payload).every(x => x === '') && filters.length === 0) {
      setError("Please enter a PNR, Reservation ID, User ID, or select filters");
      setIsLoading(false);
      return;
    }

    // Only update URL if this is not from a query parameter search (i.e., if there's an event)
    if (e) {
      updateUrlWithSearchParams();
    }

    // Add filters to payload
    filters.forEach(filter => {
      payload[filter] = true;
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/get_reservation", requestOptions);
      if (response.status === 404) {
        setNoReservationsFound(true);
        setResults([]);
        setFilteredResults([]);
      } else if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const result = await response.json();
        console.log("API Response Result:", result);
        
        const reservationsArray = Array.isArray(result) ? result : [result];
        console.log("Processed reservationsArray:", reservationsArray);
        setResults(reservationsArray);
        setFilteredResults(reservationsArray);
      }
    } catch (e) {
      console.error("There was a problem with the fetch operation:", e);
      setError("An error occurred while fetching the data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [searchCriteria, filters, updateUrlWithSearchParams]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilters(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    if (results) {
      console.log("Results in filter effect:", results);
      const filtered = results.filter(reservation => 
        filters.every(filter => reservation[filter] === true)
      );
      console.log("Filtered results:", filtered);
      setFilteredResults(filtered);
    }
  }, [results, filters]);

  const handleSortChange = (event) => {
    const { value } = event.target;
    setSortBy(value);
    if (filteredResults) {
      const sorted = [...filteredResults].sort((a, b) => {
        // Get departure dates from first segment of each trip
        const aDate = new Date(a.trips[0].departure_datetime_utc);
        const bDate = new Date(b.trips[0].departure_datetime_utc);
        
        switch (value) {
          case 'default':
            // First separate upcoming from past flights
            const aIsUpcomingDefault = a.reservation_details.flags.is_upcoming;
            const bIsUpcomingDefault = b.reservation_details.flags.is_upcoming;
            
            // If they're in different groups (upcoming vs past)
            if (aIsUpcomingDefault !== bIsUpcomingDefault) {
              return aIsUpcomingDefault ? -1 : 1; // Upcoming flights first
            }
            
            // If they're both upcoming, sort by earliest first
            if (aIsUpcomingDefault) {
              return aDate - bDate;
            }
            // If they're both past, sort by most recent first
            return bDate - aDate;
            
          case 'upcoming_first':
            const aIsUpcoming = a.reservation_details.flags.is_upcoming;
            const bIsUpcoming = b.reservation_details.flags.is_upcoming;
            
            if (aIsUpcoming !== bIsUpcoming) {
              return aIsUpcoming ? -1 : 1;
            }
            
            if (aIsUpcoming) {
              return aDate - bDate;
            }
            return bDate - aDate;
            
          case 'previous_first':
            const aIsPrevious = !a.reservation_details.flags.is_upcoming;
            const bIsPrevious = !b.reservation_details.flags.is_upcoming;
            
            if (aIsPrevious !== bIsPrevious) {
              return aIsPrevious ? -1 : 1;
            }
            
            if (aIsPrevious) {
              return bDate - aDate;
            }
            return aDate - bDate;

          case 'import_date_desc':
            return new Date(b.reservation_details.date_added) - new Date(a.reservation_details.date_added);
          case 'import_date_asc':
            return new Date(a.reservation_details.date_added) - new Date(b.reservation_details.date_added);
          case 'refreshed_date_desc':
            return new Date(b.reservation_details.date_updated) - new Date(a.reservation_details.date_updated);
          case 'refreshed_date_asc':
            return new Date(a.reservation_details.date_updated) - new Date(b.reservation_details.date_updated);
          default:
            return 0;
        }
      });
      setFilteredResults(sorted);
    }
  };

  // Add an effect to apply default sorting when results first load
  useEffect(() => {
    if (results && results.length > 0) {
      // Apply default sorting when results load
      handleSortChange({ target: { value: 'default' } });
    }
  }, [results]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        padding: 3, 
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'left', mb: 4 }}>
          Reservation Lookup
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            gap: 2, 
            marginBottom: 2, 
            alignItems: { xs: 'stretch', md: 'flex-start' },
            '& .MuiTextField-root': { 
              flex: 1,
              maxWidth: { xs: '100%', md: '350px' }
            },
            '& .MuiFormControl-root': { 
              flex: 1,
              maxWidth: { xs: '100%', md: '350px' }
            }
          }}>
            <TextField
              label="PNR or Reservation ID"
              name="identifier"
              value={searchCriteria.identifier}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              placeholder="Enter PNR or Reservation ID"
            />
            <TextField
              label="User ID"
              name="user_id"
              value={searchCriteria.user_id}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
            <Button 
              type="submit" 
              variant="contained" 
              disabled={isLoading}
              sx={{ 
                minWidth: 100,
                height: 40,
                alignSelf: { xs: 'stretch', md: 'flex-start' }
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Search"}
            </Button>
          </Box>
        </form>

        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        {noReservationsFound && <Alert severity="info" sx={{ mt: 2 }}>No reservations found.</Alert>}
        
        {filteredResults && filteredResults.length > 1 && (
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
            mb: 1,
            gap: 0.5
          }}>
            <IconButton 
              size="small"
              onClick={handleFilterClick}
              sx={{ 
                bgcolor: filters.length > 0 ? 'action.selected' : 'transparent',
                '&:hover': { bgcolor: 'action.hover' }
              }}
            >
              <FilterListIcon />
            </IconButton>
            <IconButton 
              size="small"
              onClick={handleSortClick}
              sx={{ '&:hover': { bgcolor: 'action.hover' } }}
            >
              <SortIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={filterAnchorEl}
              open={Boolean(filterAnchorEl)}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {filterOptions.map((option, index) => (
                <div key={option.value}>
                  <MenuItem 
                    onClick={() => {
                      const newFilters = filters.includes(option.value)
                        ? filters.filter(f => f !== option.value)
                        : [...filters, option.value];
                      setFilters(newFilters);
                    }}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Checkbox 
                      checked={filters.includes(option.value)}
                      size="small"
                      sx={{ p: 0 }}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                  {index < filterOptions.length - 1 && (
                    <Box
                      sx={{
                        height: '1px',
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        mx: 2
                      }}
                    />
                  )}
                </div>
              ))}
            </Menu>

            <Menu
              anchorEl={sortAnchorEl}
              open={Boolean(sortAnchorEl)}
              onClose={handleSortClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {sortOptions.map((option, index) => (
                <div key={option.value}>
                  <MenuItem 
                    onClick={() => {
                      handleSortChange({ target: { value: option.value } });
                      handleSortClose();
                    }}
                    selected={sortBy === option.value}
                  >
                    {option.label}
                  </MenuItem>
                  {index < sortOptions.length - 1 && (
                    <Box
                      sx={{
                        height: '1px',
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        mx: 2
                      }}
                    />
                  )}
                </div>
              ))}
            </Menu>
          </Box>
        )}

        <Box sx={{ mt: 2 }}>
          {filteredResults && filteredResults.length > 0 && filteredResults.map((reservation, index) => (
            <ReservationDisplay 
              key={index} 
              reservationData={reservation} 
              totalReservations={filteredResults.length}
            />
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ReservationLookup;
