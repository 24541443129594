import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  IconButton, 
  Collapse,
  Grid,
  Stack,
  Tooltip,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CakeIcon from '@mui/icons-material/Cake';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getLoyaltyStyle, LOYALTY_TIER_MAP, AIRLINE_LOGOS } from '../utils';

const LoyaltyProgramBadge = ({ program }) => {
  const theme = useTheme();
  const style = getLoyaltyStyle(program);
  
  const getDisplayStatus = (program) => {
    const programCode = program.program_name.toUpperCase();
    const status = program.airline_status?.toUpperCase();
    
    if (!status || !LOYALTY_TIER_MAP[programCode]) {
      return LOYALTY_TIER_MAP[programCode]?.DEFAULT || 'Member';
    }

    // Find the matching tier by checking each tier's value
    for (const [tier, displayName] of Object.entries(LOYALTY_TIER_MAP[programCode])) {
      if (status === tier || status === displayName.toUpperCase()) {
        return displayName;
      }
    }

    return LOYALTY_TIER_MAP[programCode].DEFAULT || 'Member';
  };

  const getAirlineLogo = (programCode) => {
    const code = programCode.toLowerCase();
    if (code === 'aa') return AIRLINE_LOGOS.american;
    if (code === 'ua') return AIRLINE_LOGOS.united;
    if (code === 'dl') return AIRLINE_LOGOS.delta;
    return null;
  };
  
  return (
    <Box 
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
        py: 0.75,
        px: 1,
        borderRadius: '6px',
        backgroundColor: style.bg,
        color: style.color,
        border: '1px solid',
        borderColor: `${style.color}40`,
        width: '100%',
        fontSize: '0.875rem'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, minWidth: 0, flex: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {getAirlineLogo(program.program_name) && (
            <Box
              component="img"
              src={getAirlineLogo(program.program_name)}
              alt={`${program.program_name} logo`}
              sx={{
                width: 16,
                height: 16,
                objectFit: 'contain',
                borderRadius: '3px',
                padding: '1px',
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: `${style.color}20`
              }}
            />
          )}
          <CardMembershipIcon sx={{ fontSize: '1rem' }} />
        </Box>
        <Box sx={{ minWidth: 0, display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
          <Typography 
            variant="caption" 
            sx={{ 
              opacity: 0.8,
              whiteSpace: 'nowrap'
            }}
          >
            {program.program_name}:
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              fontWeight: 600,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {program.loyalty_number}
          </Typography>
          <Tooltip title="Copy Number">
            <IconButton 
              size="small" 
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(program.loyalty_number);
              }}
              sx={{ 
                color: 'inherit',
                opacity: 0.7,
                '&:hover': { opacity: 1 },
                padding: 0.25,
                ml: -0.5
              }}
            >
              <ContentCopyIcon sx={{ fontSize: '0.875rem' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Typography 
        variant="body2" 
        sx={{ 
          fontWeight: 600,
          whiteSpace: 'nowrap',
          opacity: 0.9,
          fontSize: '0.75rem'
        }}
      >
        {getDisplayStatus(program)}
      </Typography>
      
      <Typography 
        variant="caption" 
        sx={{ 
          whiteSpace: 'nowrap',
          opacity: 0.8
        }}
      >
        ID: {program.loyalty_id}
      </Typography>
    </Box>
  );
};

const CopyableField = ({ icon: Icon, label, value, onCopy }) => {
  const theme = useTheme();
  
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        cursor: 'pointer',
        py: 0.5,
        px: 0.75,
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main}08`
        },
        minHeight: 32
      }}
      onClick={() => onCopy(value)}
    >
      <Icon sx={{ color: theme.palette.primary.main, fontSize: '1.125rem' }} />
      <Typography variant="body2" sx={{ flex: 1 }}>{value}</Typography>
      <Tooltip title={`Copy ${label}`}>
        <IconButton 
          size="small"
          sx={{ 
            color: theme.palette.primary.main,
            opacity: 0.7,
            '&:hover': { opacity: 1 },
            padding: 0.5
          }}
        >
          <ContentCopyIcon sx={{ fontSize: '0.875rem' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const PassengerCard = ({ passenger, currentAirline }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [copySnackbar, setCopySnackbar] = useState({ open: false, message: '' });

  const formatPhoneNumber = (phone) => {
    if (!phone) return '';
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phone;
  };

  const handleCopy = (value, fieldName) => {
    navigator.clipboard.writeText(value);
    setCopySnackbar({ 
      open: true, 
      message: `${fieldName} copied to clipboard` 
    });
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const handleNavigateToPassenger = () => {
    navigate(`/passenger?passenger_id=${passenger.passenger_id}`);
  };

  const getAllPassengerInfo = () => {
    const parts = [];
    
    // Add name
    parts.push(`${passenger.first_name} ${passenger.last_name}`);
    
    // Add DOB if exists
    if (passenger.dob) {
      parts.push(`DOB: ${passenger.dob}`);
    }
    
    // Add email if exists
    if (passenger.email) {
      parts.push(`Email: ${passenger.email}`);
    }
    
    // Add phone if exists
    if (passenger.phone) {
      parts.push(`Phone: ${formatPhoneNumber(passenger.phone)}`);
    }
    
    return parts.join(', ');
  };

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: { xs: 1.5, sm: 2.5 },
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: '12px',
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.06)',
          borderColor: 'rgba(0, 0, 0, 0.12)'
        }
      }}
    >
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'flex-start', 
        mb: 2,
        flexWrap: { xs: 'wrap', sm: 'nowrap' }
      }}>
        <Box 
          sx={{ 
            width: { xs: 40, sm: 48 },
            height: { xs: 40, sm: 48 },
            borderRadius: '50%',
            background: `linear-gradient(135deg, ${theme.palette.primary.main}15, ${theme.palette.primary.main}25)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 2,
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: { xs: '16px', sm: '18px' },
            flexShrink: 0
          }}
        >
          {getInitials(passenger.first_name, passenger.last_name)}
        </Box>
        <Box sx={{ flex: 1, minWidth: 0 }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.5, 
            mb: 0.5,
            flexWrap: { xs: 'wrap', sm: 'nowrap' }
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600, 
                lineHeight: 1.2, 
                fontSize: { xs: '1rem', sm: '1.125rem' },
                mr: 'auto'
              }}
            >
              {passenger.first_name} {passenger.last_name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Tooltip title="Copy Name">
                <IconButton 
                  size="small" 
                  onClick={() => handleCopy(`${passenger.first_name} ${passenger.last_name}`, 'Name')}
                  sx={{ 
                    padding: 0.5,
                    color: theme.palette.primary.main,
                    opacity: 0.7,
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: '0.875rem' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography 
              variant="caption" 
              sx={{ 
                color: 'text.secondary',
                fontSize: '0.75rem'
              }}
            >
              ID: {passenger.passenger_id}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 0.5, 
          ml: { xs: 'auto', sm: 1 },
          mt: { xs: 1, sm: 0 }
        }}>
          <Tooltip title="Copy All Info">
            <IconButton
              size="small"
              onClick={() => handleCopy(getAllPassengerInfo(), 'Passenger information')}
              sx={{ 
                color: 'text.secondary',
                opacity: 0.7,
                '&:hover': { 
                  opacity: 1,
                  backgroundColor: `${theme.palette.primary.main}15`
                }
              }}
            >
              <ContentCopyIcon sx={{ fontSize: '1.125rem' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Passenger Details">
            <IconButton
              size="small"
              onClick={handleNavigateToPassenger}
              sx={{ 
                color: 'text.secondary',
                opacity: 0.7,
                '&:hover': { 
                  opacity: 1,
                  backgroundColor: `${theme.palette.primary.main}15`
                }
              }}
            >
              <ArrowForwardIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Contact Information */}
      <Stack spacing={1} sx={{ mb: passenger.loyalty_programs?.length > 0 ? 3 : 0 }}>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '0.75rem', mb: 0.5, textTransform: 'uppercase', letterSpacing: '0.5px' }}>
          Contact Information
        </Typography>
        <CopyableField 
          icon={EmailIcon}
          label="Email"
          value={passenger.email}
          onCopy={(value) => handleCopy(value, 'Email')}
        />
        <CopyableField 
          icon={PhoneIcon}
          label="Phone"
          value={formatPhoneNumber(passenger.phone)}
          onCopy={(value) => handleCopy(passenger.phone, 'Phone number')}
        />
        {passenger.dob && (
          <CopyableField 
            icon={CakeIcon}
            label="Date of Birth"
            value={passenger.dob}
            onCopy={(value) => handleCopy(value, 'Date of birth')}
          />
        )}
      </Stack>

      {/* Loyalty Programs */}
      {passenger.loyalty_programs && passenger.loyalty_programs.length > 0 && (
        <Box>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontSize: '0.75rem', mb: 1, textTransform: 'uppercase', letterSpacing: '0.5px' }}>
            Loyalty Programs
          </Typography>
          <Stack spacing={1}>
            {passenger.loyalty_programs
              .filter(program => {
                const programCode = program.program_name.toLowerCase();
                const airline = currentAirline.toLowerCase();
                return (
                  (airline === 'american' && programCode === 'aa') ||
                  (airline === 'delta' && programCode === 'dl') ||
                  (airline === 'united' && programCode === 'ua')
                );
              })
              .map((program, index) => (
                <LoyaltyProgramBadge key={index} program={program} />
              ))}
          </Stack>
        </Box>
      )}

      {/* Copy Snackbar */}
      <Snackbar
        open={copySnackbar.open}
        autoHideDuration={2000}
        onClose={() => setCopySnackbar({ ...copySnackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setCopySnackbar({ ...copySnackbar, open: false })} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {copySnackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

const PassengerInfo = ({ reservationData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const passengers = reservationData?.passengers || [];
  const passengerCount = passengers.length;
  const passengerText = `${passengerCount} ${passengerCount === 1 ? 'Passenger' : 'Passengers'}`;
  const currentAirline = reservationData?.reservation_details?.airline || '';

  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: { xs: 1.5, sm: 2 },
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: '12px',
        mt: 2
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
          gap: { xs: 1, sm: 0 },
          '&:hover': {
            '& .MuiIconButton-root': {
              backgroundColor: `${theme.palette.primary.main}15`
            }
          }
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
          width: { xs: '100%', sm: 'auto' }
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PersonIcon color="primary" />
            <Typography variant="subtitle1" sx={{ 
              fontWeight: 600,
              fontSize: { xs: '0.9rem', sm: '1rem' }
            }}>
              Passenger Information
            </Typography>
          </Box>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              borderLeft: { xs: 'none', sm: '1px solid #e0e0e0' },
              paddingLeft: { xs: 0, sm: 1 },
              marginLeft: { xs: 0, sm: 0.5 }
            }}
          >
            {passengerText}
          </Typography>
        </Box>
        <IconButton 
          size="small"
          sx={{
            transition: 'all 0.2s ease',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            ml: { xs: 'auto', sm: 0 }
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={{ xs: 1.5, sm: 2 }}>
            {passengers.map((passenger, index) => (
              <Grid item xs={12} md={6} key={passenger.passenger_id}>
                <PassengerCard passenger={passenger} currentAirline={currentAirline} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default PassengerInfo; 