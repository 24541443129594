import React, { useState, useMemo, useCallback } from 'react';
import { 
  Paper, 
  Typography, 
  Box,
  IconButton,
  Collapse,
  Stack,
  Divider,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Checkbox,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaidIcon from '@mui/icons-material/Paid';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TimelineIcon from '@mui/icons-material/Timeline';
import RefreshIcon from '@mui/icons-material/Refresh';
import TableChartIcon from '@mui/icons-material/TableChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LineChart, Line, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer, Area, ReferenceLine } from 'recharts';
import SavingsAndCredits from './SavingsAndCredits';
import { AIRLINE_LOGOS } from '../utils';

const PriceHistoryItem = ({ price, timestamp, isAward, awardCurrency }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const formatPrice = (price) => {
    if (!price) return '-';
    if (isAward) {
      return `${parseFloat(price).toLocaleString()} ${awardCurrency}`;
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Typography variant="body2" color="text.secondary" sx={{ minWidth: '180px' }}>
        {formatDate(timestamp)}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {formatPrice(price)}
      </Typography>
    </Box>
  );
};

const PriceSummaryCard = ({ amount, title, subtitle, onRefresh, isUpcoming, isRefreshing, searchLink, airline, currencySymbol }) => {
  const theme = useTheme();
  
  const refreshButton = onRefresh && (
    <Tooltip title={!isUpcoming ? "Cannot refresh price for past reservations" : ""}>
      <span>
        <IconButton 
          size="small" 
          onClick={onRefresh}
          disabled={!isUpcoming || isRefreshing}
          sx={{ 
            color: theme.palette.primary.main,
            padding: { xs: '6px', sm: '8px' },
            width: { xs: '32px', sm: '36px' },
            height: { xs: '32px', sm: '36px' },
            '&:hover': {
              backgroundColor: isUpcoming ? `${theme.palette.primary.main}15` : 'inherit'
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)'
            },
            animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
          }}
        >
          <RefreshIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} />
        </IconButton>
      </span>
    </Tooltip>
  );

  const formatAirlineName = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };
  
  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: { xs: 1.25, sm: 1.5, md: 2 },
        minWidth: { xs: '100%', sm: '240px' },
        flex: { xs: '1 1 100%', sm: '1 1 calc(50% - 8px)', md: '1 1 0' },
        backgroundColor: '#f8f9ff',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 0.25 }}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 600,
            fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.5rem' },
            lineHeight: 1,
            wordBreak: 'break-word',
            color: theme.palette.text.primary,
            mb: 1
          }}
        >
          {amount}
        </Typography>
        {refreshButton}
      </Box>
      <Typography 
        variant="body2" 
        color="text.secondary" 
        sx={{ 
          fontSize: { xs: '0.65rem', sm: '0.7rem' },
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          lineHeight: 1,
          mb: 0.25
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography 
          variant="caption" 
          color="text.secondary" 
          display="block"
          sx={{
            fontSize: { xs: '0.6rem', sm: '0.65rem' },
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            lineHeight: 1
          }}
        >
          {subtitle}
        </Typography>
      )}
      {searchLink && airline && AIRLINE_LOGOS[airline.toLowerCase()] && (
        <Button
          variant="outlined"
          size="small"
          href={searchLink}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={
            <Box
              component="img"
              src={AIRLINE_LOGOS[airline.toLowerCase()]}
              sx={{
                height: 14,
                width: 'auto',
                mr: 0.5,
                borderRadius: '2px'
              }}
              alt={`${airline} logo`}
            />
          }
          endIcon={
            <ArrowForwardIcon sx={{ 
              fontSize: 14,
              transform: 'rotate(-45deg)',
              ml: 0.5
            }} />
          }
          sx={{
            mt: 0.75,
            py: 0.5,
            px: 1,
            minHeight: 0,
            minWidth: 0,
            textTransform: 'none',
            fontSize: '0.75rem',
            borderColor: theme.palette.grey[300],
            borderWidth: '1px',
            '&:hover': {
              borderColor: theme.palette.grey[400],
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderWidth: '1px'
            },
            '& .MuiButton-endIcon': {
              ml: 0.5
            },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
          }}
        >
          {`Price with ${formatAirlineName(airline)}`}
        </Button>
      )}
      {isRefreshing && (
        <Box 
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px'
          }}
        >
          <Box 
            sx={{ 
              width: 14,
              height: 14,
              border: '1px solid',
              borderColor: `${theme.palette.primary.main} transparent ${theme.palette.primary.main} transparent`,
              borderRadius: '50%',
              animation: 'spin 1s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

const PriceHistoryGraph = ({ priceHistory, isAward, awardCurrency, onRefreshPrice, isUpcoming, priceChangeEvents: propPriceChangeEvents, currencySymbol = '$' }) => {
  const theme = useTheme();
  const [showTable, setShowTable] = useState(false);
  const [hoveredLine, setHoveredLine] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const data = useMemo(() => 
    priceHistory
      ? priceHistory
          .map(item => ({
            timestamp: new Date(item.timestamp).getTime(),
            price: parseFloat(item.price),
            originalTimestamp: item.timestamp
          }))
          .sort((a, b) => a.timestamp - b.timestamp)
      : [],
    [priceHistory]
  );

  const priceChangeEvents = useMemo(() => {
    if (!propPriceChangeEvents) return [];
    return propPriceChangeEvents.map(event => ({
      timestamp: new Date(event.event_date).getTime(),
      originalPrice: event.original_price,
      newPrice: event.new_price,
      priceDifference: event.price_difference,
      eventDate: event.event_date,
      changeType: event.change_type
    }));
  }, [propPriceChangeEvents]);

  const formatDate = useCallback((timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  }, []);

  const formatDateTime = useCallback((timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    });
  }, []);

  const formatPrice = useCallback((price) => {
    if (!price) return '-';
    if (isAward) {
      return `${parseFloat(price).toLocaleString()} ${awardCurrency}`;
    }
    return `${currencySymbol} ${parseFloat(price).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  }, [isAward, awardCurrency, currencySymbol]);

  const formatPriceDifference = useCallback((amount) => {
    if (!amount) return '-';
    const isNegative = amount < 0;
    return `${isNegative ? '-' : '+'}${currencySymbol} ${Math.abs(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  }, [currencySymbol]);

  const tooltipContent = useCallback(({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      
      // Check if this timestamp matches a price change event
      const priceChangeEvent = priceChangeEvents.find(event => 
        Math.abs(event.timestamp - data.timestamp) < 1000 * 60 * 5 // Within 5 minutes
      );

      if (priceChangeEvent) {
        return (
          <Box sx={{ 
            backgroundColor: 'white', 
            p: 1.5, 
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            minWidth: '200px'
          }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: theme.palette.warning.main, mb: 0.5 }}>
              Price Change ({priceChangeEvent.changeType})
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {formatDateTime(priceChangeEvent.eventDate)}
            </Typography>
            <Stack spacing={0.5}>
              <Typography variant="body2">
                Original: {formatPrice(priceChangeEvent.originalPrice)}
              </Typography>
              <Typography variant="body2">
                New: {formatPrice(priceChangeEvent.newPrice)}
              </Typography>
              <Typography variant="body2" sx={{ 
                color: priceChangeEvent.priceDifference > 0 ? theme.palette.error.main : theme.palette.success.main,
                fontWeight: 500 
              }}>
                Difference: {formatPriceDifference(priceChangeEvent.priceDifference)}
              </Typography>
            </Stack>
          </Box>
        );
      }

      return (
        <Box sx={{ 
          backgroundColor: 'white', 
          p: 1.5, 
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <Typography variant="body2" color="text.secondary">
            {formatDateTime(data.originalTimestamp)}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            {formatPrice(data.price)}
          </Typography>
        </Box>
      );
    }
    return null;
  }, [formatDateTime, formatPrice, priceChangeEvents, theme.palette, formatPriceDifference]);

  if (!priceHistory || priceHistory.length === 0) {
    return (
      <Paper 
        elevation={0} 
        sx={{ 
          p: 2.5, 
          backgroundColor: '#f8f9ff',
          border: '1px solid #e0e0e0',
          borderRadius: '8px'
        }}
      >
        <Box 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 6,
            px: 4,
            border: '2px dashed #e0e0e0',
            borderRadius: '8px',
            backgroundColor: '#f8f9ff',
            textAlign: 'center'
          }}
        >
          <Box 
            sx={{ 
              width: 48,
              height: 48,
              borderRadius: '50%',
              backgroundColor: `${theme.palette.primary.main}15`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2
            }}
          >
            <TimelineIcon 
              sx={{ 
                color: theme.palette.primary.main,
                fontSize: '28px'
              }} 
            />
          </Box>
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'text.primary',
              mb: 1,
              fontWeight: 500
            }}
          >
            No price data available
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ maxWidth: 400, mb: 3 }}
          >
            Click below to get the current price and start tracking price changes
          </Typography>
          <Button
            variant="contained"
            disabled={!isUpcoming}
            startIcon={<RefreshIcon />}
            onClick={onRefreshPrice}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.grey[500]
              }
            }}
          >
            Refresh Price Now
          </Button>
          {!isUpcoming && (
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ mt: 2 }}
            >
              Cannot refresh price for past reservations
            </Typography>
          )}
        </Box>
      </Paper>
    );
  }

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: { xs: 1.5, sm: 2, md: 2.5 }, 
        backgroundColor: '#f8f9ff',
        border: '1px solid #e0e0e0',
        borderRadius: '8px'
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: { xs: 'flex-start', sm: 'center' }, 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between', 
        mb: { xs: 1.5, sm: 2 },
        gap: { xs: 1, sm: 0 }
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TimelineIcon color="primary" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} />
          <Typography variant="subtitle1" sx={{ 
            fontWeight: 600,
            fontSize: { xs: '0.875rem', sm: '1rem' }
          }}>
            Price History {showTable ? 'Table' : 'Graph'} ({data.length} points)
          </Typography>
        </Box>
        <IconButton 
          size="small" 
          onClick={() => setShowTable(!showTable)}
          sx={{ 
            color: theme.palette.primary.main,
            padding: { xs: '4px', sm: '8px' },
            '&:hover': {
              backgroundColor: `${theme.palette.primary.main}15`
            }
          }}
        >
          {showTable ? <TimelineIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} /> : 
                      <TableChartIcon sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }} />}
        </IconButton>
      </Box>
      
      {showTable ? (
        <Box sx={{ 
          maxHeight: { xs: '250px', sm: '300px' }, 
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: { xs: '6px', sm: '8px' },
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
            '&:hover': {
              background: '#666',
            },
          },
        }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date & Time</TableCell>
                <TableCell align="right">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDateTime(item.originalTimestamp)}</TableCell>
                  <TableCell align="right">{formatPrice(item.price)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Box sx={{ width: '100%', height: { xs: 250, sm: 300 } }}>
          <ResponsiveContainer>
            <LineChart 
              data={data} 
              margin={{ 
                top: 5, 
                right: isMobile ? 5 : 20, 
                bottom: 25, 
                left: isMobile ? 5 : 20 
              }}
            >
              <defs>
                <linearGradient id="priceGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.3} />
                  <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0.05} />
                </linearGradient>
              </defs>
              <XAxis 
                dataKey="timestamp" 
                tickFormatter={formatDate}
                stroke="#666"
                axisLine={{ stroke: '#e0e0e0' }}
                tick={{ 
                  fill: '#666',
                  fontSize: isMobile ? 10 : 12
                }}
                dy={10}
                angle={-45}
                textAnchor="end"
                height={60}
                scale="time"
                type="number"
                domain={['auto', 'auto']}
                tickCount={isMobile ? 4 : 7}
                minTickGap={30}
              />
              <YAxis 
                tickFormatter={formatPrice}
                stroke="#666"
                axisLine={{ stroke: '#e0e0e0' }}
                tick={{ 
                  fill: '#666',
                  fontSize: isMobile ? 0 : 12
                }}
                dx={-10}
                width={isMobile ? 0 : 80}
                domain={['dataMin - 50', 'dataMax + 50']}
                hide={isMobile}
              />
              <RechartsTooltip 
                content={tooltipContent}
                cursor={{ stroke: theme.palette.primary.main, strokeWidth: 1 }}
              />
              <Area
                type="monotone"
                dataKey="price"
                stroke="none"
                fill="url(#priceGradient)"
                fillOpacity={1}
              />
              {priceChangeEvents.map((event, index) => (
                <ReferenceLine
                  key={index}
                  x={event.timestamp}
                  stroke={theme.palette.error.main}
                  strokeWidth={2}
                  strokeDasharray="4 4"
                  label={{
                    value: hoveredLine === index ? "Repriced" : "",
                    position: "top",
                    fill: theme.palette.error.main,
                    fontSize: isMobile ? 10 : 12,
                    fontWeight: 500
                  }}
                  onMouseEnter={() => setHoveredLine(index)}
                  onMouseLeave={() => setHoveredLine(null)}
                />
              ))}
              <Line 
                type="monotone" 
                dataKey="price" 
                stroke={theme.palette.primary.main}
                strokeWidth={2}
                dot={{ 
                  fill: theme.palette.primary.main,
                  stroke: 'white',
                  strokeWidth: 2,
                  r: isMobile ? 3 : 4
                }}
                activeDot={{ 
                  r: isMobile ? 5 : 6,
                  fill: theme.palette.primary.main,
                  stroke: 'white',
                  strokeWidth: 2
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Paper>
  );
};

const formatPrice = (price, isAward = false, awardCurrency = '', currencySymbol = '$') => {
  if (!price) return '-';
  if (isAward) {
    return (
      <Box component="span" sx={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'baseline' }}>
        <Box component="span" sx={{ mr: 0.5 }}>
          {parseFloat(price).toLocaleString()}
        </Box>
        <Typography 
          component="span" 
          sx={{ 
            fontSize: '0.75em',
            color: 'text.secondary',
            fontWeight: 'normal'
          }}
        >
          {awardCurrency}
        </Typography>
      </Box>
    );
  }
  return `${currencySymbol} ${parseFloat(price).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

const formatAwardPrice = (price, currency) => {
  if (!price) return '-';
  return (
    <Box component="span" sx={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'baseline' }}>
      <Box component="span" sx={{ mr: 0.5 }}>
        {parseFloat(price).toLocaleString()}
      </Box>
      <Typography 
        component="span" 
        sx={{ 
          fontSize: '0.75em',
          color: 'text.secondary',
          fontWeight: 'normal'
        }}
      >
        {currency}
      </Typography>
    </Box>
  );
};

const PricingInfo = ({ reservationData }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshError, setRefreshError] = useState(false);
  const [localPriceHistory, setLocalPriceHistory] = useState(reservationData.pricing_details.price_history || []);
  const pricing = reservationData.pricing_details;
  const priceHistory = localPriceHistory;
  const isAward = pricing.is_award;
  const awardCurrency = pricing.award_currency;
  const priceChangeEvents = pricing.price_change_events || [];
  const searchLink = reservationData.admin_data?.search_link;
  const airline = reservationData.reservation_details?.airline;
  const currencySymbol = pricing.currency_symbol || '$';

  const getDisplayPrice = (pricing) => {
    if (isAward) {
      return pricing.award_price;
    }
    return pricing.total_fare;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    });
  };

  const handleRefreshPrice = () => {
    setIsRefreshing(true);
    setRefreshError(false);
    const myHeaders = new Headers();
    myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
    myHeaders.append("Referer", "https://beluga.withautopilot.com/");
    myHeaders.append("User-Agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36");
    myHeaders.append("sec-ch-ua", "\"Google Chrome\";v=\"131\", \"Chromium\";v=\"131\", \"Not_A Brand\";v=\"24\"");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1] || '');
    myHeaders.append("sec-ch-ua-mobile", "?0");

    const raw = JSON.stringify({
      reservation_id: reservationData.reservation_id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://admin-api.withautopilot.com/admin_portal/refresh_reservation_price", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.price && typeof result.price === 'number' && !isNaN(result.price)) {
          const newPricePoint = {
            price: result.price,
            timestamp: new Date().toISOString()
          };
          setLocalPriceHistory(prevHistory => [...prevHistory, newPricePoint]);
        } else {
          setRefreshError(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setRefreshError(true);
      })
      .finally(() => {
        setIsRefreshing(false);
      });
  };

  const lastPriceHistory = priceHistory[priceHistory.length - 1];

  const formatPriceComparison = () => {
    const originalPrice = getDisplayPrice(pricing);
    const currentPrice = lastPriceHistory?.price;
    
    if (!currentPrice) return formatPrice(originalPrice, isAward, awardCurrency, currencySymbol);
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <span>{formatPrice(currentPrice, isAward, awardCurrency, currencySymbol)}</span>
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: theme.palette.success.main,
            animation: 'pulse 2s infinite',
            '@keyframes pulse': {
              '0%': {
                transform: 'scale(0.95)',
                boxShadow: '0 0 0 0 rgba(76, 175, 80, 0.7)',
              },
              '70%': {
                transform: 'scale(1)',
                boxShadow: '0 0 0 6px rgba(76, 175, 80, 0)',
              },
              '100%': {
                transform: 'scale(0.95)',
                boxShadow: '0 0 0 0 rgba(76, 175, 80, 0)',
              },
            },
          }}
        />
      </Box>
    );
  };

  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: { xs: 1.5, sm: 2 },
        border: '1px solid #e0e0e0',
        borderRadius: '12px',
        mt: 2
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          py: { xs: 0.5, sm: 1 },
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
          gap: { xs: 1, sm: 0 },
          '&:hover': {
            '& .MuiIconButton-root': {
              backgroundColor: `${theme.palette.primary.main}15`
            }
          }
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
          width: { xs: '100%', sm: 'auto' }
        }}>
          <MonetizationOnIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }} color="primary" />
          <Typography variant="h6" sx={{ 
            fontWeight: 500, 
            fontSize: { xs: '0.9rem', sm: '1rem' }
          }}>
            Pricing Information
          </Typography>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              borderLeft: { xs: 'none', sm: '1px solid #e0e0e0' },
              paddingLeft: { xs: 0, sm: 1 },
              marginLeft: { xs: 0, sm: 0.5 },
              fontSize: { xs: '0.8rem', sm: '0.875rem' },
              fontWeight: 400,
              color: theme.palette.text.secondary,
              display: 'flex',
              alignItems: 'center',
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            {formatPriceComparison()}
          </Typography>
        </Box>
        <IconButton 
          size="small"
          sx={{
            padding: { xs: 0.25, sm: 0.5 },
            transition: 'all 0.2s ease',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        >
          <KeyboardArrowDownIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }} />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box sx={{ mt: { xs: 0.5, sm: 1 } }}>
          <Stack spacing={{ xs: 0.75, sm: 1 }}>
            {/* Price Summary Cards */}
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                flexWrap: 'wrap',
                gap: { xs: 0.75, sm: 1 },
                width: '100%',
                '& > *': {
                  flex: {
                    xs: '1 1 100%',
                    sm: '1 1 calc(50% - 8px)',
                    md: '1 1 calc(25% - 12px)'
                  },
                  minWidth: {
                    xs: '100%',
                    sm: 'calc(50% - 8px)',
                    md: 'calc(25% - 12px)'
                  }
                }
              }}
            >
              <PriceSummaryCard 
                amount={formatPrice(getDisplayPrice(pricing), isAward, awardCurrency, currencySymbol)}
                title="Total Original Payment Amount"
                subtitle={`Ticket Issue Date: ${formatDate(reservationData.reservation_details.ticket_issue_date)}`}
              />
              <PriceSummaryCard 
                amount={formatPrice(pricing.total_savings || 0, isAward, awardCurrency, currencySymbol)}
                title="Total Saved on this Reservation"
              />
              <PriceSummaryCard 
                amount={formatPrice(isAward ? pricing.award_price : pricing.current_effective_price, isAward, awardCurrency, currencySymbol)}
                title="Current Effective Price"
                subtitle="Fare Paid, Adjusted for Lowest Fare Guarantee"
              />
              <PriceSummaryCard 
                amount={refreshError ? "Err" : (lastPriceHistory ? formatPrice(lastPriceHistory.price, isAward, awardCurrency, currencySymbol) : '-')}
                title="Last Price Seen"
                subtitle={lastPriceHistory ? formatDate(lastPriceHistory.timestamp) : '-'}
                onRefresh={handleRefreshPrice}
                isUpcoming={reservationData.reservation_details.flags.is_upcoming}
                isRefreshing={isRefreshing}
                searchLink={searchLink}
                airline={airline}
              />
            </Box>

            {/* Price History Graph */}
            {priceHistory.length > 0 && (
              <PriceHistoryGraph 
                priceHistory={priceHistory}
                isAward={isAward}
                awardCurrency={awardCurrency}
                onRefreshPrice={handleRefreshPrice}
                isUpcoming={reservationData.reservation_details.flags.is_upcoming}
                priceChangeEvents={priceChangeEvents}
                currencySymbol={currencySymbol}
              />
            )}

            {/* Savings and Credits */}
            <SavingsAndCredits priceChangeEvents={priceChangeEvents} />
          </Stack>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default PricingInfo; 