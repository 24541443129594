import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
  IconButton,
  CircularProgress,
  Alert,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getToken } from '../../../utils/auth';

const reasonMapping = {
  'upgrade_applied': 'elite_upgrade',
  'ineligible_fare': 'ineligible_fare',
  'partially_flown': 'partially_flown',
  'price_up': 'other',
  'reservation_cancelled': 'reservation_cancelled',
  'other': 'other'
};

// Default settings for each reason
const reasonDefaults = {
  'upgrade_applied': { sendEmail: true, keepTracking: false },
  'ineligible_fare': { sendEmail: true, keepTracking: false },
  'partially_flown': { sendEmail: false, keepTracking: false },
  'price_up': { sendEmail: false, keepTracking: true },
  'reservation_cancelled': { sendEmail: false, keepTracking: false },
  'other': { sendEmail: true, keepTracking: true }
};

const UnableToProcessModal = ({ open, onClose, reservationData }) => {
  const [reason, setReason] = useState('');
  const [sendEmail, setSendEmail] = useState(true);
  const [keepTracking, setKeepTracking] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [userOverrode, setUserOverrode] = useState({ sendEmail: false, keepTracking: false });

  // Set default values when reason changes
  useEffect(() => {
    if (reason && reasonDefaults[reason] && !userOverrode.sendEmail) {
      setSendEmail(reasonDefaults[reason].sendEmail);
    }
    
    if (reason && reasonDefaults[reason] && !userOverrode.keepTracking) {
      setKeepTracking(reasonDefaults[reason].keepTracking);
    }
  }, [reason]);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleSendEmailChange = (e) => {
    setSendEmail(e.target.checked);
    setUserOverrode({ ...userOverrode, sendEmail: true });
  };

  const handleKeepTrackingChange = (e) => {
    setKeepTracking(e.target.checked);
    setUserOverrode({ ...userOverrode, keepTracking: true });
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setError('');
    
    const myHeaders = new Headers();
    myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
    myHeaders.append("Referer", "https://beluga.withautopilot.com/");
    myHeaders.append("User-Agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/132.0.0.0 Safari/537.36");
    myHeaders.append("sec-ch-ua", "\"Not A(Brand\";v=\"8\", \"Chromium\";v=\"132\", \"Google Chrome\";v=\"132\"");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());
    myHeaders.append("sec-ch-ua-mobile", "?0");

    const raw = JSON.stringify({
      "reservation_id": reservationData.reservation_id,
      "error": reasonMapping[reason],
      "is_tracking_turned_off": !keepTracking,
      "send_email": sendEmail
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/send_couldnt_refare_email", requestOptions);
      const result = await response.json();
      
      if (result.success) {
        setShowSuccess(true);
        onClose();
      } else {
        setError('Failed to process the request. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting unable to process:', error);
      setError('An error occurred while processing the request. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  const handleCloseError = () => {
    setError('');
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="unable-to-process-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '95%',
          maxWidth: 500,
          bgcolor: 'white',
          borderRadius: 2,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
          outline: 'none',
        }}>
          {/* Header */}
          <Box sx={{
            p: 3,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h6" component="h2">
              Unable to Process
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Content */}
          <Box sx={{ p: 3 }}>
            {error && (
              <Alert 
                severity="error" 
                sx={{ mb: 3 }}
                onClose={handleCloseError}
              >
                {error}
              </Alert>
            )}

            <FormControl fullWidth sx={{ mb: 3 }}>
              <Typography sx={{ mb: 1 }}>Reason</Typography>
              <Select
                value={reason}
                onChange={handleReasonChange}
                displayEmpty
              >
                <MenuItem value="" disabled>Select a reason</MenuItem>
                <MenuItem value="upgrade_applied">Upgrade Applied</MenuItem>
                <MenuItem value="ineligible_fare">Ineligible Fare</MenuItem>
                <MenuItem value="partially_flown">Partially Flown Ticket</MenuItem>
                <MenuItem value="price_up">Price Went Up</MenuItem>
                <MenuItem value="reservation_cancelled">Reservation Cancelled</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ mb: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmail}
                    onChange={handleSendEmailChange}
                    sx={{
                      color: '#535aff',
                      '&.Mui-checked': {
                        color: '#535aff',
                      },
                    }}
                  />
                }
                label="Send Customer Email"
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography sx={{ mb: 1 }}>Keep Tracking</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={keepTracking}
                    onChange={handleKeepTrackingChange}
                  />
                }
                label={keepTracking ? "On" : "Off"}
              />
            </Box>

            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={!reason || submitting}
              sx={{
                bgcolor: '#d32f2f',
                '&:hover': {
                  bgcolor: '#b71c1c',
                },
                '&.Mui-disabled': {
                  bgcolor: 'rgba(211, 47, 47, 0.5)',
                },
              }}
            >
              {submitting ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                  <span>Submitting...</span>
                </Box>
              ) : (
                'Submit'
              )}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={showSuccess}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          severity="success"
          onClose={handleCloseSuccess}
          sx={{ 
            bgcolor: '#4caf50',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            },
            '& .MuiAlert-action': {
              color: 'white',
              '& .MuiSvgIcon-root': {
                fontSize: '20px'
              }
            }
          }}
        >
          Unable to Process: {reservationData?.pnr || 'Reservation'} was recorded successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UnableToProcessModal; 