import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  IconButton, 
  Chip,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { AIRLINE_LOGOS, getAirlineLogo } from '../utils';

const HighLevelInfo = ({ reservationData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [copiedStates, setCopiedStates] = useState({
    reservation: false,
    pnr: false
  });

  const handleCopy = (text, type, e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    
    setCopiedStates(prev => ({ ...prev, [type]: true }));
    setTimeout(() => {
      setCopiedStates(prev => ({ ...prev, [type]: false }));
    }, 1500);
  };

  const handleUserClick = (e) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams();
    searchParams.set('user_id', reservationData.user_info.user_id);
    navigate({
      pathname: '/users',
      search: searchParams.toString()
    });
  };

  const getPlanChipStyle = (plan) => {
    switch (plan) {
      case 'free':
        return {
          bgcolor: 'white',
          border: '1px solid #e0e0e0',
          color: 'text.primary'
        };
      case 'card_linked':
        return {
          bgcolor: `${theme.palette.primary.main}15`,
          color: theme.palette.primary.main
        };
      case 'pro':
        return {
          bgcolor: 'success.light',
          color: 'success.dark'
        };
      default:
        return {
          bgcolor: 'white',
          border: '1px solid #e0e0e0',
          color: 'text.primary'
        };
    }
  };

  const highlightedTextStyle = {
    margin: 0,
    fontFamily: '"Haffer Trial", sans-serif',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 700,
    backgroundColor: `${theme.palette.primary.main}15`,
    padding: '2px 8px',
    borderRadius: '4px'
  };

  // Format route from trips (show complete route for round trips)
  const formatRoute = () => {
    if (!reservationData.trips || reservationData.trips.length === 0) return '';
    
    const airports = [];
    
    // Add departure and arrival for each trip
    reservationData.trips.forEach((trip, index) => {
      if (index === 0 || trip.departure_airport !== airports[airports.length - 1]) {
        airports.push(trip.departure_airport);
      }
      airports.push(trip.arrival_airport);
    });
    
    return airports.join(' - ');
  };

  // Get departure date from first trip
  const getDepartureDate = () => {
    if (!reservationData.trips || reservationData.trips.length === 0) return '';
    const date = new Date(reservationData.trips[0].departure_datetime_local);
    return date.toLocaleDateString('en-US', { 
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  // Get airline logo
  const getAirlineLogoUrl = () => {
    const airline = reservationData.reservation_details?.airline?.toLowerCase();
    return AIRLINE_LOGOS[airline] || null;
  };

  const formatPlanText = (plan) => {
    return plan
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const toTitleCase = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const formatBillingAddress = () => {
    const address = reservationData.admin_data?.billing_info?.payment_method?.address;
    if (!address) return '';
    
    return `${address.line1}${address.line2 ? `\n${address.line2}` : ''}\n${address.city}, ${address.state} ${address.postal_code}\n${address.country}`;
  };

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      alignItems: { xs: 'flex-start', md: 'center' },
      width: '100%',
      p: 2,
      gap: { xs: 2, md: 4 },
      pr: { xs: 2, md: 6 },
      position: 'relative'
    }}>
      {isMobile && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      )}
      
      {/* Airline Logo and Route */}
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        flex: { xs: '1', md: 2 },
        width: '100%'
      }}>
        <Tooltip title={toTitleCase(reservationData.reservation_details?.airline || '')} placement="top">
          <Box
            component="img"
            src={getAirlineLogoUrl()}
            alt={`${reservationData.reservation_details?.airline} logo`}
            sx={{
              width: { xs: 32, md: 40 },
              height: { xs: 32, md: 40 },
              objectFit: 'contain',
              mr: 2,
              borderRadius: '8px',
              padding: '6px',
              backgroundColor: 'white',
              boxShadow: `0 2px 4px rgba(0,0,0,${!reservationData.reservation_details?.flags?.is_upcoming ? '0.02' : '0.05'})`,
              filter: !reservationData.reservation_details?.flags?.is_upcoming ? 'grayscale(100%)' : 'none',
              opacity: !reservationData.reservation_details?.flags?.is_upcoming ? 0.5 : 1
            }}
          />
        </Tooltip>
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, color: 'text.primary' }}>
            {formatRoute()}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            {getDepartureDate()}
          </Typography>
        </Box>
      </Box>

      {/* All IDs in the middle */}
      <Box sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'flex-start', md: 'center' },
        gap: { xs: 2, md: 3 },
        flex: { xs: '1', md: 1.2 },
        width: '100%',
        justifyContent: { xs: 'flex-start', md: 'center' }
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          minWidth: { xs: '100%', md: '140px' }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary" noWrap>
              Reservation ID
            </Typography>
            <Box
              onClick={(e) => handleCopy(reservationData.reservation_id, 'reservation', e)}
              sx={{
                ...highlightedTextStyle,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: `${theme.palette.primary.main}25`
                }
              }}
            >
              <Typography sx={{ flex: 1, fontWeight: 700 }}>
                {reservationData.reservation_id}
              </Typography>
              {copiedStates.reservation ? (
                <CheckIcon sx={{ fontSize: '0.875rem', color: 'success.main' }} />
              ) : (
                <ContentCopyIcon sx={{ fontSize: '0.875rem' }} />
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          minWidth: { xs: '100%', md: '100px' }
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary" noWrap>
              PNR
            </Typography>
            <Box
              onClick={(e) => handleCopy(reservationData.pnr, 'pnr', e)}
              sx={{
                ...highlightedTextStyle,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: `${theme.palette.primary.main}25`
                }
              }}
            >
              <Typography sx={{ flex: 1, fontWeight: 700 }}>
                {reservationData.pnr}
              </Typography>
              {copiedStates.pnr ? (
                <CheckIcon sx={{ fontSize: '0.875rem', color: 'success.main' }} />
              ) : (
                <ContentCopyIcon sx={{ fontSize: '0.875rem' }} />
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ 
          minWidth: { xs: '100%', md: '100px' }
        }}>
          <Typography variant="body2" color="text.secondary" noWrap>
            User ID
          </Typography>
          <Box
            onClick={handleUserClick}
            sx={{
              ...highlightedTextStyle,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}25`
              }
            }}
          >
            <Typography sx={{ flex: 1, fontWeight: 700 }}>
              {reservationData.user_info.user_id}
            </Typography>
            {!isMobile && <ArrowForwardIcon sx={{ fontSize: '0.875rem' }} />}
          </Box>
        </Box>
      </Box>

      {/* User Name and Plan on the right */}
      <Box sx={{ 
        display: 'flex',
        alignItems: { xs: 'flex-start', md: 'center' },
        flex: { xs: '1', md: 0.8 },
        justifyContent: { xs: 'flex-start', md: 'flex-end' },
        gap: 2,
        width: '100%'
      }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'flex-start', md: 'center' },
          gap: 0.5
        }}>
          <Typography sx={{ fontWeight: 500, fontSize: '1rem', textAlign: { xs: 'left', md: 'center' } }}>
            {reservationData.user_info.first_name} {reservationData.user_info.last_name}
          </Typography>
          <Chip 
            label={formatPlanText(reservationData.user_info.plan)}
            size="small"
            sx={{ 
              height: '24px',
              ...getPlanChipStyle(reservationData.user_info.plan)
            }} 
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HighLevelInfo; 