import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Button, 
  IconButton, 
  Collapse, 
  Tooltip,
  Tabs,
  Tab,
  CircularProgress,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'js-cookie';
import { LineChart } from '@mui/x-charts/LineChart';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableViewIcon from '@mui/icons-material/TableView';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

dayjs.extend(relativeTime);

const UpgradeOffers = ({ reservationData, onRefresh }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedSegmentTab, setSelectedSegmentTab] = useState(0);
  const [selectedCabinTabs, setSelectedCabinTabs] = useState({});
  const [isInitializing, setIsInitializing] = useState(false);
  const [refreshingSegments, setRefreshingSegments] = useState({});
  const [error, setError] = useState(null);
  const isUpcoming = reservationData?.reservation_details?.flags?.is_upcoming;
  const [viewMode, setViewMode] = useState('graph');

  const upgradeAlerts = reservationData?.pricing_details?.upgrades?.alerts || [];
  const upgradeHistory = reservationData?.pricing_details?.upgrades?.upgrade_history || {};

  const getSegments = () => {
    const segments = [];
    Object.keys(upgradeHistory).forEach(segmentId => {
      const cabins = Object.keys(upgradeHistory[segmentId]);
      const matchingSegment = reservationData.trips[0].segments.find(s => s.segment_id.toString() === segmentId);
      if (matchingSegment) {
        segments.push({
          segment_id: segmentId,
          cabins,
          ...matchingSegment
        });
      }
    });
    return segments;
  };

  // Initialize cabin tabs when segments change
  useEffect(() => {
    const segments = getSegments();
    const initialCabinTabs = {};
    segments.forEach(segment => {
      if (segment.cabins.length > 0) {
        initialCabinTabs[segment.segment_id] = 0;
      }
    });
    setSelectedCabinTabs(initialCabinTabs);
  }, [upgradeHistory]);

  const formatShortDateTime = (dateString) => {
    return dayjs(dateString).fromNow();
  };

  const formatFullDateTime = (dateString) => {
    return dayjs(dateString).format('MMM D, YYYY h:mm:ss A z');
  };

  const prepareGraphData = (segmentId, cabin) => {
    if (!upgradeHistory[segmentId]?.[cabin]) return [];
    
    // Group offers by timestamp to combine cash and miles offers
    const groupedOffers = {};
    upgradeHistory[segmentId][cabin].forEach(offer => {
      const timestamp = offer.created_at;
      if (!groupedOffers[timestamp]) {
        groupedOffers[timestamp] = {
          timestamp,
          date: new Date(timestamp),
          price: null,
          miles: null
        };
      }
      if (offer.offer_type === 'cash') {
        groupedOffers[timestamp].price = offer.price;
      } else {
        groupedOffers[timestamp].miles = offer.miles_required;
      }
    });

    return Object.values(groupedOffers).sort((a, b) => a.date - b.date);
  };

  const handleInitializeUpgrades = async () => {
    setIsInitializing(true);
    setError(null);
    try {
      const token = Cookies.get('token');
      const myHeaders = new Headers();
      myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
      myHeaders.append("Referer", "https://beluga.withautopilot.com/");
      myHeaders.append("User-Agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36");
      myHeaders.append("sec-ch-ua", "\"Google Chrome\";v=\"131\", \"Chromium\";v=\"131\", \"Not_A Brand\";v=\"24\"");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", token);
      myHeaders.append("sec-ch-ua-mobile", "?0");

      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/initialize_upgrade_offers", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          reservation_id: reservationData.reservation_id
        }),
        redirect: "follow"
      });

      const data = await response.json();
      if (data.success && onRefresh) {
        onRefresh();
      } else {
        setError("Failed to initialize upgrade offers");
      }
    } catch (err) {
      setError(err.message || "Failed to initialize upgrade offers");
    } finally {
      setIsInitializing(false);
    }
  };

  const handleRefreshSegment = async (segmentId) => {
    setRefreshingSegments(prev => ({ ...prev, [segmentId]: true }));
    try {
      const token = Cookies.get('token');
      const myHeaders = new Headers();
      myHeaders.append("sec-ch-ua-platform", "\"macOS\"");
      myHeaders.append("Referer", "https://beluga.withautopilot.com/");
      myHeaders.append("User-Agent", "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36");
      myHeaders.append("sec-ch-ua", "\"Google Chrome\";v=\"131\", \"Chromium\";v=\"131\", \"Not_A Brand\";v=\"24\"");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", token);
      myHeaders.append("sec-ch-ua-mobile", "?0");

      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/refresh_upgrade_offers", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          reservation_id: reservationData.reservation_id,
          segment_id: segmentId
        }),
        redirect: "follow"
      });

      const data = await response.json();
      if (data.success) {
        // Process the new upgrade offers
        const currentTime = new Date().toISOString();
        const newOffers = data.upgrade_offer[0].upgrades;
        
        // Add each new offer to the upgrade history
        newOffers.forEach(offer => {
          if (offer.cabin !== 'Main Cabin') { // Skip Main Cabin as it's always 0
            // Add cash offer
            if (offer.cash_price > 0) {
              const cashOffer = {
                created_at: currentTime,
                currency: 'USD',
                miles_required: null,
                offer_type: 'cash',
                price: offer.cash_price,
                offer_source: 'website'
              };
              if (!upgradeHistory[segmentId]) {
                upgradeHistory[segmentId] = {};
              }
              if (!upgradeHistory[segmentId][offer.cabin]) {
                upgradeHistory[segmentId][offer.cabin] = [];
              }
              upgradeHistory[segmentId][offer.cabin].push(cashOffer);
            }

            // Add points offer
            if (offer.points_price > 0) {
              const milesOffer = {
                created_at: currentTime,
                currency: 'USD',
                miles_required: offer.points_price,
                offer_type: 'miles',
                price: 0,
                offer_source: 'website'
              };
              if (!upgradeHistory[segmentId]) {
                upgradeHistory[segmentId] = {};
              }
              if (!upgradeHistory[segmentId][offer.cabin]) {
                upgradeHistory[segmentId][offer.cabin] = [];
              }
              upgradeHistory[segmentId][offer.cabin].push(milesOffer);
            }
          }
        });

        if (onRefresh) {
          onRefresh();
        }
      }
    } catch (err) {
      console.error('Error refreshing segment:', err);
    } finally {
      setRefreshingSegments(prev => ({ ...prev, [segmentId]: false }));
    }
  };

  const formatRoute = (segment) => {
    return `${segment.departure_airport} - ${segment.arrival_airport} (${segment.flight_number})`;
  };

  const handleSegmentTabChange = (event, newValue) => {
    setSelectedSegmentTab(newValue);
  };

  const handleCabinTabChange = (segmentId) => (event, newValue) => {
    setSelectedCabinTabs(prev => ({
      ...prev,
      [segmentId]: newValue
    }));
  };

  const segments = getSegments();
  const hasUpgradeData = segments.length > 0;

  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: 2,
        border: '1px solid #e0e0e0',
        borderRadius: '12px',
        mt: 2
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          '&:hover': {
            '& .MuiIconButton-root': {
              backgroundColor: `${theme.palette.primary.main}15`
            }
          }
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AirlineSeatReclineExtraIcon color="primary" />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Upgrade Offers
            </Typography>
            {upgradeAlerts.length > 0 && (
              <>
                <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 400 }}>
                  |
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 400 }}>
                  {upgradeAlerts.length} {upgradeAlerts.length === 1 ? 'Alert' : 'Alerts'}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <IconButton 
          size="small"
          sx={{
            transition: 'all 0.2s ease',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box sx={{ mt: 2 }}>
          {!hasUpgradeData ? (
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                py: 6,
                px: 4,
                border: '2px dashed #e0e0e0',
                borderRadius: '8px',
                backgroundColor: '#f8f9ff',
                textAlign: 'center'
              }}
            >
              <Box 
                sx={{ 
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                  backgroundColor: `${theme.palette.primary.main}15`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2
                }}
              >
                <AirlineSeatReclineExtraIcon 
                  sx={{ 
                    color: theme.palette.primary.main,
                    fontSize: '28px'
                  }} 
                />
              </Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: 'text.primary',
                  mb: 1,
                  fontWeight: 500
                }}
              >
                No upgrade offers available yet
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ maxWidth: 400, mb: 3 }}
              >
                Initialize upgrade tracking to start monitoring available offers for your flight
              </Typography>
              {error && (
                <Typography 
                  variant="body2" 
                  color="error"
                  sx={{ mb: 2 }}
                >
                  {error}
                </Typography>
              )}
              <Tooltip title={!isUpcoming ? "Cannot initialize upgrades for past reservations" : ""}>
                <span>
                  <Button
                    variant="contained"
                    disabled={!isUpcoming || isInitializing}
                    startIcon={isInitializing ? <CircularProgress size={20} /> : <AddIcon />}
                    onClick={handleInitializeUpgrades}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark
                      },
                      '&.Mui-disabled': {
                        backgroundColor: theme.palette.grey[200],
                        color: theme.palette.grey[500]
                      }
                    }}
                  >
                    {isInitializing ? 'Initializing...' : 'Initialize Upgrade Offers'}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          ) : (
            <Stack spacing={3}>
              {/* Upgrade Alerts Table */}
              <Box>
                <Typography variant="h6" gutterBottom>
                  Active Upgrade Alerts
                </Typography>
                {upgradeAlerts.length === 0 ? (
                  <Paper 
                    variant="outlined" 
                    sx={{ 
                      p: 3, 
                      textAlign: 'center',
                      backgroundColor: '#f8f9ff',
                      border: '1px dashed #e0e0e0'
                    }}
                  >
                    <Typography color="text.secondary">
                      No active upgrade alerts. Alerts will appear here when price targets are set for upgrades.
                    </Typography>
                  </Paper>
                ) : (
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Alert ID</TableCell>
                          <TableCell>Route</TableCell>
                          <TableCell>Target Cabin</TableCell>
                          <TableCell>Alert Price</TableCell>
                          <TableCell>Currency</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Created</TableCell>
                          <TableCell>Last Updated</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {upgradeAlerts.map((alert, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{alert.alert_id}</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{`${alert.departure_airport} - ${alert.arrival_airport}`}</TableCell>
                            <TableCell>{alert.target_cabin}</TableCell>
                            <TableCell>
                              {alert.currency === 'cash' 
                                ? `$${alert.alert_price.toFixed(2)}` 
                                : `${alert.alert_price.toLocaleString()} miles`}
                            </TableCell>
                            <TableCell>{alert.currency === 'cash' ? 'Cash' : 'Miles'}</TableCell>
                            <TableCell>
                              <Chip
                                label={alert.is_active ? 'Active' : 'Inactive'}
                                color={alert.is_active ? 'success' : 'default'}
                                size="small"
                              />
                            </TableCell>
                            <TableCell>
                              <Tooltip 
                                title={
                                  <Box sx={{ p: 1 }}>
                                    <Typography variant="body2">
                                      Created: {formatFullDateTime(alert.created_at)}
                                    </Typography>
                                  </Box>
                                }
                              >
                                <span style={{ cursor: 'help' }}>{formatShortDateTime(alert.created_at)}</span>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Tooltip 
                                title={
                                  <Box sx={{ p: 1 }}>
                                    <Typography variant="body2">
                                      Updated: {formatFullDateTime(alert.updated_at)}
                                    </Typography>
                                  </Box>
                                }
                              >
                                <span style={{ cursor: 'help' }}>{formatShortDateTime(alert.updated_at)}</span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>

              {/* Segments and Cabins Tabs */}
              <Box>
                <Tabs
                  value={selectedSegmentTab}
                  onChange={handleSegmentTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="flight segments tabs"
                >
                  {segments.map((segment, index) => (
                    <Tab 
                      key={segment.segment_id}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {upgradeHistory[segment.segment_id] && Object.keys(upgradeHistory[segment.segment_id]).length > 0 && (
                            <Box
                              sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                bgcolor: 'success.main',
                                flexShrink: 0
                              }}
                            />
                          )}
                          <span>{formatRoute(segment)}</span>
                        </Box>
                      }
                      id={`segment-tab-${index}`}
                      aria-controls={`segment-panel-${index}`}
                    />
                  ))}
                </Tabs>

                {segments.map((segment, index) => (
                  <Box
                    key={segment.segment_id}
                    role="tabpanel"
                    hidden={selectedSegmentTab !== index}
                    id={`segment-panel-${index}`}
                    aria-labelledby={`segment-tab-${index}`}
                    sx={{ mt: 2 }}
                  >
                    {selectedSegmentTab === index && (
                      <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                          <Tooltip title="When refreshing prices with this button no email will be sent to the user if their alert is found. However, it will be sent the next time the pricing function runs.">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={refreshingSegments[segment.segment_id] ? <CircularProgress size={20} color="inherit" /> : <RefreshIcon />}
                              onClick={() => handleRefreshSegment(segment.segment_id)}
                              disabled={refreshingSegments[segment.segment_id]}
                              sx={{
                                backgroundColor: '#535aff',
                                '&:hover': {
                                  backgroundColor: '#4045cc'
                                }
                              }}
                            >
                              {refreshingSegments[segment.segment_id] ? 'Refreshing...' : 'Refresh Upgrade Prices'}
                            </Button>
                          </Tooltip>
                        </Box>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs
                            value={selectedCabinTabs[segment.segment_id] || 0}
                            onChange={handleCabinTabChange(segment.segment_id)}
                            aria-label="cabin tabs"
                          >
                            {segment.cabins.map((cabin, cabinIndex) => (
                              <Tab 
                                key={cabin}
                                label={cabin}
                                id={`cabin-tab-${cabinIndex}`}
                                aria-controls={`cabin-panel-${cabinIndex}`}
                              />
                            ))}
                          </Tabs>
                        </Box>

                        {segment.cabins.map((cabin, cabinIndex) => (
                          <Box
                            key={cabin}
                            role="tabpanel"
                            hidden={selectedCabinTabs[segment.segment_id] !== cabinIndex}
                            id={`cabin-panel-${cabinIndex}`}
                            aria-labelledby={`cabin-tab-${cabinIndex}`}
                            sx={{ mt: 2 }}
                          >
                            {selectedCabinTabs[segment.segment_id] === cabinIndex && (
                              <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                  <ToggleButtonGroup
                                    value={viewMode}
                                    exclusive
                                    onChange={(e, newMode) => newMode && setViewMode(newMode)}
                                    size="small"
                                    sx={{ mr: 2 }}
                                  >
                                    <ToggleButton value="graph" aria-label="graph view">
                                      <BarChartIcon />
                                    </ToggleButton>
                                    <ToggleButton value="table" aria-label="table view">
                                      <TableViewIcon />
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Box>

                                {viewMode === 'graph' ? (
                                  <Box sx={{ height: 400, position: 'relative' }}>
                                    <LineChart
                                      dataset={prepareGraphData(segment.segment_id, cabin)}
                                      xAxis={[{
                                        dataKey: 'date',
                                        scaleType: 'time',
                                        valueFormatter: (value) => dayjs(value).format('MMM D, YYYY h:mm A'),
                                        tickLabelStyle: { fontSize: 12 }
                                      }]}
                                      yAxis={[
                                        {
                                          id: 'price',
                                          scaleType: 'linear',
                                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : '-',
                                          label: 'Cash Price ($)',
                                          labelStyle: { 
                                            fill: '#535aff',
                                            fontSize: 14
                                          },
                                          tickLabelStyle: { fontSize: 12 },
                                          tickSize: 8
                                        },
                                        {
                                          id: 'miles',
                                          scaleType: 'linear',
                                          valueFormatter: (value) => value != null ? `${value.toLocaleString()} miles` : '-',
                                          label: 'Miles Required',
                                          position: 'right',
                                          labelStyle: { 
                                            fill: '#9c27b0',
                                            fontSize: 14
                                          },
                                          tickLabelStyle: { fontSize: 12 },
                                          tickSize: 8,
                                          tickPlacement: 'end'
                                        }
                                      ]}
                                      series={[
                                        {
                                          dataKey: 'price',
                                          label: 'Cash Price',
                                          yAxisKey: 'price',
                                          color: '#535aff',
                                          showMark: true,
                                          connectNulls: true,
                                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : '-',
                                          area: false,
                                          lineWidth: 2,
                                          markerSize: 6
                                        },
                                        {
                                          dataKey: 'miles',
                                          label: 'Miles Required',
                                          yAxisKey: 'miles',
                                          color: '#9c27b0',
                                          showMark: true,
                                          connectNulls: true,
                                          valueFormatter: (value) => value != null ? `${value.toLocaleString()} miles` : '-',
                                          area: false,
                                          lineWidth: 2,
                                          markerSize: 6
                                        }
                                      ]}
                                      slotProps={{
                                        legend: {
                                          direction: 'row',
                                          position: { vertical: 'top', horizontal: 'middle' },
                                          padding: 0,
                                          labelStyle: { fontSize: 12 }
                                        }
                                      }}
                                      tooltip={{
                                        trigger: 'axis',
                                        axisPointer: {
                                          type: 'line'
                                        },
                                        slots: {
                                          pointerContent: ({ point }) => {
                                            if (!point?.payload) return null;
                                            const data = point.payload;
                                            return (
                                              <Box sx={{ 
                                                bgcolor: 'background.paper',
                                                p: 1.5,
                                                boxShadow: 1,
                                                borderRadius: 1
                                              }}>
                                                <Typography variant="caption" display="block" sx={{ mb: 1 }}>
                                                  {dayjs(data.date).format('MMM D, YYYY h:mm A')}
                                                </Typography>
                                                {data.price !== null && (
                                                  <Typography variant="body2" sx={{ color: '#535aff', mb: 0.5 }}>
                                                    Cash Price: ${data.price.toLocaleString()}
                                                  </Typography>
                                                )}
                                                {data.miles !== null && (
                                                  <Typography variant="body2" sx={{ color: '#9c27b0' }}>
                                                    Miles Required: {data.miles.toLocaleString()}
                                                  </Typography>
                                                )}
                                              </Box>
                                            );
                                          }
                                        }
                                      }}
                                      height={350}
                                      margin={{ left: 70, right: 90, top: 40, bottom: 40 }}
                                      sx={{
                                        '.MuiLineElement-root': {
                                          strokeWidth: 2
                                        },
                                        '.MuiMarkElement-root': {
                                          strokeWidth: 2,
                                          r: 4,
                                          fill: 'white',
                                          '&:hover': {
                                            r: 6
                                          }
                                        }
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <TableContainer component={Paper} variant="outlined">
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Timestamp</TableCell>
                                          <TableCell align="right">Cash Price</TableCell>
                                          <TableCell align="right">Miles Required</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {prepareGraphData(segment.segment_id, cabin).map((row, index) => (
                                          <TableRow key={index} hover>
                                            <TableCell>
                                              <Tooltip 
                                                title={dayjs(row.date).format('MMM D, YYYY h:mm:ss A z')}
                                              >
                                                <span style={{ cursor: 'help' }}>
                                                  {dayjs(row.date).format('MMM D, YYYY h:mm A')}
                                                </span>
                                              </Tooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.price !== null ? `$${row.price.toLocaleString()}` : '-'}
                                            </TableCell>
                                            <TableCell align="right">
                                              {row.miles !== null ? row.miles.toLocaleString() : '-'}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Stack>
          )}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default UpgradeOffers; 