import React from 'react';
import {
  Box,
  Typography,
  Modal,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { getToken } from '../../../utils/auth';

const DiscrepancyAlert = ({ open, onClose, discrepancyNotes, reservationData }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const handleRetry = async () => {
    setSubmitting(true);

    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("content-type", "application/json");
    myHeaders.append("token", getToken());

    const raw = JSON.stringify({
      "reservation_id": reservationData.reservation_id,
      "passenger_refaring": reservationData.passenger_refaring,
      "charge_amount": reservationData.charge_amount,
      "payment_method": reservationData.payment_method,
      "do_not_bill_this_event": reservationData.do_not_bill_this_event,
      "do_not_bill_reason": reservationData.do_not_bill_reason,
      "do_not_send_customer_email": reservationData.do_not_send_customer_email,
      "stripe_customer_id": reservationData.stripe_customer_id,
      "billing_amount": reservationData.billing_amount,
      "setup_intent_id": reservationData.setup_intent_id,
      "autopilot_credits_redeemed": reservationData.autopilot_credits_redeemed,
      "card_info": reservationData.card_info
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://admin-api.withautopilot.com/admin_portal/record_refare", requestOptions);
      const result = await response.json();
      
      if (result.success || result.message === "Refare event recorded successfully") {
        setSuccessMessage("Refare event recorded successfully");
        onClose(true, result.message || "Refare event recorded successfully");
      } else if (result.discrepancy_notes) {
        // If we still have discrepancies, keep the modal open
        setSubmitting(false);
      } else {
        // Handle other errors
        console.error('Error retrying refare:', result);
        setSubmitting(false);
      }
    } catch (error) {
      console.error('Error retrying refare:', error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => onClose(false)}
        aria-labelledby="discrepancy-alert-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '95%',
          maxWidth: 600,
          bgcolor: 'white',
          borderRadius: 2,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
          outline: 'none',
        }}>
          {/* Header */}
          <Box sx={{ 
            p: 3,
            borderBottom: '1px solid',
            borderColor: 'rgba(255, 68, 68, 0.2)',
            bgcolor: 'rgba(255, 68, 68, 0.05)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <ErrorOutlineIcon sx={{ color: '#d32f2f', fontSize: 28 }} />
              <Typography variant="h5" sx={{ color: '#d32f2f', fontWeight: 600 }}>
                Important Changes Detected
              </Typography>
            </Box>
            <IconButton 
              onClick={() => onClose(false)}
              size="small"
              sx={{ 
                color: '#d32f2f',
                '&:hover': {
                  bgcolor: 'rgba(255, 68, 68, 0.1)',
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Content */}
          <Box sx={{ p: 3 }}>
            <Typography sx={{ mb: 2, color: '#666' }}>
              The following changes were detected in your reservation that need to be addressed:
            </Typography>
            
            <Box sx={{ 
              bgcolor: 'rgba(255, 68, 68, 0.05)',
              borderRadius: 2,
              p: 2,
              mb: 3,
            }}>
              {discrepancyNotes.map((note, index) => (
                <Box 
                  key={index}
                  sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1.5,
                    mb: index < discrepancyNotes.length - 1 ? 2 : 0,
                    p: 2,
                    borderRadius: 1,
                    bgcolor: 'white',
                    border: '1px solid rgba(255, 68, 68, 0.2)',
                  }}
                >
                  <Typography 
                    component="span" 
                    sx={{ 
                      color: '#d32f2f',
                      fontWeight: 500,
                      minWidth: 24,
                      height: 24,
                      borderRadius: '50%',
                      bgcolor: 'rgba(255, 68, 68, 0.1)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.875rem'
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Typography sx={{ color: '#d32f2f', flex: 1, fontSize: '0.875rem' }}>
                    {note}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Alert 
              severity="warning" 
              sx={{ 
                mb: 3,
                '& .MuiAlert-icon': {
                  color: '#ed6c02'
                }
              }}
            >
              Please review these changes carefully before proceeding. You can either close this alert to modify your inputs or retry the submission with the current values.
            </Alert>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => onClose(false)}
                sx={{
                  color: '#666',
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                  '&:hover': {
                    borderColor: 'rgba(0, 0, 0, 0.24)',
                    bgcolor: 'rgba(0, 0, 0, 0.02)',
                  },
                }}
              >
                Close Alert
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={handleRetry}
                disabled={submitting}
                sx={{
                  bgcolor: '#d32f2f',
                  color: 'white',
                  '&:hover': {
                    bgcolor: '#b71c1c',
                  },
                  '&.Mui-disabled': {
                    bgcolor: '#d32f2f',
                    opacity: 0.7,
                  },
                }}
              >
                {submitting ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} sx={{ color: 'white' }} />
                    <span>Retrying...</span>
                  </Box>
                ) : (
                  'Try Again'
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DiscrepancyAlert; 